import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

// core components
import SuperAdmin from "layouts/SuperAdmin.js";
import Customers from "layouts/Customers.js";
import Login from "views/Login";
import Return from "views/Return";

import NotFoundPage from "views/404";
import RecoveryPassword from "views/RecoveryPassword";

import "assets/css/material-dashboard-react.css?v=1.8.0";
import { UrlServicesProvider } from "components/UrlServicesContext";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme();

const hist = createBrowserHistory();

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <UrlServicesProvider>
      <Router history={hist}>
        <Switch>
          <Route path="/" exact={true} component={Return} />
          <Route path="/superadmin" component={SuperAdmin} />
          <Route path="/customers" component={Customers} />
          <Route path="/login" exact={true} component={Login} />
          <Route path="/recovery" exact={true} component={RecoveryPassword} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Router>
    </UrlServicesProvider>
  </ThemeProvider>,
  document.getElementById("root")
);