import React, { Fragment, useState, useContext, useEffect } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

import PropTypes from "prop-types";
import { Forward, Check, MailOutline } from "@mui/icons-material";
import {
  Grid,
  Alert,
  AlertTitle,
  Box,
  Step,
  Stepper,
  StepLabel,
  Typography,
} from "@mui/material";
import axios from "axios";
import Moment from "moment";

import loaderGif from "assets/img/loading.gif";

import {
  QontoStepIconRoot,
  ColorlibStepIconRoot,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

function ColorlibStepIcon(props) {
  let { active, completed, className } = props;

  if (props.icon === 10) {
    if (active) {
      active = false;
      completed = true;
    }
  }

  const icons = {};
  const totalNumbers = 10;

  for (let i = 1; i <= totalNumbers; i++) {
    if (i !== totalNumbers) {
      icons[i] = <Forward sx={{ fontSize: 17 }} />;
    } else {
      icons[i] = <Check sx={{ fontSize: 17 }} />;
    }
  }

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {completed ? <Check sx={{ fontSize: 17 }} /> : icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

export default function StepperStatus(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setSteps] = React.useState([]);
  const [loader, setLoader] = useState(true);
  const [stepsStart, setStepsStart] = useState(false);

  const { urlServices, UserAuth } = useContext(UrlServicesContext);

  let keyAuthorization = UserAuth.Session;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${urlServices}status-tracking/list`, {
          headers: {
            Authorization: "Bearer " + keyAuthorization,
          },
        });
        trackingCompanies(response.data);
      } catch (error) {
        // Manejo de errores
        console.error("Error fetching tracking companies:", error);
      }
    };

    fetchData();
  }, []);

  function ValidateDaysHabiles(data) {
    const fechaActual = data;
    const diaSemana = fechaActual.getDay(); // 0 para domingo, 1 para lunes, ..., 6 para sábado

    // Si es sábado (6) o domingo (0), ajustamos la fecha al siguiente lunes
    if (diaSemana === 6) {
      // Sábado
      fechaActual.setDate(fechaActual.getDate() + 2); // Sumamos 2 días para llegar al lunes
    } else if (diaSemana === 0) {
      // Domingo
      fechaActual.setDate(fechaActual.getDate() + 1); // Sumamos 1 día para llegar al lunes
    }

    return fechaActual;
  }

  function obtenerDiferenciaDias(fechaInicio, fechaFin) {
    // Truncar las horas de las fechas
    fechaInicio.setHours(0, 0, 0, 0);
    fechaFin.setHours(0, 0, 0, 0);

    const unDia = 1000 * 60 * 60 * 24; // Milisegundos en un día
    const tiempoInicio = fechaInicio.getTime();
    const tiempoFin = fechaFin.getTime();
    let diferenciaDias = 0;

    for (let i = tiempoInicio; i < tiempoFin; i += unDia) {
      const dia = new Date(i).getDay();
      // Si el día no es sábado ni domingo, aumentamos el contador de días hábiles
      if (dia !== 0 && dia !== 6) {
        diferenciaDias++;
      }
    }

    return diferenciaDias;
  }

  const trackingCompanies = (data) => {
    axios
      .get(`${urlServices}tracking-companies/company/${UserAuth.companyId}`, {
        headers: {
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.data) {
          const fechaActual = ValidateDaysHabiles(new Date());
          const fechaCreacion = ValidateDaysHabiles(
            new Date(response.data.createdAt)
          );

          const dayDifferent = obtenerDiferenciaDias(
            fechaCreacion,
            fechaActual
          );

          const valuesFinal = data.map((value) => {
            value.dayDifferentData = dayDifferent;
            if (response.data.statusTrackingId === value.id) {
              const lastHistoryTracking =
                response.data.history_tracking_companies.find(
                  (filtered) => filtered.status === 1
                );

              if (lastHistoryTracking.comment) {
                JSON.parse(lastHistoryTracking.comment)
                  .sort(
                    (a, b) =>
                      new Date(Moment(b.date, "DD-MM-YYYY hh:mm:ss")) -
                      new Date(Moment(a.date, "DD-MM-YYYY hh:mm:ss"))
                  )
                  .forEach((value, index) => {
                    if (index === 0) {
                      lastHistoryTracking.comment = value.comment;
                      lastHistoryTracking.commentDate = value.date;
                    }
                  });
              }

              return { ...value, data: lastHistoryTracking };
            } else {
              return value;
            }
          });

          setSteps(valuesFinal);

          const activ = valuesFinal.find((value2) => value2.data);

          if (activ) {
            setActiveStep(activ.id);
          } else {
            setStepsStart(true);
          }

          setLoader(false);
        } else {
          setStepsStart(true);
          setLoader(false);
        }
      })
      .catch((e) => {});
  };

  return (
    <Fragment>
      {loader ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <center>
              <img
                alt="loaderGif"
                src={loaderGif}
                style={{ width: 60, margin: "10% 0" }}
              />
            </center>
          </Grid>
        </Grid>
      ) : stepsStart ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Alert severity="info">
              En espera para iniciar el proceso de creación de la empresa.
            </Alert>
          </Grid>
        </Grid>
      ) : activeStep === 10 ? (
        <Stepper
          activeStep={activeStep - 1}
          orientation="vertical"
          sx={{ marginTop: 3 }}
        >
          {steps.map((step, index) =>
            step.id === 10 ? (
              <Step
                key={step.name}
                sx={{
                  padding: "3px 10px",
                  border: "1px solid rgb(241, 241, 241)",
                  borderRadius: 4,
                  background: "#fff",
                  marginTop: index === 0 ? 1 : "-10px",
                  boxShadow: step.data ? "0 4px 10px 0 rgba(0,0,0,.25)" : "",
                }}
              >
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  <Grid container spacing={1}>
                    <Grid item xs={4} sm={4}>
                      <b
                        style={{
                          fontSize: step.data ? 16 : 14,
                          color: step.data ? "#333333" : "darkgray",
                        }}
                      >
                        {step.name}
                        <br></br>
                        {step.data ? (
                          step.id !== 10 ? (
                            <Typography variant="caption">
                              {" "}
                              Paso actual
                            </Typography>
                          ) : (
                            <b style={{ fontSize: 12 }}>
                              Tu empresa está creada con éxito.
                            </b>
                          )
                        ) : (
                          ""
                        )}
                      </b>
                    </Grid>
                    <Grid item xs={4} sm={4} sx={{ textAlign: "center" }}>
                      {step.data ? (
                        step.id !== 10 ? (
                          <React.Fragment>
                            <span
                              style={{
                                fontSize: 10,
                                color: "green",
                              }}
                            >
                              Días hábiles
                            </span>
                            &nbsp;&nbsp;&nbsp;
                            <b
                              style={{
                                fontSize: 14,
                                color:
                                  step.dayDifferentData > 5 ? "red" : "#333333",
                              }}
                            >
                              {/*step.data.limitDays > 1
                          ? step.data.limitDays + " días"
                          : "1 día"*/}
                              {step.dayDifferentData} / 5 días
                            </b>
                          </React.Fragment>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <b
                        style={{
                          float: "right",
                          borderRadius: 10,
                          padding: "3px 8px",
                          background: step.data
                            ? step.id !== 10
                              ? "rgb(229, 246, 253)"
                              : "rgb(237, 247, 237)"
                            : index < activeStep - 1
                            ? "rgb(237, 247, 237)"
                            : index === activeStep - 1
                            ? "rgb(229, 246, 253)"
                            : "rgb(255, 244, 229)",
                          color: step.data
                            ? step.id !== 10
                              ? "rgb(1, 67, 97)"
                              : "rgb(30, 70, 32)"
                            : index < activeStep - 1
                            ? "rgb(30, 70, 32)"
                            : index === activeStep - 1
                            ? "rgb(1, 67, 97)"
                            : "rgb(102, 60, 0)",
                          fontSize: 10,
                        }}
                      >
                        {step.data
                          ? step.id !== 10
                            ? "En proceso"
                            : "Completado"
                          : index < activeStep - 1
                          ? "Completado"
                          : index === activeStep - 1
                          ? "En proceso"
                          : "En espera"}
                      </b>
                    </Grid>
                  </Grid>
                </StepLabel>
                {/*<StepContent>
            <Typography>{step.description}</Typography>
                </StepContent>*/}
                {step.data ? (
                  step.id !== 10 ? (
                    step.data.comment ? (
                      <Box
                        sx={{
                          margin: "3px 0 5px 40px",
                        }}
                      >
                        <Alert
                          icon={<MailOutline fontSize="inherit" />}
                          severity={
                            step.dayDifferentData > 5 ? "error" : "warning"
                          }
                        >
                          <AlertTitle style={{ fontSize: 12 }}>
                            Mensaje de la administración
                          </AlertTitle>
                          <span style={{ fontSize: 12 }}>
                            {step.data.comment}
                          </span>
                        </Alert>
                      </Box>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </Step>
            ) : null
          )}
        </Stepper>
      ) : (
        <Stepper activeStep={activeStep - 1} orientation="vertical">
          {steps.map((step, index) => (
            <Step
              key={step.name}
              sx={{
                padding: "3px 10px",
                border: "1px solid rgb(241, 241, 241)",
                borderRadius: 4,
                background: "#fff",
                marginTop: index === 0 ? 1 : "-10px",
                boxShadow: step.data ? "0 4px 10px 0 rgba(0,0,0,.25)" : "",
              }}
            >
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Grid container spacing={1}>
                  <Grid item xs={4} sm={4}>
                    <b
                      style={{
                        fontSize: step.data ? 16 : 14,
                        color: step.data ? "#333333" : "darkgray",
                      }}
                    >
                      {step.name}
                      <br></br>
                      {step.data ? (
                        step.id !== 10 ? (
                          <Typography variant="caption">
                            {" "}
                            Paso actual
                          </Typography>
                        ) : (
                          <b style={{ fontSize: 12 }}>
                            Tu empresa está creada con éxito.
                          </b>
                        )
                      ) : (
                        ""
                      )}
                    </b>
                  </Grid>
                  <Grid item xs={4} sm={4} sx={{ textAlign: "center" }}>
                    {step.data ? (
                      step.id !== 10 ? (
                        <React.Fragment>
                          <span
                            style={{
                              fontSize: 10,
                              color: "green",
                            }}
                          >
                            Días hábiles
                          </span>
                          &nbsp;&nbsp;&nbsp;
                          <b
                            style={{
                              fontSize: 14,
                              color:
                                step.dayDifferentData > 5 ? "red" : "#333333",
                            }}
                          >
                            {/*step.data.limitDays > 1
                              ? step.data.limitDays + " días"
                              : "1 día"*/}
                            {step.dayDifferentData} / 5 días
                          </b>
                        </React.Fragment>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <b
                      style={{
                        float: "right",
                        borderRadius: 10,
                        padding: "3px 8px",
                        background: step.data
                          ? step.id !== 10
                            ? "rgb(229, 246, 253)"
                            : "rgb(237, 247, 237)"
                          : index < activeStep - 1
                          ? "rgb(237, 247, 237)"
                          : index === activeStep - 1
                          ? "rgb(229, 246, 253)"
                          : "rgb(255, 244, 229)",
                        color: step.data
                          ? step.id !== 10
                            ? "rgb(1, 67, 97)"
                            : "rgb(30, 70, 32)"
                          : index < activeStep - 1
                          ? "rgb(30, 70, 32)"
                          : index === activeStep - 1
                          ? "rgb(1, 67, 97)"
                          : "rgb(102, 60, 0)",
                        fontSize: 10,
                      }}
                    >
                      {step.data
                        ? step.id !== 10
                          ? "En proceso"
                          : "Completado"
                        : index < activeStep - 1
                        ? "Completado"
                        : index === activeStep - 1
                        ? "En proceso"
                        : "En espera"}
                    </b>
                  </Grid>
                </Grid>
              </StepLabel>
              {/*<StepContent>
                <Typography>{step.description}</Typography>
                    </StepContent>*/}
              {step.data ? (
                step.id !== 10 ? (
                  step.data.comment ? (
                    <Box
                      sx={{
                        margin: "3px 0 5px 40px",
                      }}
                    >
                      <Alert
                        icon={<MailOutline fontSize="inherit" />}
                        severity={
                          step.dayDifferentData > 5 ? "error" : "warning"
                        }
                      >
                        <AlertTitle style={{ fontSize: 12 }}>
                          Mensaje de la administración
                        </AlertTitle>
                        <span style={{ fontSize: 12 }}>
                          {step.data.comment}
                        </span>
                      </Alert>
                    </Box>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </Step>
          ))}
        </Stepper>
      )}
    </Fragment>
  );
}
