import React, { Fragment, useEffect, useState, useContext } from "react";

import { UrlServicesContext } from "../UrlServicesContext";
import axios from "axios";
import {
  TextField,
  Grid,
  Box,
  Alert,
  Autocomplete,
  Skeleton,
} from "@mui/material";

export default function LoadCompanies(props) {
  const [results, setResults] = useState([]);
  const [error, setError] = useState(false);
  const [isLoadedd, setIsLoadedd] = useState(false);

  const { urlServices, UserAuth } = useContext(UrlServicesContext);

  let keyAuthorization = UserAuth.Session;

  useEffect(() => {
    const consultar = async () => {
      try {
        const response = await axios.get(`${urlServices}company/list`, {
          headers: {
            Authorization: "Bearer " + keyAuthorization,
          },
        });

        const resultss = response.data.filter(
          (filtered) => filtered.status === 1
        );

        let dataRows = props.rows
          ? resultss.filter(
              (element0) =>
                !props.rows.map((row) => row.companyId).includes(element0.id)
            )
          : resultss;

        setResults(dataRows);
        setIsLoadedd(true);
      } catch (error) {
        setResults(null);
        setError(true);
        setIsLoadedd(false);
      }
    };
    consultar();
  }, [urlServices]);

  return (
    <Fragment>
      {error ? (
        <Alert severity="error">
          Error en la consulta de <b>EMPRESAS</b>
        </Alert>
      ) : (
        ""
      )}
      {!isLoadedd ? (
        <Fragment>
          <Grid item xs={12} sm={12}>
            <center>
              <Box sx={{ width: "100%" }}>
                <Skeleton width="100%" height={35} />
              </Box>
            </center>
          </Grid>
        </Fragment>
      ) : (
        <Autocomplete
          id="combo-box-demo"
          options={results}
          value={props.value}
          onChange={(event, newValue) => {
            props.refresh(newValue);
          }}
          getOptionLabel={(option) => option.razonSocial || "Seleccione ..."}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Empresas"
              variant="outlined"
              margin="dense"
              error={props.error}
              required
              size="small"
              inputProps={{
                ...params.inputProps,
                autoComplete: "off", // disable autocomplete and autofill
              }}
              style={{ background: "#FFFFFF" }}
            />
          )}
          classes={{
            clearIndicatorDirty: "none",
            popupIndicator: "none",
          }}
        />
      )}
    </Fragment>
  );
}
