import React, { Fragment, useState, useContext } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";
import { Redirect } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import axios from "axios";
import { MailOutline, PersonOutline } from "@mui/icons-material";
import {
  Grid,
  DialogActions,
  DialogContent,
  FormControl,
  Slide,
  Snackbar,
  TextField,
  Alert,
  Dialog,
} from "@mui/material";

import LoadGender from "components/services/LoadGender";
import LoadProfile from "components/services/LoadProfile";
import LoadCompanies from "components/services/LoadCompanies";

import {
  cardBodyStyle,
  ButtonExit,
  ButtonStyle0,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function EditUserAdmin(props) {
  const classes = useStyles();

  const [openAlert, setOpenAlert] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  const [openAlertSuccess, setopenAlertSuccess] = useState(false);
  const [mensaje_success, setmensaje_success] = useState("");
  const [returnLogin, setReturnLogin] = React.useState(false);
  const [error, setError] = useState(false);
  const [mensageSystem, setMensageSystem] = useState("");

  const { urlServices, UserAuth } = useContext(UrlServicesContext);

  let keyAuthorization = UserAuth.Session;

  const [values, setValues] = useState({
    name: props.id.row.name,
    lastName: props.id.row.lastName,
    document_id: props.id.row.documentId,
    email: props.id.row.email,
    emailValue: props.id.row.email,
    gender: props.id.row.genderId,
    genderData: { id: props.id.row.gender.id, name: props.id.row.gender.name },
    profile: props.hide ? 1 : props.id.row.profileId,
    profileData: props.hide
      ? { id: 1, name: "Super Administrador" }
      : { id: props.id.row.profileId, name: props.id.row.profileName },
    company: props.hide ? null : props.id.row.companyId,
    companyData: props.hide
      ? []
      : {
          id: props.id.row.companyId,
          razonSocial: props.id.row.companyRazonSocial,
        },
    errorName: false,
    errorLastName: false,
    errorEmail: false,
    errorDocument: false,
    errorGender: false,
    errorProfile: false,
    errorCompany: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      errorName: false,
      errorLastName: false,
      errorEmail: false,
      errorDocument: false,
    });
    setOpenAlert(false);
  };

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  const submitForm = (e) => {
    e.preventDefault();
    setOpenAlert(false);
    setError(false);
    setopenAlertSuccess(false);

    if (values.name.length === 0) {
      setValues({ ...values, errorName: true });
      setMensaje('El campo "Nombres" es obligatorio.');
      setOpenAlert(true);
      setErrorAlert("error");
    } else if (values.lastName.length === 0) {
      setValues({ ...values, errorLastName: true });
      setMensaje('El campo "Apellidos" es obligatorio.');
      setOpenAlert(true);
      setErrorAlert("error");
    } else if (values.document_id.length === 0) {
      setValues({ ...values, errorDocument: true });
      setMensaje('El campo "Documento" es obligatorio.');
      setOpenAlert(true);
      setErrorAlert("error");
    } else if (values.email.length === 0) {
      setValues({ ...values, errorEmail: true });
      setMensaje('El campo "E-mail" es obligatorio.');
      setOpenAlert(true);
      setErrorAlert("error");
    } else if (values.gender === "") {
      setValues({ ...values, errorGender: true });
      setMensaje("Seleccione un Género");
      setOpenAlert(true);
      setErrorAlert("error");
    } else {
      const dataValue = {
        documentId: values.document_id,
        name: values.name,
        lastName: values.lastName,
        allNames: values.name + " " + values.lastName,
        genderId: values.gender,
        profileId: values.profile,
      };

      if (values.emailValue !== values.email.toLowerCase().trim()) {
        dataValue.email = values.email.toLowerCase().trim();
      }

      if (props.title === "accionista") {
        dataValue.companyId = values.company;
      }

      axios
        .put(`${urlServices}users/${props.id.row.id}`, dataValue, {
          headers: {
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setopenAlertSuccess(true);
            setmensaje_success(response.data.message);
            props.callBackRefresh();

            /*localStorage.setItem(
              "allname",
              values.name + " " + values.lastName
            );*/
          }
        })
        .catch((e) => {
          if (e.response.status === 409) {
            setValues({ ...values, errorEmail: true });
            setMensageSystem("Correo electrónico ya registrado.");
            setError(true);
          } else if (e.response.status === 401) {
            setMensageSystem(
              "La sesión ha expirado, vuelva a iniciar sesión ..."
            );
            setError(true);

            setTimeout(() => {
              localStorage.clear();
              setReturnLogin(true);
            }, 4000);
          } else if (e.response.status === 500) {
            setMensageSystem("Error en la consulta con sel servidor.");
            setError(true);
          }
        });
    }
  };

  const changeGender = (data) => {
    setValues({
      ...values,
      gender: data !== null ? data.id : "",
      genderData: data !== null ? data : "",
      errorGender: false,
    });

    setOpenAlert(false);
  };

  const changeProfile = (data) => {
    setValues({
      ...values,
      profile: data !== null ? data.id : "",
      profileData: data !== null ? data : "",
      errorProfile: false,
    });

    setOpenAlert(false);
  };

  const changeCompany = (data) => {
    setValues({
      ...values,
      company: data !== null ? data.id : "",
      companyData: data !== null ? data : "",
      errorCompany: false,
    });

    setOpenAlert(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  if (returnLogin) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        PaperProps={{
          sx: {
            borderRadius: 6,
          },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    {error ? (
                      <Alert severity="error">{mensageSystem}</Alert>
                    ) : (
                      ""
                    )}
                    <div className={classes.titleCardFilter}>
                      Editar {props.title}
                    </div>
                    <div className={classes.lineBottom}></div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        name="name"
                        value={values.name}
                        error={values.errorName}
                        onChange={handleChange("name")}
                        fullWidth
                        required
                        size="small"
                        label="Nombres"
                        margin="dense"
                        autoComplete="off"
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <PersonOutline
                              fontSize="small"
                              className={classes.iconInput}
                              position="end"
                            ></PersonOutline>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        name="lastName"
                        value={values.lastName}
                        error={values.errorLastName}
                        onChange={handleChange("lastName")}
                        fullWidth
                        required
                        size="small"
                        label="Apellidos"
                        margin="dense"
                        autoComplete="off"
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <PersonOutline
                              fontSize="small"
                              className={classes.iconInput}
                              position="end"
                            ></PersonOutline>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        name="document_id"
                        value={values.document_id}
                        error={values.errorDocument}
                        onChange={handleChange("document_id")}
                        fullWidth
                        required
                        size="small"
                        label="Documento"
                        margin="dense"
                        variant="outlined"
                        autoComplete="off"
                        inputProps={{
                          style: {
                            textAlign: "center",
                          },
                          maxLength: 13,
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                        helperText="Cédula, RUC o pasaporte."
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="off"
                      name="email"
                      value={values.email}
                      onChange={handleChange("email")}
                      label="Email"
                      size="small"
                      error={values.errorEmail}
                      InputProps={{
                        endAdornment: (
                          <MailOutline
                            fontSize="small"
                            className={classes.iconInput}
                            position="end"
                          ></MailOutline>
                        ),
                      }}
                      fullWidth
                      margin="dense"
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <LoadGender
                      value={values.genderData}
                      refresh={changeGender}
                      error={values.errorGender}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <LoadProfile
                      value={values.profileData}
                      refresh={changeProfile}
                      error={values.errorProfile}
                      hide={props.hide}
                    />
                  </Grid>
                  {props.title === "accionista" ? (
                    <Grid item xs={6} md={6}>
                      <LoadCompanies
                        value={values.companyData}
                        refresh={changeCompany}
                        error={values.errorCompany}
                      />
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid item xs={12} sm={12}>
                    <br></br>
                    <center>
                      <ButtonStyle0 type="submit" onClick={submitForm}>
                        Editar {props.title}
                      </ButtonStyle0>
                    </center>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <center>
                      {openAlertSuccess ? (
                        <Alert severity="success">{mensaje_success}</Alert>
                      ) : (
                        ""
                      )}
                    </center>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={errorAlert === "error" ? "error" : "success"}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <ButtonExit onClick={handleCloseDialog(true)}>Cerrar</ButtonExit>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
