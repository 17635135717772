import React, { Fragment, useState, useContext } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";
import { Redirect } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import axios from "axios";
import {
  FormControl,
  Slide,
  Snackbar,
  TextField,
  Grid,
  DialogActions,
  DialogContent,
  Alert,
  Dialog,
} from "@mui/material";
import { MailOutline, PersonOutline } from "@mui/icons-material";

import LoadGender from "components/services/LoadGender";
import LoadProfile from "components/services/LoadProfile";

import imgLoader from "assets/img/loading.gif";

import {
  cardBodyStyle,
  ButtonExit,
  ButtonStyle0,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function NewUserAdmin(props) {
  const classes = useStyles();

  const [openAlert, setOpenAlert] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  const [openAlertSuccess, setopenAlertSuccess] = useState(false);
  const [mensaje_success, setmensaje_success] = useState("");
  const [returnLogin, setReturnLogin] = React.useState(false);
  const [error, setError] = useState(false);
  const [mensageSystem, setMensageSystem] = useState("");
  const [disable, setDisable] = useState(false);
  const [loaders, setLoaders] = useState(false);

  const { urlServices, UserAuth } = useContext(UrlServicesContext);

  let keyAuthorization = UserAuth.Session;

  const [values, setValues] = useState({
    name: "",
    lastName: "",
    document_id: "",
    email: "",
    gender: "",
    genderData: [],
    profile: "",
    profileData: [],
    errorName: false,
    errorLastName: false,
    errorEmail: false,
    errorDocument: false,
    errorGender: false,
    errorProfile: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      errorName: false,
      errorLastName: false,
      errorEmail: false,
      errorDocument: false,
    });
    setOpenAlert(false);
  };

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  const submitForm = (e) => {
    e.preventDefault();
    setOpenAlert(false);
    setError(false);
    setopenAlertSuccess(false);

    if (values.name.length === 0) {
      setValues({ ...values, errorName: true });
      setMensaje('El campo "Nombres" es obligatorio.');
      setOpenAlert(true);
      setErrorAlert("error");
    } else if (values.lastName.length === 0) {
      setValues({ ...values, errorLastName: true });
      setMensaje('El campo "Apellidos" es obligatorio.');
      setOpenAlert(true);
      setErrorAlert("error");
    } else if (values.document_id.length === 0) {
      setValues({ ...values, errorDocument: true });
      setMensaje('El campo "Documento" es obligatorio.');
      setOpenAlert(true);
      setErrorAlert("error");
    } else if (values.email.length === 0) {
      setValues({ ...values, errorEmail: true });
      setMensaje('El campo "E-mail" es obligatorio.');
      setOpenAlert(true);
      setErrorAlert("error");
    } else if (values.gender === "") {
      setValues({ ...values, errorGender: true });
      setMensaje("Seleccione un Género");
      setOpenAlert(true);
      setErrorAlert("error");
    } else if (values.profile === "") {
      setValues({ ...values, errorProfile: true });
      setMensaje("Seleccione un perfil");
      setOpenAlert(true);
      setErrorAlert("error");
    } else {
      setLoaders(true);

      const randomNumber = Math.floor(Math.random() * 900000) + 100000;

      const dataValue = {
        email: values.email.toLowerCase().trim(),
        documentId: values.document_id,
        name: values.name,
        lastName: values.lastName,
        allNames: values.name + " " + values.lastName,
        genderId: values.gender,
        profileId: values.profile,
        password: randomNumber.toString(),
        code: randomNumber,
        status: 2,
        companyId: UserAuth.companyId,
      };

      axios
        .post(`${urlServices}users`, dataValue, {
          headers: {
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then((response) => {
          setValues({
            ...values,
            name: "",
            lastName: "",
            document_id: "",
            email: "",
            gender: "",
            genderData: [],
            profile: "",
            profileData: [],
          });

          setDisable(true);
          setopenAlertSuccess(true);
          setmensaje_success("Solicitud registrada.");
          setLoaders(false);
        })
        .catch((e) => {
          if (e.response.status === 409) {
            setValues({ ...values, errorEmail: true });
            setMensageSystem("Correo electrónico ya registrado.");
            setError(true);
          } else if (e.response.status === 401) {
            setMensageSystem(
              "La sesión ha expirado, vuelva a iniciar sesión ..."
            );
            setError(true);

            setTimeout(() => {
              localStorage.clear();
              setReturnLogin(true);
            }, 4000);
          } else if (e.response.status === 500) {
            setMensageSystem("Error en la consulta con sel servidor.");
            setError(true);
          }
          setLoaders(false);
        });
    }
  };

  const changeGender = (data) => {
    setValues({
      ...values,
      gender: data !== null ? data.id : "",
      genderData: data !== null ? data : "",
      errorGender: false,
    });

    setOpenAlert(false);
  };

  const changeProfile = (data) => {
    setValues({
      ...values,
      profile: data !== null ? data.id : "",
      profileData: data !== null ? data : "",
      errorProfile: false,
    });

    setOpenAlert(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  if (returnLogin) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        PaperProps={{
          sx: {
            borderRadius: 6,
          },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    {error ? (
                      <Alert severity="error">{mensageSystem}</Alert>
                    ) : (
                      ""
                    )}
                    <div className={classes.titleCardFilter}>
                      Nueva solicitud de accionista
                    </div>
                    <div className={classes.lineBottom}></div>
                    <div className={classes.titleCardFilter}>
                      <Alert severity="info">
                        Se enviará la solicitud a los administradores para su
                        aprobación o rechazo. Todos los datos son obligatorios.
                      </Alert>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <b>Datos del nuevo accionista:</b>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        name="name"
                        value={values.name}
                        error={values.errorName}
                        onChange={handleChange("name")}
                        fullWidth
                        required
                        label="Nombre"
                        margin="dense"
                        autoComplete="off"
                        variant="outlined"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <PersonOutline
                              fontSize="small"
                              className={classes.iconInput}
                              position="end"
                            ></PersonOutline>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        name="lastName"
                        value={values.lastName}
                        error={values.errorLastName}
                        onChange={handleChange("lastName")}
                        fullWidth
                        required
                        size="small"
                        label="Apellidos"
                        margin="dense"
                        autoComplete="off"
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <PersonOutline
                              fontSize="small"
                              className={classes.iconInput}
                              position="end"
                            ></PersonOutline>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        name="document_id"
                        value={values.document_id}
                        error={values.errorDocument}
                        onChange={handleChange("document_id")}
                        fullWidth
                        required
                        size="small"
                        label="Documento"
                        margin="dense"
                        variant="outlined"
                        autoComplete="off"
                        inputProps={{
                          style: {
                            textAlign: "center",
                          },
                          maxLength: 13,
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                        helperText="Cédula, RUC o pasaporte."
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="off"
                      name="email"
                      value={values.email}
                      onChange={handleChange("email")}
                      label="Email"
                      error={values.errorEmail}
                      InputProps={{
                        endAdornment: (
                          <MailOutline
                            fontSize="small"
                            className={classes.iconInput}
                            position="end"
                          ></MailOutline>
                        ),
                      }}
                      fullWidth
                      margin="dense"
                      required
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <LoadGender
                      value={values.genderData}
                      refresh={changeGender}
                      error={values.errorGender}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <LoadProfile
                      value={values.profileData}
                      refresh={changeProfile}
                      error={values.errorProfile}
                      hide={props.hide}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <br></br>
                    {loaders ? (
                      <center>
                        <img
                          src={imgLoader}
                          alt="loader"
                          style={{
                            width: 60,
                            objectFit: "contain",
                            marginTop: 20,
                          }}
                        />
                      </center>
                    ) : (
                      <center>
                        <ButtonStyle0
                          type="submit"
                          onClick={submitForm}
                          disabled={disable}
                        >
                          Enviar solicitud
                        </ButtonStyle0>
                      </center>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <center>
                      {openAlertSuccess ? (
                        <Alert severity="success">{mensaje_success}</Alert>
                      ) : (
                        ""
                      )}
                    </center>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={errorAlert === "error" ? "error" : "success"}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <ButtonExit onClick={handleCloseDialog(true)}>Cerrar</ButtonExit>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
