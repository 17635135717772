import React, { Fragment, useState, useContext, useEffect } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";
import { Redirect } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import axios from "axios";
import {
  TextField,
  Slide,
  Snackbar,
  Grid,
  DialogActions,
  DialogContent,
  Alert,
  Dialog,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { PendingActions, ExpandMore, MailOutline } from "@mui/icons-material";
import Moment from "moment";

import {
  cardBodyStyle,
  ButtonExit,
  ButtonStyle0,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function CommentStatusTracking(props) {
  const classes = useStyles();

  const [openAlert, setOpenAlert] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  const [openAlertSuccess, setopenAlertSuccess] = useState(false);
  const [mensaje_success, setmensaje_success] = useState("");
  const [returnLogin, setReturnLogin] = React.useState(false);
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [loader, setLoader] = useState(true);
  const [mensageSystem, setMensageSystem] = useState("");
  const [dataHistory, setDataHistory] = useState([]);
  const [countHistoryStatus, setCountHistoryStatus] = useState(0);
  const [dateCompareLimit, setDateCompareLimit] = useState(true);

  const { urlServices, UserAuth } = useContext(UrlServicesContext);

  let keyAuthorization = UserAuth.Session;

  const [values, setValues] = useState({
    comment: "",
    errorComment: false,
  });

  function ValidateDaysHabiles(data) {
    const fechaActual = data;
    const diaSemana = fechaActual.getDay(); // 0 para domingo, 1 para lunes, ..., 6 para sábado

    // Si es sábado (6) o domingo (0), ajustamos la fecha al siguiente lunes
    if (diaSemana === 6) {
      // Sábado
      fechaActual.setDate(fechaActual.getDate() + 2); // Sumamos 2 días para llegar al lunes
    } else if (diaSemana === 0) {
      // Domingo
      fechaActual.setDate(fechaActual.getDate() + 1); // Sumamos 1 día para llegar al lunes
    }

    return fechaActual;
  }

  function obtenerDiferenciaDias(fechaInicio, fechaFin) {
    // Truncar las horas de las fechas
    fechaInicio.setHours(0, 0, 0, 0);
    fechaFin.setHours(0, 0, 0, 0);

    const unDia = 1000 * 60 * 60 * 24; // Milisegundos en un día
    const tiempoInicio = fechaInicio.getTime();
    const tiempoFin = fechaFin.getTime();
    let diferenciaDias = 0;

    for (let i = tiempoInicio; i < tiempoFin; i += unDia) {
      const dia = new Date(i).getDay();
      // Si el día no es sábado ni domingo, aumentamos el contador de días hábiles
      if (dia !== 0 && dia !== 6) {
        diferenciaDias++;
      }
    }

    return diferenciaDias;
  }

  useEffect(() => {
    let lastHistoryTracking = props.id.history_tracking_companies.filter(
      (filtered) => filtered.status === 1
    );

    const fechaActual = ValidateDaysHabiles(new Date());
    const fechaCreacion = ValidateDaysHabiles(new Date(props.id.createdAt));

    const dayDifferent = obtenerDiferenciaDias(fechaCreacion, fechaActual);

    setDateCompareLimit(dayDifferent > 5 ? true : false);

    setDataHistory({
      data: lastHistoryTracking[0],
      comment: lastHistoryTracking[0].comment
        ? JSON.parse(lastHistoryTracking[0].comment)
        : [],
    });

    if (lastHistoryTracking.length > 0) {
      const commentsArray = lastHistoryTracking[0].comment
        ? JSON.parse(lastHistoryTracking[0].comment)
        : [];
      setCountHistoryStatus(commentsArray.length);
      setLoader(false);
    } else {
      setCountHistoryStatus(0); // Si no hay comentarios, imprime 0
    }
  }, []);

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  const handleChange = (prop) => (event) => {
    setopenAlertSuccess(false);

    setValues({
      ...values,
      [prop]: event.target.value,
      errorComment: false,
    });
    setOpenAlert(false);
  };

  const submitForm = (e) => {
    e.preventDefault();
    setErrorAlert("");
    setError(false);
    setopenAlertSuccess(false);

    if (values.comment === "") {
      setValues({ ...values, errorComment: true });
      setMensaje("Debe agregar un comentario.");
      setOpenAlert(true);
      setErrorAlert("error");
    } else {
      // Obtener los comentarios existentes si los hay
      let existingComments = [];
      const currentDate = new Date(); // Obtener la fecha actual

      if (dataHistory.comment) {
        existingComments = dataHistory.comment;
      }

      // Agregar el nuevo comentario al array existente
      existingComments.push({
        date: currentDate,
        comment: values.comment,
      });

      const dataValue = {
        comment: JSON.stringify(existingComments),
      };

      axios
        .put(
          `${urlServices}history-tracking-companies/${dataHistory.data.id}`,
          dataValue,
          {
            headers: {
              Authorization: "Bearer " + keyAuthorization,
            },
          }
        )
        .then((response) => {
          setDataHistory({ data: dataHistory.data, comment: existingComments });
          setCountHistoryStatus(existingComments.length);
          setLoader(false);
          setDisabled(true);
          setopenAlertSuccess(true);
          setmensaje_success(response.data.message);
          props.callBackRefresh();

          setValues({
            ...values,
            comment: "",
          });
        })
        .catch((e) => {
          if (e.response.status === 401) {
            setMensageSystem(
              "La sesión ha expirado, vuelva a iniciar sesión ..."
            );
            setError(true);

            setTimeout(() => {
              localStorage.clear();
              setReturnLogin(true);
            }, 4000);
          } else if (e.response.status === 500) {
            setMensageSystem("Error en la consulta con sel servidor.");
            setError(true);
          }
        });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  if (returnLogin) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        PaperProps={{
          sx: {
            borderRadius: 6,
          },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                {error ? <Alert severity="error">{mensageSystem}</Alert> : ""}
                <div className={classes.titleCardFilter}>
                  Comentario para el estatus
                </div>
                <div className={classes.lineBottom}></div>
                <br></br>
              </Grid>
              <Grid item xs={12} md={12}>
                <PendingActions fontSize="inherit" /> &nbsp;
                <b style={{ fontSize: 12 }}>Estatus actual:</b>
              </Grid>
              <Grid item xs={12} md={12}>
                <Alert
                  // icon={<PendingActions fontSize="inherit" />}
                  icon={false}
                  severity={dateCompareLimit ? "error" : "success"}
                  action={
                    <b
                      style={{
                        marginTop: 2,
                        fontSize: 10,
                        marginLeft: "right",
                        padding: "3px 8px",
                        background: loader
                          ? ""
                          : dateCompareLimit
                          ? "rgb(253, 237, 237)"
                          : "rgb(237, 247, 237)",
                        color: loader
                          ? ""
                          : dateCompareLimit
                          ? "rgb(95, 33, 32)"
                          : "rgb(30, 70, 32)",
                      }}
                    >
                      {loader
                        ? ""
                        : dateCompareLimit
                        ? "RETRASADO"
                        : "A TIEMPO"}
                    </b>
                  }
                >
                  <div style={{ fontSize: 12 }}>
                    <b>
                      {props.id.status_tracking.name} <br></br>{" "}
                      {/*<span style={{ fontWeight: 400 }}>
                        Tiempo límite registrado para realizarlo:
                      </span>
                      {loader
                        ? ""
                        : " " +
                          dataHistory.data.limitDays +
                          " día" +
                          (dataHistory.data.limitDays > 1 ? "s" : "")*/}
                      <span style={{ fontWeight: 400 }}>
                        Fecha de registro:{" "}
                      </span>
                      {loader
                        ? ""
                        : Moment(dataHistory.data.createdAt).format(
                            "DD-MM-YYYY"
                          )}
                    </b>
                  </div>
                </Alert>
              </Grid>
              <Grid item xs={12} sm={12}>
                <b>Agregar comentario:</b>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  autoComplete="off"
                  name="comment"
                  value={values.comment}
                  onChange={handleChange("comment")}
                  label="Comentario"
                  error={values.errorComment}
                  fullWidth
                  required
                  margin="dense"
                  variant="outlined"
                  style={{ background: "#FFFFFF" }}
                  size="small"
                  multiline
                  maxRows={2}
                  helperText="Si desea que al cliente visualice algún comentario referente al estatus actual."
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <b>Comentarios agregados para este estatus:</b>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <span style={{ fontSize: 14, fontWeight: "400" }}>
                      Ver comentarios:
                    </span>{" "}
                    &nbsp;&nbsp; <b>{countHistoryStatus}</b>
                  </AccordionSummary>
                  {loader
                    ? ""
                    : dataHistory.comment
                        .sort(
                          (a, b) =>
                            new Date(Moment(b.date, "DD-MM-YYYY hh:mm:ss")) -
                            new Date(Moment(a.date, "DD-MM-YYYY hh:mm:ss"))
                        )
                        .map((value, index) => (
                          <AccordionDetails key={index}>
                            <Alert
                              severity="warning"
                              icon={<MailOutline fontSize="inherit" />}
                            >
                              <b
                                style={{
                                  marginTop: "-25px",
                                  position: "absolute",
                                  left: 28,
                                  fontSize: 8,
                                  display: "block",
                                  background:
                                    index === 0 ? "palegreen" : "whitesmoke",
                                  padding: "3px 8px",
                                  color: index === 0 ? "#333333" : "gray",
                                }}
                              >
                                {index === 0
                                  ? "Mensaje actual que ve el cliente"
                                  : "Mensaje antiguos"}
                              </b>
                              {value.comment}
                              <b
                                style={{
                                  float: "right",
                                  marginTop: 2,
                                  position: "absolute",
                                  right: 28,
                                  fontSize: 8,
                                  display: "block",
                                  color: "gray",
                                }}
                              >
                                {Moment(value.date).format("DD-MM-YYYY")}
                              </b>
                            </Alert>
                          </AccordionDetails>
                        ))}
                </Accordion>
              </Grid>
              <Grid item xs={12} sm={12}>
                <br></br>
                <br></br>
                <center>
                  <ButtonStyle0
                    type="submit"
                    onClick={submitForm}
                    disabled={disabled}
                  >
                    Enviar
                  </ButtonStyle0>
                </center>
              </Grid>
              <Grid item xs={12} sm={12}>
                <center>
                  {openAlertSuccess ? (
                    <Alert severity="success">{mensaje_success}</Alert>
                  ) : (
                    ""
                  )}
                </center>
              </Grid>
            </Grid>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={errorAlert === "error" ? "error" : "success"}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <ButtonExit onClick={handleCloseDialog(true)}>Cerrar</ButtonExit>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
