/*eslint-disable*/
import React, { Fragment, useState, useEffect, useContext } from "react";

import { UrlServicesContext } from "../UrlServicesContext";

import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@mui/styles";
import {
  Collapse,
  ListItemText,
  ListItem,
  List,
  Hidden,
  Drawer,
  Icon,
  Box,
  Divider,
  Grid,
  Avatar,
  Button,
  Stack,
} from "@mui/material";

import {
  ArrowRight,
  ExpandMore,
  ExpandLess,
  ExitToApp,
} from "@mui/icons-material";

// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import PopupLogout from "views/dialog/PopupLogout";

import mujerAvatar from "assets/img/undraw_mujer.png";
import hombreAvatar from "assets/img/undraw_hombre.png";

import {
  sidebarStyle,
  ListItemStyle,
} from "assets/jss/material-dashboard-react/components/sidebarStyle.js";

const useStyles = makeStyles(sidebarStyle);

export default function SidebarAdmin(props) {
  const classes = useStyles();

  const { UserAuth } = useContext(UrlServicesContext);

  const initialOpenStates = {}; // Objeto para rastrear los elementos abiertos
  props.routes.forEach((prop) => {
    if (prop.submenu.length > 0) {
      initialOpenStates[prop.id] = false; // Inicialmente, todos los submenús están cerrados
    }
  });

  const [marginTop, setMarginTop] = useState("90%");
  const [popupLogout, setpopupLogout] = React.useState(false);
  const [openStates, setOpenStates] = useState(initialOpenStates);

  const picture =
    UserAuth.avatar === null
      ? UserAuth.gender === 1
        ? hombreAvatar
        : mujerAvatar
      : UserAuth.avatar;

  const mMobile = 0.2;
  const mDesktop = 0.32;

  /*useEffect(() => {
    //<!-- Start of HubSpot Embed Code -->
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "hs-script-loader";
    script.async = true;
    script.defer = true;
    script.src = "//js-na1.hs-scripts.com/46332218.js";
    document.body.appendChild(script);

    // Limpieza: remover el script cuando el componente se desmonte
    return () => {
      document.body.removeChild(script);
    };
    //<!-- End of HubSpot Embed Code -->
  }, []);*/

  useEffect(() => {
    function updateMarginTop() {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const isMobile = screenWidth < 1300; // Define tu propia lógica para determinar si es un dispositivo móvil

      let newMarginTop;
      if (isMobile) {
        newMarginTop = `${screenHeight * mMobile}px`;
      } else {
        newMarginTop = `${screenHeight * mDesktop}px`;
      }
      setMarginTop(newMarginTop);
    }

    updateMarginTop();
    window.addEventListener("resize", updateMarginTop);
    return () => window.removeEventListener("resize", updateMarginTop);
  }, []);

  const handleCloseAllSubmenus = () => {
    setOpenStates((prevStates) => {
      // Crear un nuevo objeto con todos los submenús cerrados
      const newStates = {};
      for (const key in prevStates) {
        newStates[key] = false;
      }
      return newStates;
    });
  };

  const handleOpen = (pro) => () => {
    setOpenStates((prevStates) => ({
      ...prevStates,
      [pro]: !prevStates[pro],
    }));
  };

  const handleOpenpopupLogout = () => () => {
    setpopupLogout(true);
  };

  const handleClosepopupLogout = () => {
    setpopupLogout(false);
  };

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const {
    //color,
    logo,
    image,
    routes,
  } = props;
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        var activePro = " ";
        // var listItemClasses;
        if (prop.path === "/upgrade-to-pro") {
          activePro = classes.activePro + " ";
          /* listItemClasses = classNames({
            [" " + classes[color]]: true,
          });*/
        } else if (prop.path === "") {
          // listItemClasses = "";
        } else {
          /*listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.layout + prop.path),
          });*/
        }
        /* const purpleFontClasses = classNames({
          [" " + classes.purple]: activeRoute(prop.layout + prop.path),
        });*/

        const purpleIconlasses = classNames({
          [" " + classes.purpleIcon]: activeRoute(prop.layout + prop.path),
        });
        return prop.name !== "" ? (
          prop.submenu.length === 0 ? (
            <Fragment>
              {prop.margin === 0 ? (
                <NavLink
                  to={prop.layout + prop.path}
                  //className={activePro + classes.item}
                  activeClassName="active"
                  key={key}
                >
                  <ListItemStyle
                    button
                    //className={classes.itemLink + listItemClasses}
                    onClick={handleCloseAllSubmenus}
                  >
                    {typeof prop.icon === "string" ? (
                      <Icon
                        className={classNames(
                          classes.itemIcon,
                          purpleIconlasses
                          /*{
                            [classes.itemIconRTL]: props.rtlActive,
                          }*/
                        )}
                      >
                        {prop.icon}
                      </Icon>
                    ) : (
                      <prop.icon
                        className={classNames(
                          classes.itemIcon,
                          purpleIconlasses
                          /*{
                            [classes.itemIconRTL]: props.rtlActive,
                          }*/
                        )}
                      />
                    )}
                    <ListItemText
                      primary={props.rtlActive ? prop.rtlName : prop.name}
                      className={classNames(classes.itemText, "", {
                        [classes.itemTextRTL]: props.rtlActive,
                        [" " + classes.itemTextPurple]: activeRoute(
                          prop.layout + prop.path
                        ),
                      })}
                      disableTypography={true}
                    />
                  </ListItemStyle>
                </NavLink>
              ) : (
                <Box sx={{ marginTop: marginTop }}>
                  <NavLink
                    to={prop.layout + prop.path}
                    //className={activePro + classes.item}
                    activeClassName="active"
                    key={key}
                  >
                    <Divider />
                    <ListItem
                      button
                      //className={classes.itemLink + listItemClasses}
                      onClick={handleCloseAllSubmenus}
                    >
                      {typeof prop.icon === "string" ? (
                        <Icon
                          className={classNames(
                            classes.itemIcon,
                            purpleIconlasses
                            /*{
                          [classes.itemIconRTL]: props.rtlActive,
                        }*/
                          )}
                        >
                          {prop.icon}
                        </Icon>
                      ) : (
                        <prop.icon
                          className={classNames(
                            classes.itemIcon,
                            purpleIconlasses
                            /*{
                          [classes.itemIconRTL]: props.rtlActive,
                        }*/
                          )}
                        />
                      )}
                      <ListItemText
                        primary={props.rtlActive ? prop.rtlName : prop.name}
                        className={classNames(classes.itemText, "", {
                          [classes.itemTextRTL]: props.rtlActive,
                          [" " + classes.itemTextPurple]: activeRoute(
                            prop.layout + prop.path
                          ),
                        })}
                        disableTypography={true}
                      />
                    </ListItem>
                  </NavLink>
                  <Grid container>
                    <Grid xs={12} md={12}>
                      <Stack direction="row" spacing={1} sx={{ marginLeft: 2 }}>
                        <Avatar
                          src={picture}
                          style={{ marginTop: 1, width: 30, height: 30 }}
                        />
                        <div style={{ marginTop: 1, fontSize: 10 }}>
                          <b>
                            {UserAuth.allname &&
                              (UserAuth.allname.length > 16
                                ? UserAuth.allname.substring(0, 16) + "..."
                                : UserAuth.allname)}
                            <div
                              style={{
                                marginTop: "-8px",
                                color: "gray",
                                fontSize: 9,
                              }}
                            >
                              {UserAuth.profile_name}
                            </div>
                          </b>
                        </div>
                        <Button
                          aria-haspopup="true"
                          onClick={handleOpenpopupLogout()}
                          sx={{ float: "right", marginTop: "-20px" }}
                        >
                          <ExitToApp
                            style={{
                              fontSize: 20,
                              color: "#000",
                            }}
                          />
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <ListItem
                button
                onClick={handleOpen(prop.id)}
                className={classes.itemLinkCollapse}
              >
                <span style={{ marginLeft: 5 }}>
                  {typeof prop.icon === "string" ? prop.icon : <prop.icon />}
                </span>
                <ListItemText
                  primary={prop.name}
                  className={classes.itemLinkCollapseName}
                  disableTypography={true}
                />
                {openStates[prop.id] ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse
                in={openStates[prop.id]}
                timeout="auto"
                unmountOnExit
                style={{ backgroundColor: "rgb(247, 247, 247)" }}
              >
                <List component="div" key={key}>
                  {prop.submenu.map((prop2) => {
                    if (prop2.subId === prop.id) {
                      return (
                        <NavLink
                          to={prop.layout + prop2.path}
                          className={activePro + classes.item}
                          activeClassName="active"
                          key={key}
                        >
                          <ListItem
                            button
                            //className={classes.itemLinkCollapseSubmenu}
                            sx={{
                              width: "auto",
                              transition: "all 300ms linear",
                              margin: "8px 12px 0",
                              borderRadius: "3px",
                              position: "relative",
                              display: "flex",
                              padding: "3px 8px",
                              backgroundColor: "#FAFAFA",
                              "&:hover": {
                                background: "rgba(224, 224, 224, 1)",
                              },
                            }}
                          >
                            <ArrowRight
                              style={{
                                minWidth: " 0px !important",
                                color: "#000",
                              }}
                            />
                            <ListItemText
                              primary={prop2.name}
                              className={classNames(
                                classes.itemLinkCollapseNameSub,
                                "",
                                {
                                  [" " + classes.itemTextPurple]: activeRoute(
                                    prop.layout + prop2.path
                                  ),
                                }
                              )}
                              disableTypography={true}
                            />
                          </ListItem>
                        </NavLink>
                      );
                    }
                  })}
                </List>
              </Collapse>
            </Fragment>
          )
        ) : null;
      })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <img src={logo} alt="logo" className={classes.img} />
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <AdminNavbarLinks />
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          onClose={props.handleDrawerToggle}
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : (
            <div className={classes.background}>
              {/*<Divider />
              <NavLink
                to="/superadmin/profileaccount"
                // className={activePro + classes.item}
                activeClassName="active"
              >
                <Button
                  variant="text"
                  startIcon={<AccountCircle style={{ fontSize: 28 }} />}
                  sx={{
                    color: "#000",
                    textTransform: "none",
                    width: "100%",
                    margin: "2px 0 40px -50px",
                    lineHeight: "25px",
                    fontSize: 14,
                    "&:hover": {
                      background: "#F6F6F6",
                    },
                  }}
                  //  onClick={handleButtonShareHolders()}
                >
                  Tu perfil
                </Button>
              </NavLink>*/}
            </div>
          )}
        </Drawer>
      </Hidden>
      {popupLogout ? (
        <PopupLogout open={popupLogout} exit={handleClosepopupLogout} />
      ) : (
        ""
      )}
    </div>
  );
}

SidebarAdmin.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
