/*eslint-disable*/
import React, { Fragment, useState, useEffect, useContext } from "react";

import { UrlServicesContext } from "../UrlServicesContext";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  //Collapse,
  ListItemText,
  List,
  Hidden,
  Drawer,
  // Icon,
  Box,
  Divider,
  Grid,
  Avatar,
  Button,
  Stack,
} from "@mui/material";

import {
  //ExpandMore,
  //ExpandLess,
  AccountCircle,
  Remove,
  GridView,
  ExitToApp,
  Store,
  Source,
} from "@mui/icons-material";

import mujerAvatar from "assets/img/undraw_mujer.png";
import hombreAvatar from "assets/img/undraw_hombre.png";

// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import PopupLogout from "views/dialog/PopupLogout";

import {
  sidebarStyle,
  ListItemStyle,
} from "assets/jss/material-dashboard-react/components/sidebarStyle.js";

const useStyles = makeStyles(sidebarStyle);

export default function Sidebar(props) {
  const { logo, image, routes } = props;

  const { UserAuth } = useContext(UrlServicesContext);

  const classes = useStyles();

  const [marginTop, setMarginTop] = useState("90%");
  const [popupLogout, setpopupLogout] = React.useState(false);
  //const [open, setOpen] = useState(false);
  //const [menu, setMenu] = useState(0);

  const picture =
    UserAuth.avatar === null
      ? UserAuth.gender === 1
        ? hombreAvatar
        : mujerAvatar
      : UserAuth.avatar;

  const mMobile = 0.37;
  const mDesktop = 0.44;

  useEffect(() => {
    //<!-- Start of HubSpot Embed Code -->
   /* const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "hs-script-loader";
    script.async = true;
    script.defer = true;
    script.src = "//js-na1.hs-scripts.com/44966052.js";
    document.body.appendChild(script);

    // Limpieza: remover el script cuando el componente se desmonte
    return () => {
      document.body.removeChild(script);
    };*/
    //<!-- End of HubSpot Embed Code -->

     // Crear primer script
       const script1 = document.createElement('script');
       script1.type = 'text/javascript';
       script1.src = 'https://cdn.botpress.cloud/webchat/v1/inject.js';
       document.body.appendChild(script1);
   
       // Crear segundo script
       const script2 = document.createElement('script');
       script2.type = 'text/javascript';
       script2.src = 'https://mediafiles.botpress.cloud/7cb3b37b-ddaa-441d-b25e-09fe4e30726d/webchat/config.js';
   
       // Asegurar que el segundo script se agregue después de que el primero haya cargado
       script1.onload = () => {
         document.body.appendChild(script2);
       };
   
       // Función de limpieza
       return () => {
         if (script1) {
           document.body.removeChild(script1);
         }
         if (script2) {
           document.body.removeChild(script2);
         }
       };
/*
    const script1 = document.createElement("script");
    script1.src = "https://cdn.botpress.cloud/webchat/v1/inject.js";
    script1.async = true;
    script1.onload = () => {
      // Configuración de Botpress después de cargar el script
      window.botpressWebChat.init({
        composerPlaceholder: "Chat con PalankasBot",
        botConversationDescription: "Chat para nuestros clientes",
        botId: "7cb3b37b-ddaa-441d-b25e-09fe4e30726d",
        hostUrl: "https://cdn.botpress.cloud/webchat/v1",
        messagingUrl: "https://messaging.botpress.cloud",
        clientId: "7cb3b37b-ddaa-441d-b25e-09fe4e30726d",
        webhookId: "5e2cf2c2-e938-4d3e-90e0-c2d31fb41b81",
        lazySocket: true,
        themeName: "prism",
        botName: "PalankasBot",
        stylesheet: "https://webchat-styler-css.botpress.app/prod/e9208d7e-0559-43a4-a7e0-9a376d97a977/v13503/style.css",
        frontendVersion: "v1",
        // useSessionStorage: true,
        showBotInfoPage: true,
        //showPoweredBy: true,
        theme: "prism",
        themeColor: purpleColor,
        allowedOrigins: [],
      });
    };
    document.body.appendChild(script1);

    // Función de limpieza
    return () => {
      document.body.removeChild(script1);
    };*/
  }, []);

  useEffect(() => {
    function updateMarginTop() {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const isMobile = screenWidth < 1300; // Define tu propia lógica para determinar si es un dispositivo móvil

      let newMarginTop;
      if (isMobile) {
        newMarginTop = `${screenHeight * mMobile}px`;
      } else {
        newMarginTop = `${screenHeight * mDesktop}px`;
      }
      setMarginTop(newMarginTop);
    }

    updateMarginTop();
    window.addEventListener("resize", updateMarginTop);
    return () => window.removeEventListener("resize", updateMarginTop);
  }, []);

  const linksUrl = (url) => () => {
    // if (url === "/dashboard")
    //setOpen(false);
    props.url(url);
  };

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }

  const handleOpenpopupLogout = () => () => {
    setpopupLogout(true);
  };

  const handleClosepopupLogout = () => {
    setpopupLogout(false);
  };

  const urlLinksIcon = (iconSelect) => {
    switch (iconSelect) {
      case "/datacompany":
        return (
          <Store
            sx={{ fontSize: 24 }}
            className={classNames(classes.itemIcon, {
              [" " + classes.purpleIcon]: activeRoute(
                "/customers" + iconSelect
              ),
            })}
          />
        );
      case "/myaccount":
        return (
          <AccountCircle
            sx={{ fontSize: 24 }}
            className={classNames(classes.itemIcon, {
              [" " + classes.purpleIcon]: activeRoute(
                "/customers" + iconSelect
              ),
            })}
          />
        );
      case "/documentscompanies":
        return (
          <Source
            sx={{ fontSize: 24 }}
            className={classNames(classes.itemIcon, {
              [" " + classes.purpleIcon]: activeRoute(
                "/customers" + iconSelect
              ),
            })}
          />
        );
      default:
        return (
          <Remove
            sx={{ fontSize: 24 }}
            className={classNames(classes.itemIcon, {
              [" " + classes.purpleIcon]: activeRoute(
                "/customers" + iconSelect
              ),
            })}
          />
        );
    }
  };

  /*const handleOpen = (pro) => () => {
      let statusMenu = open;
      if (menu != pro) {
        statusMenu = false;
      }

      setOpen(!statusMenu);
      setMenu(pro);
    };*/

  var links = (
    <List className={classes.list}>
      <NavLink
        to={"/customers/dashboard"}
        //className={activePro + classes.item}
        activeClassName="active"
      >
        <ListItemStyle
          button
          onClick={linksUrl("/dashboard")}
          className={classes.itemLinkCollapse}
        >
          <GridView
            sx={{ fontSize: 24 }}
            className={classNames(classes.itemIcon, {
              [" " + classes.purpleIcon]: activeRoute("/customers/dashboard"),
            })}
          />
          <ListItemText
            primary="Dashboard"
            // className={classes.itemLinkCollapseName}
            className={classNames(classes.itemText, "", {
              [classes.itemTextRTL]: props.rtlActive,
              [" " + classes.itemTextPurple]: activeRoute(
                "/customers/dashboard"
              ),
            })}
            disableTypography={true}
          />
        </ListItemStyle>
      </NavLink>
      {routes
        .sort((a, b) => a.resource.nOrder - b.resource.nOrder) // Ordenar los elementos según nOrder
        .map((prop, key) => {
          return (
            <Fragment key={key}>
              {prop.resource.path !== "/myaccount" ? (
                <Fragment key={key}>
                  <NavLink
                    to={"/customers" + prop.resource.path}
                    //className={activePro + classes.item}
                    activeClassName="active"
                    key={key}
                  >
                    <ListItemStyle
                      button
                      //className={classes.itemLink + listItemClasses}
                      onClick={linksUrl(prop.resource.path)}
                    >
                      {urlLinksIcon(prop.resource.path)}
                      <ListItemText
                        primary={prop.resource.name}
                        className={classNames(classes.itemText, "", {
                          [classes.itemTextRTL]: props.rtlActive,
                          [" " + classes.itemTextPurple]: activeRoute(
                            "/customers" + prop.resource.path
                          ),
                        })}
                        disableTypography={true}
                      />
                    </ListItemStyle>
                  </NavLink>
                  {/* <Fragment key={prop.resource.id}>
              <ListItem
                key={prop.resource.id}
                button
                onClick={handleOpen(prop.resource.id)}
                className={classes.itemLinkCollapse}
              >
                <Remove />
                <ListItemText
                  primary={prop.resource.name}
                  className={classes.itemLinkCollapseName}
                  disableTypography={true}
                />
                {menu === prop.resource.id && open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse
                in={menu === prop.resource.id ? open : false}
                timeout="auto"
                unmountOnExit
                style={{ backgroundColor: "rgb(247, 247, 247)" }}
              >
                {
                  <List component="div" key={key}>
                    {prop.resource.map((prop2) => {
                      return (
                        <ListItem
                          key={prop2.id}
                          button
                          onClick={linksUrl(prop2.path)}
                          className={classes.itemLinkCollapseSubmenu}
                        >
                          <ArrowRight
                            style={{
                              minWidth: " 0px !important",
                              color: "#000",
                            }}
                          />
                          <ListItemText
                            primary={prop2.name}
                            className={classes.itemLinkCollapseNameSub}
                            disableTypography={true}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                }
              </Collapse>*/}
                </Fragment>
              ) : (
                <Box sx={{ marginTop: marginTop }}>
                  <NavLink
                    to={"/customers" + prop.resource.path}
                    //className={activePro + classes.item}
                    activeClassName="active"
                    key={key}
                  >
                    <Divider />
                    <ListItemStyle
                      button
                      //className={classes.itemLink + listItemClasses}
                      onClick={linksUrl(prop.resource.path)}
                    >
                      {urlLinksIcon(prop.resource.path)}
                      <ListItemText
                        primary={prop.resource.name}
                        className={classNames(classes.itemText, "", {
                          [classes.itemTextRTL]: props.rtlActive,
                          [" " + classes.itemTextPurple]: activeRoute(
                            "/customers" + prop.resource.path
                          ),
                        })}
                        disableTypography={true}
                      />
                    </ListItemStyle>
                  </NavLink>
                  <Grid container>
                    <Grid xs={12} md={12}>
                      <Stack direction="row" spacing={1} sx={{ marginLeft: 2 }}>
                        <Avatar
                          src={picture}
                          style={{ marginTop: 1, width: 30, height: 30 }}
                        />
                        <div style={{ marginTop: 1, fontSize: 10 }}>
                          <b>
                            {UserAuth.allname &&
                              (UserAuth.allname.length > 16
                                ? UserAuth.allname.substring(0, 16) + "..."
                                : UserAuth.allname)}
                            <div
                              style={{
                                marginTop: "-8px",
                                color: "gray",
                                fontSize: 9,
                              }}
                            >
                              {UserAuth.profile_name}
                            </div>
                          </b>
                        </div>
                        <Button
                          aria-haspopup="true"
                          onClick={handleOpenpopupLogout()}
                          sx={{ float: "right", marginTop: "-20px" }}
                        >
                          <ExitToApp
                            style={{
                              fontSize: 20,
                              color: "#000",
                            }}
                          />
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Fragment>
          );
        })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <img src={logo} alt="logo" className={classes.img} />
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <AdminNavbarLinks />
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          onClose={props.handleDrawerToggle}
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : (
            <div className={classes.background} />
          )}
        </Drawer>
      </Hidden>
      {popupLogout ? (
        <PopupLogout open={popupLogout} exit={handleClosepopupLogout} />
      ) : (
        ""
      )}
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
