import React, { Fragment, useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { UrlServicesContext } from "../../components/UrlServicesContext";

import { makeStyles } from "@mui/styles";
import {
  Grid,
  Alert,
  Card,
  CardActions,
  IconButton,
  CardHeader,
  Link,
  Avatar,
} from "@mui/material";
import { Source, SaveAlt, Description } from "@mui/icons-material";
import Moment from "moment";
import axios from "axios";

import {
  cardBodyStyle,
  purpleColor,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

import loaderGif from "assets/img/loading.gif";
import folder from "assets/img/undraw_Folder_files_re_2cbm.png";

const useStyles = makeStyles(cardBodyStyle);

export default function ViewsDocumentCompanyCustomers(props) {
  const classes = useStyles();

  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);
  const [mensageSystem, setMensageSystem] = useState("");
  const [returnLogin, setReturnLogin] = useState(false);
  const [dataDocuments, setDataDocuments] = useState([]);

  const { urlServices, UserAuth } = useContext(UrlServicesContext);

  let keyAuthorization = UserAuth.Session;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Scroll to top smoothly
        window.scrollTo({ top: 0, behavior: "smooth" });

        const response = await axios.get(
          `${urlServices}documents-companies/company/${UserAuth.companyId}`,
          {
            headers: {
              Authorization: "Bearer " + keyAuthorization,
            },
          }
        );

        if (response.status === 200) {
          if (response.data) {
            setDataDocuments(response.data.upload_documents_companies);
            setLoader(false);
          } else {
            setLoader(false);
          }
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            setMensageSystem(
              "La sesión ha expirado, vuelva a iniciar sesión ..."
            );
            setError(true);

            setTimeout(() => {
              localStorage.clear();
              setReturnLogin(true);
            }, 4000);
          } else if (error.response.status === 500) {
            setMensageSystem("Error en la consulta con el servidor.");
            setError(true);
          }
        } else {
          console.error("Error fetching documents:", error);
        }
      }
    };

    fetchData();
  }, [urlServices, keyAuthorization, UserAuth.companyId]);

  if (returnLogin) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <Grid container spacing={1} style={{ marginBottom: 25 }}>
        <Grid item xs={12} sm={12}>
          {error ? <Alert severity="error">{mensageSystem}</Alert> : ""}
          <div className={classes.cardTitleBlack}>
            <Source className={classes.iconFilter} /> Documentos &nbsp;&nbsp;{" "}
            <span
              style={{
                background: "rgb(237, 247, 237)",
                color: "rgb(30, 70, 32)",
                padding: "5px 10px",
                borderRadius: "50%",
              }}
            >
              {dataDocuments.length}
            </span>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={1} className={classes.containerProfile}>
        {loader ? (
          <Grid item xs={12} sm={12}>
            <center>
              <img
                alt="loaderGif"
                src={loaderGif}
                style={{ width: 60, margin: "10% 0" }}
              />
            </center>
          </Grid>
        ) : (
          <Fragment>
            {dataDocuments.length > 0 ? (
              dataDocuments.map((data) => {
                let date =
                  "Cargado el: " + Moment(data.createdAt).format("DD-MM-YYYY");
                return (
                  <Grid item xs={12} md={4} key={data.id}>
                    <Card
                      sx={{
                        border: "1px solid #e9ebee",
                        borderLeft: "1px solid #000",
                        backgroundColor: "#FAFAFA",
                      }}
                      // variant="outlined"
                    >
                      <CardHeader
                        avatar={
                          <Avatar
                            sx={{ bgcolor: purpleColor }}
                            aria-label="recipe"
                          >
                            <Description />
                          </Avatar>
                        }
                        title={data.typed_ocuments.name}
                        subheader={date}
                        // titleTypographyProps={{variant:'h6' }}
                        subheaderTypographyProps={{ fontSize: 10 }}
                      />
                      <CardActions style={{ float: "right" }}>
                        <IconButton
                          aria-label="fingerprint"
                          style={{ fontSize: 12, color: "#000" }}
                        >
                          <Link
                            href={urlServices + "documents/" + data.document}
                            underline="none"
                            color="inherit"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Descargar &nbsp;
                            <SaveAlt sx={{ verticalAlign: "middle" }} />
                          </Link>
                        </IconButton>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={12} md={12}>
                <Alert severity="warning">Ningún documento cargado</Alert>
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <center>
                <img
                  alt="folder"
                  src={folder}
                  style={{ width: 150, margin: "10% 0 5% 0" }}
                />
              </center>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Fragment>
  );
}
