import React, { useState, useContext, useEffect, Fragment } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";
import { Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@mui/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { Add, AutoAwesomeMotion, Search, Cancel } from "@mui/icons-material";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import clsx from "clsx";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import axios from "axios";
import { TextField, Box, Tooltip, Grid, Alert } from "@mui/material";
import Moment from "moment";

import StatusAdmin from "views/dialog/StatusAdmin";
import NewTrackingCompanyAdmin from "views/dialog/NewTrackingCompanyAdmin";
import CommentStatusTracking from "views/dialog/CommentStatusTracking";
import ChangeStatusTracking from "views/dialog/ChangeStatusTracking";

import loaderGif from "assets/img/loading.gif";

import {
  cardBodyStyle,
  ButtonStyle0,
  ButtonTableGrid1,
  ButtonTableGrid2,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function TrackingCompaniesListAdmin() {
  const classes = useStyles();

  const [idRow, setIdRow] = useState("");
  const [rows, setRows] = useState([]);
  const [openStatus, setOpenStatus] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [openCommentStatus, setOpenCommentStatus] = useState(false);
  const [openStatusTracking, setOpenStatusTracking] = useState(false);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);
  const [mensageSystem, setMensageSystem] = useState("");
  const [returnLogin, setReturnLogin] = React.useState(false);
  const [searchText, setSearchText] = useState("");
  const [rowsResponse, setRowsResponse] = useState([]);

  let resultSearch = "";

  const { urlServices, UserAuth } = useContext(UrlServicesContext);

  let keyAuthorization = UserAuth.Session;

  const [state] = useState({
    columns: [
      {
        headerName: "Fecha Constitución",
        field: "company.fechaConstitucion",
        headerClassName: "super-app-theme--header",
        headerAlign: "center",
        renderCell: (params) => {
          return (
            <span>
              {Moment(params.row.company.fechaConstitucion).format(
                "DD-MM-YYYY"
              )}{" "}
            </span>
          );
        },
        cellClassName: (params) => {
          return clsx("super-app", {
            celAll: params.row.company.fechaConstitucion,
          });
        },
        align: "center",
        sortable: false,
        filtering: false,
        hideable: false,
        width: 150,
      },
      {
        headerName: "Empresa",
        field: "company.razonSocial",
        // headerAlign: "center",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => {
          return <span>{params.row.company.razonSocial} </span>;
        },
        cellClassName: (params) => {
          return clsx("super-app", {
            celAll: params.row.company.razonSocial,
          });
        },
        sortable: false,
        hideable: false,
        flex: 1,
      },
      /* {
        headerName: "Nro. Ruc",
        field: "company.nRuc",
        // headerAlign: "center",
        headerClassName: "super-app-theme--header",
        cellClassName: (params) => {
          return clsx("super-app", {
            celAll: params.row.company.nRuc,
          });
        },
        renderCell: (params) => {
          const color = params.row.company.nRuc !== "" ? "" : "red";
          const text =
            params.row.company.nRuc !== "" ? params.value : "Por registrar";
          return (
            <>
              <span style={{ color: color, fontSize: 12 }}>{text}</span>
            </>
          );
        },
        sortable: false,
        hideable: false,
        width: 120,
      },*/
      {
        headerName: "Estatus",
        field: "status_tracking",
        // headerAlign: "center",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => {
          const background =
            params.row.status_tracking.id !== 10
              ? "rgb(255, 244, 229)"
              : "rgb(237, 247, 237)";
          const color =
            params.row.status_tracking.id !== 10
              ? "rgb(102, 60, 0)"
              : "rgb(30, 70, 32)";
          return (
            <>
              <b
                style={{
                  background: background,
                  color: color,
                  padding: "3px 8px",
                  fontSize: 10,
                  borderRadius: 5,
                }}
              >
                {params.row.status_tracking.name}
              </b>
            </>
          );
        },
        width: 200,
      },
      {
        field: "action1",
        headerName: "----",
        headerAlign: "center",
        headerClassName: "super-app-theme--header",
        width: 110,
        renderCell: (params) =>
          params.row.status_tracking.id !== 10 ? (
            <ButtonTableGrid1 onClick={openStatusTrackingValue(params)}>
              Cambiar estatus
            </ButtonTableGrid1>
          ) : (
            ""
          ),
      },
      {
        field: "action2",
        headerName: "----",
        headerAlign: "center",
        headerClassName: "super-app-theme--header",
        width: 110,
        renderCell: (params) =>
          params.row.status_tracking.id !== 10 ? (
            <ButtonTableGrid2 onClick={openCommentStatusValue(params)}>
              Comentario
            </ButtonTableGrid2>
          ) : (
            ""
          ),
      },
      {
        headerName: "-----",
        field: "actions",
        type: "actions",
        width: 10,
        headerClassName: "super-app-theme--header",
        cellClassName: (params) => {
          return clsx("super-app", {
            celActions: params.value,
          });
        },
        getActions: (params) => {
          const actions = [];

          /* if (parseInt(params.row.status) === 0) {
            // Si el perfil está deshabilitado, mostramos el icono para habilitarlo
            actions.push(
              <GridActionsCellItem
                key="enable"
                icon={
                  <Tooltip title="Habilitar" arrow>
                    <AddCircle style={{ fontSize: 24, color: "green" }} />
                  </Tooltip>
                }
                label="Habilitar"
                onClick={openStatusValue(params)}
              />
            );
          } else {*/
          actions.push(
            <GridActionsCellItem
              key="disable"
              icon={
                <Tooltip title="Eliminar" arrow>
                  <Cancel style={{ fontSize: 24, color: "#000" }} />
                </Tooltip>
              }
              label="Eliminar"
              onClick={openStatusValue(params)}
            />
          );
          // }
          return actions;
        },
      },
    ],
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    RefreshTable();
  }, []);

  // Función para eliminar acentos de una cadena de texto
  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const handleSearchChange = (event) => {
    // Filtrar los datos según el valor de búsqueda
    const searchText = event.target.value.trim().toLowerCase();
    if (searchText !== "") {
      resultSearch = rows.filter((item) =>
        removeAccents(item.company.razonSocial.toLowerCase()).includes(
          removeAccents(searchText)
        )
      );
      setRows(resultSearch);
    } else {
      setRows(rowsResponse);
    }

    setSearchText(searchText);
  };

  const RefreshTable = () => {
    setSearchText("");

    axios
      .get(`${urlServices}tracking-companies/list`, {
        headers: {
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let result = response.data.filter(
            (filtered) => filtered.status !== 0
          );

          const sortedData = result.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          setRows(sortedData);
          setRowsResponse(sortedData);
          setLoader(false);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setMensageSystem(
            "La sesión ha expirado, vuelva a iniciar sesión ..."
          );
          setError(true);

          setTimeout(() => {
            localStorage.clear();
            setReturnLogin(true);
          }, 4000);
        } else if (e.response.status === 500) {
          setRows([]);
          setMensageSystem("Error en la consulta con sel servidor.");
          setError(true);
        }
      });
  };

  const handleCloseStatus = () => {
    setOpenStatus(false);
  };

  const handleCloseStatusTracking = () => {
    setOpenStatusTracking(false);
  };

  const openStatusValue = (data) => () => {
    setIdRow(data);
    setOpenStatus(true);
  };

  const openStatusTrackingValue = (data) => () => {
    setIdRow(data.row);
    setOpenStatusTracking(true);
  };

  const openCommentStatusValue = (data) => () => {
    setIdRow(data.row);
    setOpenCommentStatus(true);
  };

  const handleCloseNew = () => {
    setOpenNew(false);
  };

  const handleCloseCommentStatus = () => {
    setOpenCommentStatus(false);
  };

  if (returnLogin) {
    return <Redirect to="/" />;
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <div className={classes.cardTitleBlack}>
          <AutoAwesomeMotion className={classes.iconFilter} /> Seguimiento de
          empresas
        </div>
        <Card>
          <CardBody>
            {error ? <Alert severity="error">{mensageSystem}</Alert> : ""}
            {loader ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <center>
                    <img
                      alt="loaderGif"
                      src={loaderGif}
                      style={{ width: 60, margin: "20% 0" }}
                    />
                  </center>
                </Grid>
              </Grid>
            ) : (
              <Fragment>
                <Grid container>
                  <Grid item xs={6} sm={6}>
                    {/* Campo de búsqueda */}
                    <TextField
                      variant="outlined"
                      value={searchText}
                      onChange={handleSearchChange}
                      style={{ marginBottom: 5 }}
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <Search
                            position="start"
                            style={{
                              color: "gray",
                              fontSize: 22,
                              marginRight: 10,
                            }}
                          />
                        ),
                      }}
                      placeholder="Buscar"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <div style={{ float: "right" }}>
                      <ButtonStyle0
                        onClick={(event) => {
                          setOpenNew(true);
                        }}
                        endIcon={<Add />}
                      >
                        Agregar seguimiento
                      </ButtonStyle0>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Box
                      sx={{
                        "& .super-app.celAll": {
                          fontSize: 12,
                          //backgroundColor: 'rgba(255, 7, 0, 0.55)',
                        },
                        "& .super-app.celActions": {
                          backgroundColor: "rgb(242, 242, 242)",
                        },
                        "& .super-app-theme--header": {
                          backgroundColor: "rgb(242, 242, 242)",
                        },
                        marginTop: 2,
                      }}
                    >
                      <DataGrid
                        rows={rows}
                        columns={state.columns}
                        loading={loader}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                          },
                        }}
                        pageSizeOptions={[5, 10, 20, 50]}
                        disableRowSelectionOnClick={true}
                        checkboxSelection={false}
                        //rowHeight={40}
                        autoHeights
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Fragment>
            )}
          </CardBody>
        </Card>
      </GridItem>
      {openStatus ? (
        <StatusAdmin
          open={openStatus}
          exit={handleCloseStatus}
          id={idRow}
          callBackRefresh={RefreshTable}
          path="tracking-companies"
        />
      ) : (
        ""
      )}
      {openNew ? (
        <NewTrackingCompanyAdmin
          open={openNew}
          exit={handleCloseNew}
          callBackRefresh={RefreshTable}
          rows={rowsResponse}
        />
      ) : (
        ""
      )}
      {openCommentStatus ? (
        <CommentStatusTracking
          open={openCommentStatus}
          exit={handleCloseCommentStatus}
          callBackRefresh={RefreshTable}
          id={idRow}
        />
      ) : (
        ""
      )}
      {openStatusTracking ? (
        <ChangeStatusTracking
          open={openStatusTracking}
          exit={handleCloseStatusTracking}
          callBackRefresh={RefreshTable}
          id={idRow}
        />
      ) : (
        ""
      )}
    </GridContainer>
  );
}
