import React, { Fragment, useState, useContext, useEffect } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

import { makeStyles } from "@mui/styles";
import axios from "axios";
import { Save, SaveAlt } from "@mui/icons-material";
import Papa from "papaparse";
import {
  DialogContent,
  Grid,
  Snackbar,
  Slide,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  Alert,
  AlertTitle,
  Dialog,
} from "@mui/material";

import imgLoader from "assets/img/loading.gif";

import {
  cardBodyStyle,
  ButtonExit,
  ButtonContactGreen,
  ButtonLeftSteps2,
  ButtonStyle0,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function NewUserMasive(props) {
  const classes = useStyles();

  const [openAlert, setOpenAlert] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  const [loadersSave, setLoadersSave] = useState(false);
  const [disabledMasive, setDisabledMasive] = useState(true);
  const [rowsImport, setRowsImport] = useState();
  const [rowsAlert, setRowsAlert] = useState([]);
  const [rowsAlertData, setRowsAlertData] = useState([]);
  const [exitAlert, setExitAlert] = useState(false);
  const [dataUserProp, setDataUserProp] = useState([]);
  const [errorData, setErrorData] = useState([]);

  const { urlServices, UserAuth } = useContext(UrlServicesContext);

  let keyAuthorization = UserAuth.Session;

  useEffect(() => {
    if (props.rows.length > 0) {
      const emails = []; // Array para almacenar los correos electrónicos
      props.rows.forEach((prop) => {
        emails.push(prop.email); // Agregar cada correo electrónico al array
      });
      setDataUserProp({ ...dataUserProp, emails: emails }); // Establecer el estado con el array de correos electrónicos
    }
  }, []);

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  const submitForm = (e) => {
    e.preventDefault();
    setOpenAlert(false);
    setLoadersSave(true);

    const userPromises = [];

    rowsImport.users.forEach((user) => {
      const randomNumber = Math.floor(Math.random() * 900000) + 100000;
      const valuesContact = {
        email: user.email.toLowerCase().trim(),
        documentId: user.documentId,
        name: user.name,
        lastName: user.lastName,
        allNames: user.name + " " + user.lastName,
        genderId: user.gender,
        profileId: 2,
        password: randomNumber.toString(),
        code: randomNumber,
        companyId: props.companyId,
        status: 99,
      };
      userPromises.push(insertUsers(valuesContact));
    });
    // Esperar a que se completen todas las llamadas a insertUser
    Promise.all(userPromises)
      .then((responses) => {
        setOpenAlert(true);
        setMensaje("Datos registrados con éxito.");
        setErrorAlert("success");
        setDisabledMasive(true);
        setLoadersSave(false);

        // Obtener los datos registrados de cada respuesta
        const registeredData = responses.map((response) => response.data);

        let dataSend = props.rows.concat(registeredData);
        //let dataSend = [...props.rows, ...dataSendUsers]; O ESTE

        props.callBackRefresh(1, dataSend);
        setRowsImport([]);
      })
      .catch((error) => {
        setLoadersSave(false);
      });
  };

  const insertUsers = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${urlServices}users`, data, {
          headers: {
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then((response) => {
          response.data.user.profile = {
            id: 2,
            name: "Accionista",
            color: "#fffec2",
          };

          resolve({ response: response, data: response.data.user }); // Resolvemos la promesa con la respuesta de axios y los datos enviados
        })
        .catch((error) => {
          setErrorData((prevErrorData) => ({
            ...prevErrorData,
            user: [...(prevErrorData.user || []), data], // Agregar el usuario que ha dado error a la lista de errores
          }));
          reject(error); // Rechazamos la promesa con el error de axios
        });
    });
  };

  const arrayDNY = (data) => {
    if (data.length > 0) {
      return (
        <Fragment>
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow style={{ backgroundColor: "#F2F2F2" }}>
                  <TableCell
                    colSpan={9}
                    style={{
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    HAY QUE ELIMINAR ESTOS DATOS DEL DOCUMENTO QUE DESEA SUBIR
                  </TableCell>
                </TableRow>
                <TableRow style={{ backgroundColor: "tomato" }}>
                  <TableCell
                    colSpan={9}
                    style={{
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    ESTOS CORREO ELECTRÓNICOS YA ESTÁN REGISTRADOS EN EL SISTEMA
                  </TableCell>
                </TableRow>
                <TableRow className={classes.titleFilterAction}>
                  <TableCell
                    style={{
                      fontWeight: "600",
                      textAlign: "center",
                      fontSize: 14,
                      backgroundColor: "#F2F2F2",
                    }}
                  >
                    Correo Electrónico
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "600",
                      textAlign: "center",
                      fontSize: 14,
                      backgroundColor: "#F2F2F2",
                    }}
                  >
                    Usuario
                  </TableCell>
                </TableRow>
                {data.map((row, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell
                        style={{
                          textAlign: "center",
                          fontSize: 12,
                          color: "red",
                        }}
                      >
                        {row.email}
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "center",
                          fontSize: 12,
                          color: "red",
                        }}
                      >
                        {row.name + " " + row.lastName}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Fragment>
      );
    }
  };

  const arrayUsers = (data) => {
    if (data.length > 0) {
      return (
        <Fragment>
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow style={{ backgroundColor: "#F2F2F2" }}>
                  <TableCell
                    colSpan={9}
                    style={{
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    ESTOS USUARIOS NO FUERON REGISTRADOS
                  </TableCell>
                </TableRow>
                <TableRow style={{ backgroundColor: "tomato" }}>
                  <TableCell
                    colSpan={9}
                    style={{
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    ESTOS CORREO ELECTRÓNICOS YA ESTÁN REGISTRADOS EN EL SISTEMA
                  </TableCell>
                </TableRow>
                <TableRow className={classes.titleFilterAction}>
                  <TableCell
                    style={{
                      fontWeight: "600",
                      textAlign: "center",
                      fontSize: 14,
                      backgroundColor: "#F2F2F2",
                    }}
                  >
                    Correo Electrónico
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "600",
                      textAlign: "center",
                      fontSize: 14,
                      backgroundColor: "#F2F2F2",
                    }}
                  >
                    Usuario
                  </TableCell>
                </TableRow>
                {data.map((row, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell
                        style={{
                          textAlign: "center",
                          fontSize: 12,
                          color: "red",
                        }}
                      >
                        {row.email}
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "center",
                          fontSize: 12,
                          color: "red",
                        }}
                      >
                        {row.name + " " + row.lastName}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Fragment>
      );
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const changeHandler = (event) => {
    setOpenAlert(false);
    setRowsAlert(false);
    setRowsAlertData([]);

    if (event.target.files[0] !== undefined) {
      // Passing file data (event.target.files[0]) to parse using Papa.parse
      Papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const rowsArray = [];
          const valuesArray = [];
          let out = [];
          const empty = [];

          // Iterating data to get column name and their values
          results.data.forEach((d) => {
            rowsArray.push(Object.keys(d));
            valuesArray.push(Object.values(d));
          });

          results.data.map((row1) => {
            const tieneDatos = Object.values(row1).some(
              (elemento) => elemento !== ""
            );

            if (tieneDatos) {
              if (
                row1.nombre_persona === "" ||
                row1.nombre_persona === undefined
              ) {
                empty.push({ nombre_persona: true });
              }

              if (
                row1.apellido_persona === "" ||
                row1.apellido_persona === undefined
              ) {
                empty.push({ apellido_persona: true });
              }

              if (
                row1.email_persona === "" ||
                row1.email_persona === undefined
              ) {
                empty.push({ email_persona: true });
              }

              if (
                row1.genero_persona === "" ||
                row1.genero_persona === undefined
              ) {
                empty.push({ genero_persona: true });
              }

              const valuesNuewUser = {
                name: row1.nombre_persona,
                lastName: row1.apellido_persona,
                documentId:
                  row1.identificacion_persona &&
                  row1.identificacion_persona !== ""
                    ? row1.identificacion_persona
                    : "",
                email: row1.email_persona,
                gender:
                  row1.genero_persona && row1.genero_persona !== ""
                    ? row1.genero_persona.toLowerCase() === "hombre"
                      ? 1
                      : 2
                    : "",
              };

              out.push(valuesNuewUser);
            }
          });

          if (empty.length > 0) {
            setExitAlert(true);
            setMensaje(
              "Pueda que haya celdas vacías (revisar NOMBRE, APELLIDO, EMAIL Y GÉNERO que son importantes) o verifique que no haya modificado la cabecera del formato original."
            );
            setOpenAlert(true);
            setErrorAlert("error");
          } else if (out.length === 0) {
            setExitAlert(true);
            setMensaje(
              "El documento no puede estar vacío o revisar que estén todos los datos obligatorios."
            );
            setOpenAlert(true);
            setErrorAlert("error");
          } else {
            let datosIgualUser = [];

            if (dataUserProp.emails) {
              datosIgualUser = out
                .flatMap((innerArray) => innerArray) // Aplanar el array multidimensional
                .filter((val2) => dataUserProp.emails.includes(val2.email)); // Filtrar por emails coincidentes
            }

            if (datosIgualUser.length > 0) {
              setExitAlert(true);
              setRowsAlert(true);
              setRowsAlertData(datosIgualUser);
            } else {
              setRowsImport({ users: out });
              setDisabledMasive(false);
            }
          }
        },
      });
    }
  };

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        PaperProps={{
          sx: {
            borderRadius: 6,
          },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                <div className={classes.titleCardFilter}>
                  Carga masiva de usuarios
                </div>
                <div className={classes.lineBottom}></div>
                <br></br>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid
                  container
                  spacing={1}
                  style={{
                    backgroundColor: "#f8f8f8",
                    borderRadius: 5,
                    padding: 10,
                  }}
                >
                  <Grid item xs={12} md={12}>
                    <Alert severity="warning">
                      <AlertTitle>IMPORTANTE</AlertTitle>- Todas las columnas es
                      deseable que se registre la información.
                      <br></br>- La columna{" "}
                      <strong>Identificación de las personas</strong> es
                      importante mantener este formato ej: en formato de "TEXTO"
                      para agregar al inicio 0 si lo requiere. <br></br>- Es
                      importante suministrar la información completa tanto en
                      nombres, correo electrónico y género. <br></br>- La
                      columna <strong>Género</strong> es importante mantener
                      este formato ej: <strong>Mujer o Hombre</strong> <br></br>
                      - Al finalizar con el archivo excel, deberá ir a{" "}
                      <strong>"ARCHIVO y GUARDAR COMO"</strong>, seleccionar el
                      formato del archivo tipo&nbsp;
                      <strong>
                        "(CSV CODIFICADO EN UTF-8 (DELIMITADO POR COMAS))"{" "}
                      </strong>
                      y <strong>"GUARDAR"</strong>.<br></br>
                    </Alert>
                  </Grid>
                  {rowsAlert ? (
                    <Grid item xs={12} md={12} style={{ marginTop: "3%" }}>
                      {arrayDNY(rowsAlertData)}
                      <br></br>
                    </Grid>
                  ) : (
                    ""
                  )}
                  {errorData.user && errorData.user.length > 0 ? (
                    <Grid item xs={12} md={12}>
                      {arrayUsers(errorData.user)}
                      <br></br>
                    </Grid>
                  ) : (
                    ""
                  )}
                  <br></br>
                  <Grid item xs={12} md={12}>
                    <center>
                      {exitAlert ? (
                        <Alert variant="filled" severity="error">
                          <b>
                            Cierre nuevamente la ventana para evitar errores.
                          </b>
                        </Alert>
                      ) : null}
                    </center>
                  </Grid>
                  <br></br>
                  <Fragment>
                    <Grid item xs={12} md={12}>
                      <center>
                        <input
                          accept=".csv"
                          style={{ display: "none" }}
                          onChange={changeHandler}
                          id="contained-button-file"
                          multiple
                          type="file"
                          name="filecsv"
                        />
                        <a
                          href={"/documents/csv_usuarios.csv"}
                          download="csv_usuarios.csv"
                        >
                          <ButtonLeftSteps2 startIcon={<SaveAlt />}>
                            Descargar formato
                          </ButtonLeftSteps2>
                        </a>
                        <label htmlFor="contained-button-file">
                          <ButtonContactGreen component="span">
                            Subir documento
                          </ButtonContactGreen>
                        </label>
                        <br></br>
                        <br></br>
                      </center>
                    </Grid>
                  </Fragment>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Fragment>
                  {loadersSave ? (
                    <center>
                      <img
                        src={imgLoader}
                        alt="loader"
                        style={{
                          width: 60,
                          objectFit: "contain",
                          marginTop: 25,
                        }}
                      />
                    </center>
                  ) : (
                    <div style={{ float: "right" }}>
                      <ButtonExit
                        className={classes.buttonLeft}
                        onClick={handleCloseDialog(true)}
                      >
                        Cerrar
                      </ButtonExit>
                      <ButtonStyle0
                        onClick={submitForm}
                        disabled={disabledMasive}
                        endIcon={<Save />}
                      >
                        Guardar
                      </ButtonStyle0>
                    </div>
                  )}
                </Fragment>
              </Grid>
            </Grid>
            <Snackbar
              open={openAlert}
              autoHideDuration={20000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={errorAlert === "error" ? "error" : "success"}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}
