import React, { Fragment, useContext, useEffect } from "react";
import { UrlServicesContext } from "../../components/UrlServicesContext";

import { makeStyles } from "@mui/styles";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  Alert,
  Card,
  CardActions,
  IconButton,
  CardHeader,
  Link,
  Avatar,
} from "@mui/material";
import { Description, SaveAlt, HighlightOff } from "@mui/icons-material";
import Moment from "moment";

import {
  cardBodyStyle,
  ButtonExit,
  purpleColor,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

import DeleteDocument from "views/dialog/DeleteDocument";

const useStyles = makeStyles(cardBodyStyle);

export default function ViewsDocumentCompany(props) {
  const classes = useStyles();

  const [idRow, setIdRow] = React.useState("");
  const [deleteDocument, setDeleteDocument] = React.useState(false);
  const [dataDocuments, setDataDocuments] = React.useState([]);

  const { urlServices } = useContext(UrlServicesContext);

  useEffect(() => {
    setDataDocuments(props.id.upload_documents_companies);
  }, []);

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  const deleteDoc = (id) => () => {
    setIdRow(id);
    setDeleteDocument(true);
  };

  const handleCloseDeleteDocument = () => {
    setDeleteDocument(false);
  };

  const Refresh = () => {
    const newDataDocuments = dataDocuments.filter((item) => item.id !== idRow);
    setDataDocuments(newDataDocuments);
    props.callBackRefresh();
  };

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        PaperProps={{
          sx: {
            borderRadius: 6,
          },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <br></br>
              <div className={classes.titleCardFilter}>
                Documentos{" "}
                <span
                  style={{
                    background: "rgb(237, 247, 237)",
                    color: "rgb(30, 70, 32)",
                    padding: "5px 10px",
                    borderRadius: "50%",
                  }}
                >
                  {dataDocuments.length}
                </span>
              </div>
              <div className={classes.lineBottom}></div>
              <br></br>
            </Grid>
            {dataDocuments.length > 0 ? (
              dataDocuments.map((data) => {
                let date =
                  "Cargado el: " + Moment(data.createdAt).format("DD-MM-YYYY");
                return (
                  <Grid item xs={12} md={6} key={data.id}>
                    <Card
                      sx={{
                        border: "1px solid #e9ebee",
                        borderLeft: "1px solid #000",
                      }}
                      // variant="outlined"
                    >
                      <CardHeader
                        avatar={
                          <Avatar
                            sx={{ bgcolor: purpleColor }}
                            aria-label="recipe"
                          >
                            <Description />
                          </Avatar>
                        }
                        title={data.typed_ocuments.name}
                        subheader={date}
                        // titleTypographyProps={{variant:'h6' }}
                        subheaderTypographyProps={{ fontSize: 10 }}
                      />
                      <CardActions style={{ float: "right" }}>
                        <IconButton
                          aria-label="fingerprint"
                          style={{ fontSize: 12, color: "#000" }}
                        >
                          <Link
                            href={urlServices + "documents/" + data.document}
                            underline="none"
                            color="inherit"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Descargar &nbsp;
                            <SaveAlt sx={{ verticalAlign: "middle" }} />
                          </Link>
                        </IconButton>
                        <IconButton aria-label="fingerprint" color="error">
                          <HighlightOff onClick={deleteDoc(data.id)} />
                        </IconButton>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={12} md={12}>
                <Alert severity="warning">Ningún documento cargado</Alert>
              </Grid>
            )}
          </Grid>
          {deleteDocument ? (
            <DeleteDocument
              open={deleteDocument}
              exit={handleCloseDeleteDocument}
              callBackRefresh={Refresh}
              id={idRow}
            />
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          <ButtonExit onClick={handleCloseDialog(true)}>Cerrar</ButtonExit>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
