import React, { Fragment, useState, useContext } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";
import { Redirect } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import axios from "axios";
import {
  //TextField,
  Slide,
  Snackbar,
  Grid,
  DialogActions,
  DialogContent,
  Alert,
  Dialog,
} from "@mui/material";
//import { EventAvailable } from "@mui/icons-material";

import LoadStatusTracking from "components/services/LoadStatusTracking";
import LoadCompanies from "components/services/LoadCompanies";

import {
  cardBodyStyle,
  ButtonExit,
  ButtonStyle0,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function NewTrackingCompanyAdmin(props) {
  const classes = useStyles();

  const [openAlert, setOpenAlert] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  const [openAlertSuccess, setopenAlertSuccess] = useState(false);
  const [mensaje_success, setmensaje_success] = useState("");
  const [returnLogin, setReturnLogin] = React.useState(false);
  const [error, setError] = useState(false);
  const [mensageSystem, setMensageSystem] = useState("");

  const { urlServices, UserAuth } = useContext(UrlServicesContext);

  let keyAuthorization = UserAuth.Session;

  const [values, setValues] = useState({
    company: "",
    companyData: "",
    //limitDays: "",
    statusTrackingData: "",
    statusTracking: "",
    //errorLimitDays: false,
    errorStatusTracking: false,
    errorCompany: false,
  });

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  /*const handleChange = (prop) => (event) => {
    setopenAlertSuccess(false);

    setValues({
      ...values,
      [prop]: event.target.value,
      errorLimitDays: false,
    });
    setOpenAlert(false);
  };*/

  const submitForm = (e) => {
    e.preventDefault();
    setErrorAlert("");
    setError(false);
    setopenAlertSuccess(false);

    if (values.company === "") {
      setValues({ ...values, errorCompany: true });
      setMensaje("Seleccione una Empresa.");
      setOpenAlert(true);
      setErrorAlert("error");
    } else if (values.statusTracking === "") {
      setValues({ ...values, errorStatusTracking: true });
      setMensaje("Seleccione un estatus.");
      setOpenAlert(true);
      setErrorAlert("error");
    } /*else if (values.limitDays.length === 0) {
      setValues({ ...values, errorLimitDays: true });
      setMensaje("Debe agregar un tiempo límite para el requerimiento.");
      setOpenAlert(true);
      setErrorAlert("error");
    }*/ else {
      const dataValue = {
        companyId: values.company,
        statusTrackingId: values.statusTracking,
        createdUserId: UserAuth.id,
      };

      axios
        .post(`${urlServices}tracking-companies`, dataValue, {
          headers: {
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then((response) => {
          const userPromises = [];

          let valuesInsert = {
            trackingCompaniesId: response.data.trackingCompanies.id,
            statusTrackingId: values.statusTracking,
            //limitDays: values.limitDays,
          };

          userPromises.push(insertHistoryTrackingCompanies(valuesInsert));

          // Esperar a que todas las promesas se resuelvan
          return Promise.all(userPromises);
        })
        .then((response) => {
          setopenAlertSuccess(true);
          setmensaje_success(response[0].data.message);
          props.callBackRefresh();

          setValues({
            ...values,
            company: "",
            companyData: "",
            // limitDays: "",
            statusTrackingData: "",
            statusTracking: "",
          });
        })
        .catch((e) => {
          if (e.response.status === 401) {
            setMensageSystem(
              "La sesión ha expirado, vuelva a iniciar sesión ..."
            );
            setError(true);

            setTimeout(() => {
              localStorage.clear();
              setReturnLogin(true);
            }, 4000);
          } else if (e.response.status === 500) {
            setMensageSystem("Error en la consulta con sel servidor.");
            setError(true);
          }
        });
    }
  };

  const insertHistoryTrackingCompanies = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${urlServices}history-tracking-companies`, data, {
          headers: {
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then((response) => {
          resolve(response); // Resolvemos la promesa con la respuesta de axios
        })
        .catch((error) => {
          reject(error); // Rechazamos la promesa con el error de axios
        });
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const changestatusTracking = (data) => {
    setValues({
      ...values,
      statusTracking: data !== null ? data.id : "",
      statusTrackingData: data !== null ? data : "",
      errorStatusTracking: false,
    });

    setOpenAlert(false);
  };

  const changeCompany = (data) => {
    setValues({
      ...values,
      company: data !== null ? data.id : "",
      companyData: data !== null ? data : "",
      errorCompany: false,
    });

    setOpenAlert(false);
  };

  if (returnLogin) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        maxWidth={"xs"}
        scroll="body"
        PaperProps={{
          sx: {
            borderRadius: 6,
          },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                {error ? <Alert severity="error">{mensageSystem}</Alert> : ""}
                <div className={classes.titleCardFilter}>Nuevo seguimiento</div>
                <div className={classes.lineBottom}></div>
                <br></br>
              </Grid>
              <Grid item xs={12} md={12}>
                <b>Empresa</b>
              </Grid>
              <Grid item xs={12} md={12}>
                <LoadCompanies
                  value={values.companyData}
                  refresh={changeCompany}
                  error={values.errorCompany}
                  rows={props.rows}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <b>Agregar estatus para comenzar</b>
              </Grid>
              <Grid item xs={12} md={12}>
                <LoadStatusTracking
                  value={values.statusTrackingData}
                  refresh={changestatusTracking}
                  error={values.errorStatusTracking}
                />
              </Grid>
              {/*<Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="off"
                  name="limitDays"
                  value={values.limitDays}
                  onChange={handleChange("limitDays")}
                  label="Límite de días"
                  fullWidth
                  required
                  InputProps={{
                    endAdornment: (
                      <EventAvailable
                        fontSize="small"
                        className={classes.iconInput}
                        position="end"
                      ></EventAvailable>
                    ),
                  }}
                  inputProps={{
                    maxLength: 12,
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                  margin="dense"
                  variant="outlined"
                  error={values.errorLimitDays}
                  style={{ background: "#FFFFFF" }}
                  size="small"
                  helperText="Días límite para culminar el requerimineto"
                />
              </Grid>*/}
              <Grid item xs={12} sm={12}>
                <br></br>
                <br></br>
                <center>
                  <ButtonStyle0 type="submit" onClick={submitForm}>
                    Guardar
                  </ButtonStyle0>
                </center>
              </Grid>
              <Grid item xs={12} sm={12}>
                <center>
                  {openAlertSuccess ? (
                    <Alert severity="success">{mensaje_success}</Alert>
                  ) : (
                    ""
                  )}
                </center>
              </Grid>
            </Grid>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={errorAlert === "error" ? "error" : "success"}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <ButtonExit onClick={handleCloseDialog(true)}>Cerrar</ButtonExit>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
