import React, { Fragment, useState, useContext, useEffect } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";
import { Redirect } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import axios from "axios";
import {
  // TextField,
  Slide,
  Snackbar,
  Grid,
  DialogActions,
  DialogContent,
  Alert,
  Dialog,
} from "@mui/material";
import {
  //EventAvailable,
  PendingActions,
} from "@mui/icons-material";
import Moment from "moment";

import LoadStatusTracking from "components/services/LoadStatusTracking";

import {
  cardBodyStyle,
  ButtonExit,
  ButtonStyle0,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function ChangeStatusTracking(props) {
  const classes = useStyles();

  const [openAlert, setOpenAlert] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  const [openAlertSuccess, setopenAlertSuccess] = useState(false);
  const [mensaje_success, setmensaje_success] = useState("");
  const [returnLogin, setReturnLogin] = React.useState(false);
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [mensageSystem, setMensageSystem] = useState("");
  const [dataHistory, setDataHistory] = useState([]);
  const [dateCompareLimit, setDateCompareLimit] = useState(true);
  const [loader, setLoader] = useState(true);

  const { urlServices, UserAuth } = useContext(UrlServicesContext);

  let keyAuthorization = UserAuth.Session;

  const [values, setValues] = useState({
    //limitDays: "",
    statusTrackingData: "",
    statusTracking: "",
    //errorLimitDays: false,
    errorStatusTracking: false,
  });

  function ValidateDaysHabiles(data) {
    const fechaActual = data;
    const diaSemana = fechaActual.getDay(); // 0 para domingo, 1 para lunes, ..., 6 para sábado

    // Si es sábado (6) o domingo (0), ajustamos la fecha al siguiente lunes
    if (diaSemana === 6) {
      // Sábado
      fechaActual.setDate(fechaActual.getDate() + 2); // Sumamos 2 días para llegar al lunes
    } else if (diaSemana === 0) {
      // Domingo
      fechaActual.setDate(fechaActual.getDate() + 1); // Sumamos 1 día para llegar al lunes
    }

    return fechaActual;
  }

  function obtenerDiferenciaDias(fechaInicio, fechaFin) {
    // Truncar las horas de las fechas
    fechaInicio.setHours(0, 0, 0, 0);
    fechaFin.setHours(0, 0, 0, 0);

    const unDia = 1000 * 60 * 60 * 24; // Milisegundos en un día
    const tiempoInicio = fechaInicio.getTime();
    const tiempoFin = fechaFin.getTime();
    let diferenciaDias = 0;

    for (let i = tiempoInicio; i < tiempoFin; i += unDia) {
      const dia = new Date(i).getDay();
      // Si el día no es sábado ni domingo, aumentamos el contador de días hábiles
      if (dia !== 0 && dia !== 6) {
        diferenciaDias++;
      }
    }

    return diferenciaDias;
  }

  useEffect(() => {
    let lastHistoryTracking = props.id.history_tracking_companies.filter(
      (filtered) => filtered.status === 1
    );

    const fechaActual = ValidateDaysHabiles(new Date());
    const fechaCreacion = ValidateDaysHabiles(new Date(props.id.createdAt));

    const dayDifferent = obtenerDiferenciaDias(fechaCreacion, fechaActual);

    setDateCompareLimit(dayDifferent > 5 ? true : false);

    setDataHistory({
      data: lastHistoryTracking[0],
      comment: JSON.parse(lastHistoryTracking[0].comment),
    });

    if (lastHistoryTracking.length > 0) {
      setLoader(false);
    }
  }, []);

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  /*const handleChange = (prop) => (event) => {
    setopenAlertSuccess(false);

    setValues({
      ...values,
      [prop]: event.target.value,
      errorLimitDays: false,
    });
    setOpenAlert(false);
  };*/

  const submitForm = (e) => {
    e.preventDefault();
    setErrorAlert("");
    setError(false);
    setopenAlertSuccess(false);

    if (values.statusTracking === "") {
      setValues({ ...values, errorStatusTracking: true });
      setMensaje("Seleccione un estatus.");
      setOpenAlert(true);
      setErrorAlert("error");
    } /*else if (values.limitDays.length === 0 && values.statusTracking !== 3) {
      setValues({ ...values, errorLimitDays: true });
      setMensaje("Debe agregar un tiempo límite para el requerimiento.");
      setOpenAlert(true);
      setErrorAlert("error");
    } */ else if (props.id.statusTrackingId === values.statusTracking) {
      setValues({ ...values, errorStatusTracking: true });
      setMensaje("Debe elegir otro estatus que no sea el actual.");
      setOpenAlert(true);
      setErrorAlert("error");
    } else {
      const dataValue = {
        statusTrackingId: values.statusTracking,
      };

      axios
        .put(`${urlServices}tracking-companies/${props.id.id}`, dataValue, {
          headers: {
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then((response) => {
          const userPromises = [];

          let valuesInsert = {
            trackingCompaniesId: props.id.id,
            statusTrackingId: values.statusTracking,
            lastHistoryTracking: dataHistory.data.id,
          };

          /* if (values.limitDays !== "") {
            valuesInsert.limitDays = values.limitDays;
          }*/

          userPromises.push(insertHistoryTrackingCompanies(valuesInsert));

          // Esperar a que todas las promesas se resuelvan
          return Promise.all(userPromises);
        })
        .then((response) => {
          setDisabled(true);
          setopenAlertSuccess(true);
          setmensaje_success(response[0].data.message);
          props.callBackRefresh();
        })
        .catch((e) => {
          if (e.response.status === 401) {
            setMensageSystem(
              "La sesión ha expirado, vuelva a iniciar sesión ..."
            );
            setError(true);

            setTimeout(() => {
              localStorage.clear();
              setReturnLogin(true);
            }, 4000);
          } else if (e.response.status === 500) {
            setMensageSystem("Error en la consulta con sel servidor.");
            setError(true);
          }
        });
    }
  };

  const insertHistoryTrackingCompanies = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${urlServices}history-tracking-companies`, data, {
          headers: {
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then((response) => {
          resolve(response); // Resolvemos la promesa con la respuesta de axios
        })
        .catch((error) => {
          reject(error); // Rechazamos la promesa con el error de axios
        });
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const changestatusTracking = (data) => {
    setValues({
      ...values,
      statusTracking: data !== null ? data.id : "",
      statusTrackingData: data !== null ? data : "",
      errorStatusTracking: false,
    });

    setOpenAlert(false);
  };

  if (returnLogin) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        maxWidth={"xs"}
        PaperProps={{
          sx: {
            borderRadius: 6,
          },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                {error ? <Alert severity="error">{mensageSystem}</Alert> : ""}
                <div className={classes.titleCardFilter}>Cambiar estatus</div>
                <div className={classes.lineBottom}></div>
                <br></br>
              </Grid>
              <Grid item xs={12} md={12}>
                <PendingActions fontSize="inherit" /> &nbsp;
                <b style={{ fontSize: 12 }}>Estatus actual:</b>
              </Grid>
              <Grid item xs={12} md={12}>
                <Alert
                  // icon={<PendingActions fontSize="inherit" />}
                  icon={false}
                  severity={dateCompareLimit ? "error" : "success"}
                  action={
                    <b
                      style={{
                        marginTop: 2,
                        fontSize: 10,                        
                        marginLeft: "right",
                        padding: "3px 8px",
                        background: loader
                          ? ""
                          : dateCompareLimit
                          ? "rgb(253, 237, 237)"
                          : "rgb(237, 247, 237)",
                        color: loader
                          ? ""
                          : dateCompareLimit
                          ? "rgb(95, 33, 32)"
                          : "rgb(30, 70, 32)",
                      }}
                    >
                      {loader
                        ? ""
                        : dateCompareLimit
                        ? "RETRASADO"
                        : "A TIEMPO"}
                    </b>
                  }
                >
                  <div style={{ fontSize: 12 }}>
                    <b>
                      {props.id.status_tracking.name} <br></br>{" "}
                      {/*<span style={{ fontWeight: 400 }}>
                        Tiempo límite registrado para realizarlo:
                      </span>
                      {loader
                        ? ""
                        : " " +
                          dataHistory.data.limitDays +
                          " día" +
                          (dataHistory.data.limitDays > 1 ? "s" : "")*/}
                      <span style={{ fontWeight: 400 }}>
                        Fecha de registro:{" "}
                      </span>
                      {loader
                        ? ""
                        : Moment(dataHistory.data.createdAt).format(
                            "DD-MM-YYYY"
                          )}
                    </b>
                  </div>
                </Alert>
              </Grid>
              <Grid item xs={12} md={12}>
                <b>Nuevo estatus:</b>
              </Grid>
              <Grid item xs={12} md={12}>
                <LoadStatusTracking
                  value={values.statusTrackingData}
                  refresh={changestatusTracking}
                  error={values.errorStatusTracking}
                />
              </Grid>
              {/*values.statusTracking !== 3 ? (
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="off"
                    name="limitDays"
                    value={values.limitDays}
                    onChange={handleChange("limitDays")}
                    label="Límite de días"
                    fullWidth
                    required
                    InputProps={{
                      endAdornment: (
                        <EventAvailable
                          fontSize="small"
                          className={classes.iconInput}
                          position="end"
                        ></EventAvailable>
                      ),
                    }}
                    inputProps={{
                      maxLength: 12,
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    }}
                    margin="dense"
                    variant="outlined"
                    error={values.errorLimitDays}
                    style={{ background: "#FFFFFF" }}
                    size="small"
                    helperText="Días límite para culminar el requerimineto"
                  />
                </Grid>
              ) : (
                ""
              )*/}
              <Grid item xs={12} sm={12}>
                <br></br>
                <br></br>
                <center>
                  <ButtonStyle0
                    type="submit"
                    onClick={submitForm}
                    disabled={disabled}
                  >
                    Actualizar estatus
                  </ButtonStyle0>
                </center>
              </Grid>
              <Grid item xs={12} sm={12}>
                <center>
                  {openAlertSuccess ? (
                    <Alert severity="success">{mensaje_success}</Alert>
                  ) : (
                    ""
                  )}
                </center>
              </Grid>
            </Grid>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={errorAlert === "error" ? "error" : "success"}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <ButtonExit onClick={handleCloseDialog(true)}>Cerrar</ButtonExit>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
