import React, { useState, useContext, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { UrlServicesContext } from "components/UrlServicesContext";

import { makeStyles } from "@mui/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import {
  Snackbar,
  Button,
  TextField,
  Grid,
  Toolbar,
  OutlinedInput,
  InputLabel,
  FormControl,
  IconButton,
  InputAdornment,
  Slide,
  Alert,
} from "@mui/material";

import logoSimple from "assets/img/logoSimple.png";
import logoPalanka from "assets/img/logoPalanka.png";
import logoPalankabySimple from "assets/img/PalankasbysimpleSAS.png";

import PasswordRecovery from "./dialog/PasswordRecovery";

import {
  ButtonStyle,
  AppBarStyle,
  ColorLinearProgress,
  cardBodyStyle,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function Login() {
  const classes = useStyles();

  const [loaderLogin, setLoaderLogin] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [passwordRecovery, setpasswordRecovery] = useState(false);

  const [values, setValues] = useState({
    mail: "",
    password: "",
    newPassword: "",
    userspass: "",
    showPassword: false,
    errorMail: false,
    errorPassword: false,
  });

  const { urlServices, LoginAuth } = useContext(UrlServicesContext);

  const [alert, setAlert] = useState({
    openAlert: false,
    mensaje_error: "",
    loggedIn: localStorage.getItem("Session") === null ? false : true,
  });

  const handleChange = (prop) => (event) => {
    setAlert({
      ...alert,
      openAlert: false,
    });
    setValues({
      ...values,
      [prop]: event.target.value,
      errorMail: false,
      errorPassword: false,
    });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitFormLogin = () => (event) => {
    event.preventDefault();

    if (values.mail.length === 0) {
      setValues({
        ...values,
        errorMail: true,
      });
      setAlert({
        ...alert,
        openAlert: true,
        mensaje_error: "El campo Correo electrónico es obligatorio!",
      });
    } else if (values.password.length === 0) {
      setValues({
        ...values,
        errorPassword: true,
      });
      setAlert({
        ...alert,
        openAlert: true,
        mensaje_error: "El campo contraseña es obligatorio!",
      });
    } else {
      const data = {
        email: values.mail.trim(),
        password: values.password,
      };

      axios
        .post(`${urlServices}users/login`, data)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.user.status === 0) {
              setAlert({
                ...alert,
                openAlert: true,
                mensaje_error: "Usuario deshabilitado del sistema.",
              });
            } else if (response.data.user.status === 2) {
              setAlert({
                ...alert,
                openAlert: true,
                mensaje_error: "Usuario en espera de aprobación.",
              });
            } else {
              if (response.data.user.status === 99) {
                updateStatusUser(response.data.user.id, response.data.token);
              }

              setLoaderLogin(true);
              setDisabledButton(true);
              LoginAuth(response.data); // SAVE SESSION
              setTimeout(() => {
                setLoaderLogin(false);
                redirectProgile(response.data.user.profileId);
              }, 1000);
            }
          }
        })
        .catch((e) => {
          if (e.response === undefined) {
            setAlert({
              ...alert,
              openAlert: true,
              mensaje_error: "Error de conexión, intente más tarde.",
            });
          } else if (e.response.status === 404) {
            setValues({
              ...values,
              errorMail: true,
            });
            setAlert({
              ...alert,
              openAlert: true,
              mensaje_error: e.response.data.message,
            });
          } else if (e.response.status === 401) {
            setValues({
              ...values,
              errorPassword: true,
            });
            setAlert({
              ...alert,
              openAlert: true,
              mensaje_error: e.response.data.message,
            });
          }
        });
    }
  };

  const updateStatusUser = (id, keyAuthorization) => {
    const data = {
      status: 1,
    };

    axios
      .put(`${urlServices}users/${id}`, data, {
        headers: {
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then((response) => {})
      .catch((e) => {});
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({ openAlert: false });
  };

  const handleOpenPasswordRecovery = () => {
    setpasswordRecovery(true);
  };

  const handleClosePasswordRecovery = () => {
    setpasswordRecovery(false);
  };

  const redirectProgile = (profile) => {
    if (profile === 1) {
      localStorage.setItem("controller", "/superadmin");
      localStorage.setItem("controllerRouter", "/superadmin/dashboard");

      setAlert({ ...alert, loggedIn: true });
    } else {
      localStorage.setItem("controller", "/customers");
      localStorage.setItem("controllerRouter", "/customers/dashboard");

      setAlert({ ...alert, loggedIn: true });
    }
  };

  if (alert.loggedIn) {
    return (
      <Redirect
        from={localStorage.getItem("controller")}
        to={localStorage.getItem("controllerRouter")}
      />
    );
  }

  return (
    <Fragment>
      <AppBarStyle position="static">
        <Toolbar sx={{ minHeight: "50px !important" }}>
          <img
            src={logoPalanka}
            alt="logoheader"
            style={{ width: 70, marginLeft: "5%" }}
          />
        </Toolbar>
      </AppBarStyle>
      {loaderLogin ? (
        <ColorLinearProgress />
      ) : (
        <div style={{ padding: 2 }}></div>
      )}

      <center>
        <Grid container>
          <Grid item sm={3}></Grid>
          <Grid item xs={12} sm={6}>
            <Grid
              container
              component="main"
              style={{
                margin: "10px 0 40px 0",
                padding: "35px 0 60px 0",
                backgroundColor: "#FFFFFF",
                borderRadius: 10,
                border: "1px solid #FAFAFA",
                overflow: "hidden",
              }}
            >
              <Grid item xs={12} sm={12}>
                <img
                  alt="logoPalankabySimple"
                  src={logoPalankabySimple}
                  style={{ width: 110, objectFit: "contain" }}
                />
                <br></br>
                <div className={classes.titleCardLogin}>
                  Bienvenido a Palankas
                </div>
                <span
                  style={{
                    fontSize: 12,
                    textTransform: "none",
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: 500,
                    lineHeight: 1.75,
                    color: "#434D56",
                  }}
                >
                  Formalización inteligente
                </span>
              </Grid>
              <Grid item xs={12} sm={12}>
                <form onSubmit={submitFormLogin()}>
                  <TextField
                    autoComplete="off"
                    name="user"
                    value={values.mail}
                    onChange={handleChange("mail")}
                    label="Correo electrónico"
                    error={values.errorMail}
                    type="email"
                    inputProps={{
                      style: {
                        textAlign: "center",
                      },
                      maxLength: 150,
                    }}
                    style={{ marginTop: 20, width: 400 }}
                    variant="outlined"
                  />
                  <FormControl
                    variant="outlined"
                    sx={{ marginTop: 2, width: 400 }}
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Contraseña
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={values.showPassword ? "text" : "password"}
                      value={values.password}
                      error={values.errorPassword}
                      onChange={handleChange("password")}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <Visibility
                                fontSize="small"
                                className={classes.iconInput}
                              />
                            ) : (
                              <VisibilityOff
                                fontSize="small"
                                className={classes.iconInput}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={80}
                      label="Contraseña"
                    />
                  </FormControl>
                  <br></br>
                  <Button
                    aria-haspopup="true"
                    onClick={handleOpenPasswordRecovery}
                    style={
                      loaderLogin
                        ? {
                            opacity: "0.4",
                            fontSize: 12,
                            textTransform: "none",
                            color: "#434D56",
                          }
                        : {
                            fontSize: 12,
                            textTransform: "none",
                            color: "#434D56",
                          }
                    }
                  >
                    ¿Contraseña olvidada?
                  </Button>
                  <br></br>
                  <ButtonStyle
                    type="submit"
                    onClick={submitFormLogin()}
                    disabled={disabledButton}
                  >
                    Iniciar Sesión
                  </ButtonStyle>
                </form>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={3}></Grid>
        </Grid>

        <Grid
          container
          style={{ width: "50%", marginTop: 3, marginBottom: 15 }}
        >
          <Grid item xs={6} sm={3}>
            <img
              src={logoSimple}
              alt="logobottom"
              style={{ width: 45, opacity: "0.4" }}
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <span
              style={{
                fontSize: 10,
                textTransform: "none",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 500,
                lineHeight: 1.75,
                color: "#A9B2B9",
              }}
            >
              Política de privacidad
            </span>
          </Grid>
          <Grid item xs={6} sm={2}>
            <span
              style={{
                fontSize: 10,
                textTransform: "none",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 500,
                lineHeight: 1.75,
                color: "#A9B2B9",
              }}
            >
              Política de marca
            </span>
          </Grid>
          <Grid item xs={6} sm={2}>
            <span
              style={{
                fontSize: 10,
                textTransform: "none",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 500,
                lineHeight: 1.75,
                color: "#A9B2B9",
              }}
            >
              Condiciones de uso
            </span>
          </Grid>
          <Grid item xs={12} sm={2}>
            <center>
              <span
                style={{
                  fontSize: 10,
                  textTransform: "none",
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: 500,
                  lineHeight: 1.75,
                  color: "#A9B2B9",
                }}
              >
                Acerca de
              </span>
            </center>
          </Grid>
        </Grid>
      </center>

      {alert.openAlert ? (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseAlert}
        >
          <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <Alert
              onClose={handleCloseAlert}
              severity="error"
              elevation={6}
              variant="filled"
            >
              {alert.mensaje_error}
            </Alert>
          </Slide>
        </Snackbar>
      ) : (
        ""
      )}

      {passwordRecovery ? (
        <PasswordRecovery
          open={passwordRecovery}
          exit={handleClosePasswordRecovery}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
}
