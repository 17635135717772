import React, { Fragment } from "react";

import { makeStyles } from "@mui/styles";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  Alert,
} from "@mui/material";

import {
  cardBodyStyle,
  ButtonExit,
  ButtonStyle0,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function StatusAdmin(props) {
  const classes = useStyles();

  const handleChangeSuccess = (pro) => (event) => {
    props.success();
    props.exit();
  };

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        PaperProps={{
          sx: {
            borderRadius: 6,
          },
        }}
      >
        <DialogContent dividers>
          <form noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} style={{ paddingTop: 50 }}>
                <center>
                  <div className={classes.titleCardFilter}>
                    ¿Desea continuar con la actualización de datos?
                  </div>
                  <Alert severity="warning">
                    Te aconsejamos solicitar la actualización de los datos vía
                    correo, sino tienes 100% de certeza sobre la actualización
                    que estás haciendo, no lo hagas.
                  </Alert>
                  <br></br>
                  <ButtonStyle0 onClick={handleChangeSuccess()}>
                    Actualizar datos
                  </ButtonStyle0>
                </center>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <ButtonExit onClick={handleCloseDialog(true)}>Cerrar</ButtonExit>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
