import React, { Fragment, useState, useContext, useEffect } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";
import { Redirect } from "react-router-dom";

import { makeStyles, useTheme } from "@mui/styles";
import axios from "axios";
import {
  Save,
  SaveAlt,
  PersonOutline,
  MailOutline,
  WhatsApp,
  Store,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import Papa from "papaparse";
import Moment from "moment";
import {
  DialogContent,
  Grid,
  Snackbar,
  TextField,
  Slide,
  AppBar,
  Tabs,
  Tab,
  Box,
  Typography,
  FormControl,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  Alert,
  AlertTitle,
  Dialog,
  Paper,
  TableHead,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "dayjs/locale/es";

import LoadStates from "components/services/LoadStates";
import LoadCities from "components/services/LoadCities";
import imgLoader from "assets/img/loading.gif";
import LoadGender from "components/services/LoadGender";
import LoadSectors from "components/services/LoadSectors";
import LoadProfile from "components/services/LoadProfile";

import {
  cardBodyStyle,
  ButtonExit,
  ButtonContactGreen,
  ButtonLeftSteps2,
  ButtonStyle0,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    "aria-controls": `action-tabpanel-${index}`,
  };
}

dayjs.locale("es"); // use Spanish locale globally

export default function NewCompanyAdmin(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [openAlert, setOpenAlert] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  const [loaders, setLoaders] = useState(false);
  const [loadersSave, setLoadersSave] = useState(false);
  const [valueTabs, setValueTabs] = useState(0);
  const [disabledMasive, setDisabledMasive] = useState(true);
  const [disabledCities, setDisabledCities] = useState(true);
  const [rowsImport, setRowsImport] = useState();
  const [rowsAlert, setRowsAlert] = useState([]);
  const [rowsAlertData, setRowsAlertData] = useState([]);
  const [exitAlert, setExitAlert] = useState(false);
  const [error, setError] = useState("");
  const [mensageSystem, setMensageSystem] = useState("");
  const [returnLogin, setReturnLogin] = useState(false);
  const [dataUserProp, setDataUserProp] = useState([]);
  const [dateConstitucion, setDateConstitucion] = useState();
  const [errorListCompanies, setErrorListCompanies] = useState([]);
  const [errorListUsers, setErrorListUsers] = useState([]);

  const { urlServices, UserAuth } = useContext(UrlServicesContext);

  let keyAuthorization = UserAuth.Session;

  const [values, setValues] = useState({
    code: "A" + (Math.floor(Math.random() * 900000) + 100000).toString(),
    fecha_constitucion: "",
    document_id: "",
    razon_social: "",
    nombre_comercial: "",
    email: "",
    phone: "",
    states: "",
    statesData: "",
    cities: "",
    citiesData: "",
    parroquia: "",
    direccion: "",
    referencia: "",
    nameContact: "",
    lastNameContact: "",
    document_idContact: "",
    emailContact: "",
    gender: "",
    genderData: "",
    sectors: "",
    sectorsData: "",
    profile: "",
    profileData: "",
    errorFecha_constitucion: false,
    errorNombre_comercial: false,
    errorDocument: false,
    errorRazon_social: false,
    errorEmail: false,
    errorPhone: false,
    errorStates: false,
    errorCities: false,
    errorDireccion: false,
    errorParroquia: false,
    errorNameContact: false,
    errorLastNameContact: false,
    errorDocument_idContact: false,
    errorEmailContact: false,
    errorGender: false,
    errorSectors: false,
    errorProfile: false,
  });

  useEffect(() => {
    if (props.rows.length > 0) {
      const emails = []; // Array para almacenar los correos electrónicos
      props.rows.forEach((prop) => {
        if (prop.user.length > 0) {
          prop.user.forEach((prop2) => {
            emails.push(prop2.email); // Agregar cada correo electrónico al array
          });
        }
      });
      setDataUserProp({ ...dataUserProp, emails: emails }); // Establecer el estado con el array de correos electrónicos
    }

    //console.log(dataUserProp);
  }, []);

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      errorFecha_constitucion: false,
      errorNombre_comercial: false,
      errorRazon_social: false,
      errorDocument: false,
      errorEmail: false,
      errorPhone: false,
      errorDireccion: false,
      errorParroquia: false,
      errorNameContact: false,
      errorLastNameContact: false,
      errorDocument_idContact: false,
      errorEmailContact: false,
    });

    setDisabledMasive(false);
    setOpenAlert(false);
    setError(false);
  };

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  const submitForm = (e) => {
    e.preventDefault();
    setOpenAlert(false);
    setError(false);

    if (valueTabs === 0) {
      if (values.fecha_constitucion === "") {
        setValues({ ...values, errorFecha_constitucion: true });
        setMensaje('El campo "Fecha de Constitución" es obligatorio.');
        setOpenAlert(true);
        setErrorAlert("error");
      } /*if (values.document_id.length === 0) {
        setValues({ ...values, errorDocument: true });
        setMensaje('El campo "Nro. Ruc" es obligatorio.');
        setOpenAlert(true);
        setErrorAlert("error");
      } else*/ else if (values.razon_social.length === 0) {
        setValues({ ...values, errorRazon_social: true });
        setMensaje('El campo "Razón social" es obligatorio.');
        setOpenAlert(true);
        setErrorAlert("error");
      } else if (values.nombre_comercial.length === 0) {
        setValues({ ...values, errorNombre_comercial: true });
        setMensaje('El campo "Nombre Comercial" es obligatorio.');
        setOpenAlert(true);
        setErrorAlert("error");
      } else if (values.email.length === 0) {
        setValues({ ...values, errorEmail: true });
        setMensaje('El campo "Correo Electrónico" es obligatorio.');
        setOpenAlert(true);
        setErrorAlert("error");
      } else if (values.states === "") {
        setValues({ ...values, errorStates: true });
        setMensaje('El campo "Provincias" es obligatorio.');
        setOpenAlert(true);
        setErrorAlert("error");
      } else if (values.cities === "") {
        setValues({ ...values, errorCities: true });
        setMensaje('El campo "Ciudad" es obligatorio.');
        setOpenAlert(true);
        setErrorAlert("error");
      } else if (values.direccion.length === 0) {
        setValues({ ...values, errorDireccion: true });
        setMensaje('El campo "Dirección" es obligatorio.');
        setOpenAlert(true);
        setErrorAlert("error");
      } else if (values.phone.length === 0) {
        setValues({ ...values, errorPhone: true });
        setMensaje('El campo "Número de whatsapp" es obligatorio.');
        setOpenAlert(true);
        setErrorAlert("error");
      } else if (values.parroquia.length === 0) {
        setValues({ ...values, errorParroquia: true });
        setMensaje('El campo "Parroquia" es obligatorio.');
        setOpenAlert(true);
        setErrorAlert("error");
      } else if (values.sectors === "") {
        setValues({ ...values, errorSectors: true });
        setMensaje('El campo "Sector" es obligatorio.');
        setOpenAlert(true);
        setErrorAlert("error");
      } else if (values.nameContact.length === 0) {
        setValues({ ...values, errorNameContact: true });
        setMensaje(
          'El campo "Nombre de la persona de contacto" es obligatorio.'
        );
        setOpenAlert(true);
        setErrorAlert("error");
      } else if (values.lastNameContact.length === 0) {
        setValues({ ...values, errorLastNameContact: true });
        setMensaje(
          'El campo "Apellidos de la persona de contacto" es obligatorio.'
        );
        setOpenAlert(true);
        setErrorAlert("error");
      } else if (values.document_idContact.length === 0) {
        setValues({ ...values, errorDocument_idContact: true });
        setMensaje(
          'El campo "Documento de la persona de contacto" es obligatorio.'
        );
        setOpenAlert(true);
        setErrorAlert("error");
      } else if (values.emailContact.length === 0) {
        setValues({ ...values, errorEmailContact: true });
        setMensaje(
          'El campo "Correo electrónico de la persona de contacto" es obligatorio.'
        );
        setOpenAlert(true);
        setErrorAlert("error");
      } else if (
        dataUserProp.emails &&
        dataUserProp.emails.includes(values.emailContact)
      ) {
        setValues({ ...values, errorEmailContact: true });
        setMensaje(
          'El "Correo electrónico de la persona de contacto" ya está registrado.'
        );
        setOpenAlert(true);
        setErrorAlert("error");
      } else if (values.gender === "") {
        setValues({ ...values, errorGender: true });
        setMensaje(
          'El campo "Género de la persona de contacto" es obligatorio.'
        );
        setOpenAlert(true);
        setErrorAlert("error");
      } else if (values.profile === "") {
        setValues({ ...values, errorProfile: true });
        setMensaje(
          'El campo "Perfil de la persona de contacto" es obligatorio.'
        );
        setOpenAlert(true);
        setErrorAlert("error");
      } else {
        let result = props.rows.filter(
          (filtered) =>
            filtered.nRuc !== "" && filtered.nRuc === values.document_id
        );

        if (result.length > 0) {
          setDisabledMasive(true);
          setValues({ ...values, errorDocument: true });
          setMensaje("Ya existe una empresa con este Ruc en el sistema.");
          setOpenAlert(true);
          setErrorAlert("error");
        } else {
          setDisabledMasive(false);
          setOpenAlert(false);
          setLoaders(true);

          const dataValue = {
            razonSocial: values.razon_social,
            nombreComercial: values.nombre_comercial,
            nRuc: values.document_id,
            fechaConstitucion: values.fecha_constitucion,
            provinceId: values.states,
            cityId: values.cities,
            parroquia: values.parroquia,
            direccion: values.direccion,
            referencia: values.referencia,
            email: values.email,
            phone: values.phone,
            code: values.code,
            sectorId: values.sectors,
          };

          axios
            .post(`${urlServices}company`, dataValue, {
              headers: {
                Authorization: "Bearer " + keyAuthorization,
              },
            })
            .then((response) => {
              const userPromises = [];

              const randomNumber = Math.floor(Math.random() * 900000) + 100000;

              let valuesContact = {
                email: values.emailContact.toLowerCase().trim(),
                documentId: values.document_idContact,
                name: values.nameContact,
                lastName: values.lastNameContact,
                allNames: values.nameContact + " " + values.lastNameContact,
                genderId: values.gender,
                profileId: values.profile,
                password: randomNumber.toString(),
                code: randomNumber,
                companyId: response.data.company.id,
                status: 99,
              };

              userPromises.push(insertUsers(valuesContact, 1));

              // Esperar a que todas las promesas se resuelvan
              return Promise.all(userPromises);
            })
            .then((response) => {
              setValues({
                ...values,
                code:
                  "A" +
                  (Math.floor(Math.random() * 900000) + 100000).toString(),
                fecha_constitucion: "",
                document_id: "",
                razon_social: "",
                nombre_comercial: "",
                email: "",
                phone: "",
                states: "",
                statesData: "",
                cities: "",
                citiesData: "",
                parroquia: "",
                direccion: "",
                referencia: "",
                nameContact: "",
                lastNameContact: "",
                document_idContact: "",
                emailContact: "",
                gender: "",
                genderData: "",
                sectors: "",
                sectorsData: "",
                profile: "",
                profileData: "",
              });

              setDateConstitucion();
              setDisabledMasive(true);
              setOpenAlert(true);
              setMensaje("Empresa registrada con éxito.");
              setErrorAlert("success");
              setLoaders(false);

              props.callBackRefresh();
            })
            .catch((e) => {
              if (e.response.status === 401) {
                setMensageSystem(
                  "La sesión ha expirado, vuelva a iniciar sesión ..."
                );
                setError(true);

                setTimeout(() => {
                  localStorage.clear();
                  setReturnLogin(true);
                }, 4000);
              } else if (e.response.status === 500) {
                setMensageSystem("Error en la consulta con sel servidor.");
                setError(true);
              }
            });
        }
      }
    } else if (valueTabs === 1) {
      setLoadersSave(true);

      let dataCompanies = rowsImport.companies.length;
      let companiesIDs = [];

      if (dataCompanies > 0) {
        // Array para almacenar todas las promesas de las llamadas axios
        let axiosPromises = [];
        const errorsArray = []; // Array para almacenar los datos que dieron error

        rowsImport.companies.forEach((row1) => {
          const insert = async () => {
            const valueData = {
              code: row1.code,
              fechaConstitucion: row1.fechaConstitucion,
              nRuc: row1.nRuc,
              razonSocial: row1.razonSocial,
              nombreComercial: row1.nombreComercial,
              email: row1.email,
              phone: row1.phone,
              parroquia: row1.parroquia,
              direccion: row1.direccion,
              referencia: row1.referencia,
            };

            // Agregar la promesa de axios a nuestro array
            axiosPromises.push(
              axios
                .post(`${urlServices}company`, valueData, {
                  headers: {
                    Authorization: "Bearer " + keyAuthorization,
                  },
                })
                .then((response) => {
                  companiesIDs.push({
                    id: row1.id,
                    companies_id: response.data.company.id,
                  });
                })
                .catch((error) => {
                  console.log(error);
                  errorsArray.push(valueData); // Guardar los datos que dieron error
                  // setLoadersSave(false);
                })
            );
          };

          insert();
        });

        // Esperar a que todas las promesas se resuelvan
        Promise.all(axiosPromises)
          .then(() => {
            const userPromises = [];

            rowsImport.users.forEach((user) => {
              user.forEach((userData) => {
                // Filtrar companiesIDs para encontrar la correspondencia con el ID de usuario
                const matchingCompany = companiesIDs.find(
                  (company) => userData.id === company.id
                );

                if (matchingCompany) {
                  const randomNumber =
                    Math.floor(Math.random() * 900000) + 100000;

                  const valuesContact = {
                    email: userData.email.toLowerCase().trim(),
                    documentId: userData.documentId,
                    name: userData.name,
                    lastName: userData.lastName,
                    allNames: userData.name + " " + userData.lastName,
                    genderId: userData.gender,
                    profileId: 2,
                    password: randomNumber.toString(),
                    code: randomNumber,
                    companyId: matchingCompany.companies_id,
                    status: 99,
                  };

                  userPromises.push(insertUsers(valuesContact, 0));
                }
              });
            });

            // Esperar a que se completen todas las llamadas a insertUser
            return Promise.allSettled(userPromises);
          })
          .then((results) => {
            // Aquí puedes manejar tanto los éxitos como los errores
            const errorsUsers = results.filter(
              (result) => result.value.isAxiosError
            );

            setErrorListCompanies(errorsArray);
            setErrorListUsers(errorsUsers);

            /* console.log("ERROR Users: ", errorsUsers);
            console.log("ERROR Companies: ", errorsArray);*/

            setOpenAlert(true);
            setMensaje("Datos registrados con éxito.");
            setErrorAlert("success");
            setRowsImport([]);
            setValueTabs(0);
            setDisabledMasive(true);
            setLoadersSave(false);

            props.callBackRefresh();
          })
          .catch((error) => {
            console.log("ENTRO: ", error);
            setLoadersSave(false);
          });
      } else {
        setLoadersSave(false);
        setMensaje("El archivo no puede estar vacío.");
        setOpenAlert(true);
        setErrorAlert("error");
      }
    }
  };

  const insertUsers = (data, value) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${urlServices}users`, data, {
          headers: {
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then((response) => {
          resolve(response); // Resolvemos la promesa con la respuesta de axios
        })
        .catch((error) => {
          //reject(error); // Rechazamos la promesa con el error de axios
          resolve(error);
        });
    });
  };

  const arrayDNY = (data) => {
    if (data.length > 0) {
      return (
        <Fragment>
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow style={{ backgroundColor: "#F2F2F2" }}>
                  <TableCell
                    colSpan={9}
                    style={{
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    HAY QUE ELIMINAR ESTOS DATOS DEL DOCUMENTO QUE DESEA SUBIR
                  </TableCell>
                </TableRow>
                <TableRow style={{ backgroundColor: "tomato" }}>
                  <TableCell
                    colSpan={9}
                    style={{
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    ESTOS RUC YA ESTÁN REGISTRADOS CON ESTAS EMPRESAS EN EL
                    SISTEMA
                  </TableCell>
                </TableRow>
                <TableRow className={classes.titleFilterAction}>
                  <TableCell
                    style={{
                      fontWeight: "600",
                      textAlign: "center",
                      fontSize: 14,
                      backgroundColor: "#F2F2F2",
                    }}
                  >
                    Nro. Ruc
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "600",
                      textAlign: "center",
                      fontSize: 14,
                      backgroundColor: "#F2F2F2",
                    }}
                  >
                    Empresa
                  </TableCell>
                </TableRow>
                {data.map((row, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell
                        style={{
                          textAlign: "center",
                          fontSize: 12,
                          color: "red",
                        }}
                      >
                        {row.nRuc}
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "center",
                          fontSize: 12,
                          color: "red",
                        }}
                      >
                        {row.razonSocial}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Fragment>
      );
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleDateConstitucionChange = (data) => {
    const formattedDate = dayjs(data).format("YYYY-MM-DD");

    setValues({
      ...values,
      fecha_constitucion: formattedDate,
      errorFecha_constitucion: false,
    });
    setOpenAlert(false);
    setDateConstitucion(data);
  };

  const changeStates = (data) => {
    setValues({
      ...values,
      states: data !== null ? data.id : "",
      statesData: data !== null ? data : "",
      errorStates: false,
      cities: "",
      citiesData: "",
    });

    setDisabledCities(data !== null ? false : true);
    setOpenAlert(false);
  };

  const changeCities = (data) => {
    setValues({
      ...values,
      cities: data !== null ? data.id : "",
      citiesData: data !== null ? data : "",
      errorCities: false,
    });

    setOpenAlert(false);
  };

  const changeGender = (data) => {
    setValues({
      ...values,
      gender: data !== null ? data.id : "",
      genderData: data !== null ? data : "",
      errorGender: false,
    });

    setOpenAlert(false);
  };

  const changeSectors = (data) => {
    setValues({
      ...values,
      sectors: data !== null ? data.id : "",
      sectorsData: data !== null ? data : "",
      errorSectors: false,
    });

    setOpenAlert(false);
  };

  const handleChangeTabs = (event, newValue) => {
    setValues({
      ...values,
      fecha_constitucion: "",
      document_id: "",
      razon_social: "",
      nombre_comercial: "",
      email: "",
      phone: "",
      states: "",
      statesData: "",
      cities: "",
      citiesData: "",
      parroquia: "",
      direccion: "",
      referencia: "",
      nameContact: "",
      lastNameContact: "",
      document_idContact: "",
      emailContact: "",
      gender: "",
      genderData: "",
      sectors: "",
      sectorsData: "",
    });

    setDisabledMasive(true);
    setValueTabs(newValue);
  };

  // Función para validar y convertir fechas
  function validarYConvertirFecha(fecha) {
    // Expresión regular para el primer formato: 20/3/24 o 20/3/2024
    const regex1 = /^(\d{1,2})\/(\d{1,2})\/(\d{2}|\d{4})$/;
    // Expresión regular para el segundo formato: 20/3/2024
    const regex2 = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;

    // Verificar si la fecha coincide con alguno de los formatos
    if (regex1.test(fecha) || regex2.test(fecha)) {
      // Extraer partes de la fecha
      const partes = fecha.split("/");
      let año = partes[2];
      // Convertir año de 2 dígitos a 4 dígitos si es necesario
      if (año.length === 2) {
        año = "20" + año; // Suponiendo que todas las fechas son del siglo XXI
      }
      const mes = partes[1].padStart(2, "0"); // Agregar ceros a la izquierda si es necesario
      const dia = partes[0].padStart(2, "0"); // Agregar ceros a la izquierda si es necesario
      // Devolver la fecha en el formato deseado
      return `${año}-${mes}-${dia}`;
    } else {
      // Si la fecha no coincide con ninguno de los formatos, devolver la misma fecha
      return fecha;
    }
  }

  const changeHandler = (event) => {
    setOpenAlert(false);
    setRowsAlert(false);
    setRowsAlertData([]);

    if (event.target.files[0] !== undefined) {
      // Passing file data (event.target.files[0]) to parse using Papa.parse
      Papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const rowsArray = [];
          const valuesArray = [];
          let out = [],
            out2 = [];
          const empty = [];

          // Iterating data to get column name and their values
          results.data.forEach((d) => {
            rowsArray.push(Object.keys(d));
            valuesArray.push(Object.values(d));
          });

          results.data.map((row1, i) => {
            const tieneDatos = Object.values(row1).some(
              (elemento) => elemento !== ""
            );

            if (tieneDatos) {
              if (
                row1.razon_social_empresa === "" ||
                row1.razon_social_empresa === undefined
              ) {
                empty.push({ razon_social: true });
              }

              if (
                row1.nombre_comercial_empresa === "" ||
                row1.nombre_comercial_empresa === undefined
              ) {
                empty.push({ nombre_comercial: true });
              }

              let fecha_constitucion_empresa = validarYConvertirFecha(
                row1.fecha_constitucion_empresa
              );

              if (
                Moment(fecha_constitucion_empresa).format("YYYY-MM-DD") ===
                  "Invalid date" ||
                row1.fecha_constitucion_empresa === undefined
              ) {
                empty.push({ fecha_constitucion: true });
              }

              if (
                row1.direccion_empresa === "" ||
                row1.direccion_empresa === undefined
              ) {
                empty.push({ direccion: true });
              }

              if (
                row1.telefono_celular_empresa === "" ||
                row1.telefono_celular_empresa === undefined
              ) {
                empty.push({ telefono_celular_empresa: true });
              }

              if (
                row1.nombre_persona_1 === "" ||
                row1.nombre_persona_1 === undefined
              ) {
                empty.push({ nombre_persona_1: true });
              }

              if (
                row1.apellido_persona_1 === "" ||
                row1.apellido_persona_1 === undefined
              ) {
                empty.push({ apellido_persona_1: true });
              }

              if (
                row1.email_persona_1 === "" ||
                row1.email_persona_1 === undefined
              ) {
                empty.push({ email_persona_1: true });
              }

              if (
                row1.genero_persona_1 === "" ||
                row1.genero_persona_1 === undefined
              ) {
                empty.push({ genero_persona_1: true });
              }

              /*
              if (row1.n_ruc === "" || row1.n_ruc === undefined) {
                empty.push({ n_ruc: true });
              }             

              if (row1.parroquia === "" || row1.parroquia === undefined) {
                empty.push({ parroquia: true });
              }

              if (
                row1.referencia_direccion === "" ||
                row1.referencia_direccion === undefined
              ) {
                empty.push({ referencia_direccion: true });
              }  

              if (
                row1.email_empresa === "" ||
                row1.email_empresa === undefined
              ) {
                empty.push({ email_empresa: true });
              }  

              if (
                row1.identificacion_persona_contacto === "" ||
                row1.identificacion_persona_contacto === undefined
              ) {
                empty.push({ identificacion_persona_contacto: true });
              }
              */

              const valuesNuewCompanie = {
                id: i,
                code:
                  "A" +
                  (Math.floor(Math.random() * 900000) + 100000).toString(),
                fechaConstitucion: fecha_constitucion_empresa,
                nRuc:
                  row1.n_ruc_empresa && row1.n_ruc_empresa !== ""
                    ? row1.n_ruc_empresa
                    : "",
                razonSocial: row1.razon_social_empresa,
                nombreComercial: row1.nombre_comercial_empresa,
                email: row1.email_empresa,
                phone: row1.telefono_celular_empresa,
                parroquia: row1.parroquia_empresa,
                direccion: row1.direccion_empresa,
                referencia: row1.referencia_direccion_empresa,
                /*nameContact: row1.nombre_persona_contacto,
                lastNameContact: row1.apellido_persona_contacto,
                document_idContact: row1.identificacion_persona_contacto,
                emailContact: row1.email_persona_contacto,
                gender: row1.genero === "H" ? 1 : 2,*/
              };

              out.push(valuesNuewCompanie);

              let valuesNuewUser = []; // Mantener valuesNuewUser como un array

              const valuesNuewUser1 = {
                id: i,
                name: row1.nombre_persona_1,
                lastName: row1.apellido_persona_1,
                documentId:
                  row1.identificacion_persona_1 &&
                  row1.identificacion_persona_1 !== ""
                    ? row1.identificacion_persona_1
                    : "",
                email: row1.email_persona_1,
                gender:
                  row1.genero_persona_1 && row1.genero_persona_1 !== ""
                    ? row1.genero_persona_1.toLowerCase() === "hombre"
                      ? 1
                      : 2
                    : "",
              };

              valuesNuewUser.push(valuesNuewUser1);

              if (row1.nombre_persona_2 && row1.nombre_persona_2 !== "") {
                const valuesNuewUser2 = {
                  id: i,
                  name: row1.nombre_persona_2,
                  lastName: row1.apellido_persona_2,
                  documentId:
                    row1.identificacion_persona_2 &&
                    row1.identificacion_persona_2 !== ""
                      ? row1.identificacion_persona_2
                      : "",
                  email: row1.email_persona_2,
                  gender:
                    row1.genero_persona_2 && row1.genero_persona_2 !== ""
                      ? row1.genero_persona_2.toLowerCase() === "hombre"
                        ? 1
                        : 2
                      : "",
                };
                valuesNuewUser.push(valuesNuewUser2);
              }

              out2.push(valuesNuewUser);
            }
          });

          if (empty.length > 0) {
            setExitAlert(true);
            setMensaje(
              "Pueda que haya celdas vacías (Revisar las columnas RAZON SOCIAL, NOMBRE COMERCIAL, FECHA CONSTITUCIÓN, DIRECCIÓN, TELÉFONO CELULAR EMPRESA y PERSONA_1 que son importantes) o formato de fecha no aceptada (Revisar que cumpla 5/11/2023 o 2023-11-05 o 5/11/23) o verifique que no haya modificado la cabecera del formato original."
            );
            setOpenAlert(true);
            setErrorAlert("error");
          } else if (out.length === 0) {
            setExitAlert(true);
            setMensaje(
              "El documento no puede estar vacío o revisar que estén todos los datos obligatorios."
            );
            setOpenAlert(true);
            setErrorAlert("error");
          } else {
            let datosIgual = props.rows
              .filter((row) => row.nRuc && row.nRuc !== null) // Filtrar solo las filas con nRuc válido
              .map((row) => {
                const filteredData = out.find(
                  (filtered) => filtered.nRuc === row.nRuc
                ); // Buscar en 'out' por nRuc
                if (filteredData) {
                  // Si se encontró un dato coincidente
                  return {
                    nRuc: row.nRuc,
                    razonSocial: row.razonSocial,
                  };
                }
                return null; // Si no se encontró ningún dato coincidente
              })
              .filter((data) => data !== null); // Eliminar los resultados nulos

            let datosIgualUser = [];
            if (dataUserProp.emails) {
              datosIgualUser = out2
                .flatMap((innerArray) => innerArray) // Aplanar el array multidimensional
                .filter((val2) => dataUserProp.emails.includes(val2.email)); // Filtrar por emails coincidentes
            }

            if (datosIgual.length > 0) {
              setExitAlert(true);
              setRowsAlert(true);
              setRowsAlertData(datosIgual);
            } else if (datosIgualUser.length > 0) {
              setExitAlert(true);
              setMensaje(
                "El en el documento hay correos electrónico de las personas que ya están registrados en el sistema."
              );
              setOpenAlert(true);
              setErrorAlert("error");
            } else {
              setRowsImport({ companies: out, users: out2 });
              setDisabledMasive(false);
            }
          }
        },
      });
    }
  };

  const changeProfile = (data) => {
    setValues({
      ...values,
      profile: data !== null ? data.id : "",
      profileData: data !== null ? data : "",
      errorProfile: false,
    });

    setOpenAlert(false);
  };

  if (returnLogin) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        // onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        PaperProps={{
          sx: {
            borderRadius: 6,
          },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                {error ? <Alert severity="error">{mensageSystem}</Alert> : ""}
                <div className={classes.titleCardFilter}>Nueva empresa</div>
                <div className={classes.lineBottom}></div>
                <br></br>
              </Grid>
              <Grid item xs={12} md={12}>
                <AppBar position="static" color="default">
                  <Tabs
                    value={valueTabs}
                    onChange={handleChangeTabs}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="action tabs example"
                  >
                    <Tab
                      label="Carga manual"
                      {...a11yProps(0)}
                      style={{ color: "#000" }}
                    />
                    <Tab
                      label="Cargar CSV"
                      {...a11yProps(1)}
                      style={{ color: "#000" }}
                    />
                  </Tabs>
                </AppBar>
                <TabPanel value={valueTabs} index={0} dir={theme.direction}>
                  <Grid
                    container
                    spacing={1}
                    style={{
                      backgroundColor: "rgb(248, 248, 248, 0.71)",
                      borderRadius: 5,
                      padding: 10,
                    }}
                  >
                    <Grid item xs={12} sm={6}>
                      <b>Datos de la empresa:</b>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        autoComplete="off"
                        name="code"
                        value={values.code}
                        onChange={handleChange("code")}
                        label="Código"
                        fullWidth
                        required
                        margin="dense"
                        variant="outlined"
                        disabled
                        style={{ background: "#FFFFFF" }}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            name="fecha_constitucion"
                            label="Fecha de constitución"
                            value={dateConstitucion}
                            onChange={(newValue) =>
                              handleDateConstitucionChange(newValue)
                            }
                            format="DD/MM/YYYY"
                            sx={{
                              "& .MuiButtonBase-root": {
                                color: "#333",
                              },
                              width: "100%",
                              background: "#FFFFFF",
                            }}
                            slotProps={{
                              textField: {
                                // helperText: "DD/MM/YYYY",
                                error: values.errorFecha_constitucion,
                                required: true,
                                size: "small",
                              },
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl style={{ width: "100%" }}>
                        <TextField
                          name="document_id"
                          value={values.document_id}
                          error={values.errorDocument}
                          onChange={handleChange("document_id")}
                          fullWidth
                          // required
                          label="Nro. Ruc"
                          margin="dense"
                          variant="outlined"
                          autoComplete="off"
                          inputProps={{
                            style: {
                              textAlign: "center",
                            },
                            maxLength: 13,
                          }}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                          style={{ background: "#FFFFFF" }}
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        autoComplete="off"
                        name="razon_social"
                        value={values.razon_social}
                        onChange={handleChange("razon_social")}
                        label="Razón social"
                        error={values.errorRazon_social}
                        fullWidth
                        required
                        margin="dense"
                        variant="outlined"
                        style={{ background: "#FFFFFF" }}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        autoComplete="off"
                        name="nombre_comercial"
                        value={values.nombre_comercial}
                        onChange={handleChange("nombre_comercial")}
                        label="Nombre Comercial - Marca"
                        error={values.errorNombre_comercial}
                        fullWidth
                        required
                        margin="dense"
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <Store
                              fontSize="small"
                              className={classes.iconInput}
                              position="end"
                            ></Store>
                          ),
                        }}
                        style={{ background: "#FFFFFF" }}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        autoComplete="off"
                        name="email"
                        value={values.email}
                        onChange={handleChange("email")}
                        label="Correo electrónico de la empresa"
                        error={values.errorEmail}
                        fullWidth
                        required
                        margin="dense"
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <MailOutline
                              fontSize="small"
                              className={classes.iconInput}
                              position="end"
                            ></MailOutline>
                          ),
                        }}
                        style={{ background: "#FFFFFF" }}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LoadStates
                        value={values.statesData}
                        refresh={changeStates}
                        idCountries={45}
                        error={values.errorStates}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        autoComplete="off"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange("phone")}
                        label="Número de Whatsapp"
                        fullWidth
                        required
                        InputProps={{
                          style: {
                            textAlign: "center",
                          },
                          maxLength: 13,
                          endAdornment: (
                            <WhatsApp
                              fontSize="small"
                              className={classes.iconInput}
                              position="end"
                            ></WhatsApp>
                          ),
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                        margin="dense"
                        variant="outlined"
                        error={values.errorPhone}
                        style={{ background: "#FFFFFF" }}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LoadCities
                        value={values.citiesData}
                        refresh={changeCities}
                        disabled={disabledCities}
                        idStates={values.states}
                        error={values.errorCities}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        autoComplete="off"
                        name="parroquia"
                        value={values.parroquia}
                        onChange={handleChange("parroquia")}
                        label="Parroquia"
                        fullWidth
                        required
                        margin="dense"
                        variant="outlined"
                        error={values.errorParroquia}
                        style={{ background: "#FFFFFF" }}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        autoComplete="off"
                        name="direccion"
                        value={values.direccion}
                        onChange={handleChange("direccion")}
                        label="Dirección de funcionamiento"
                        fullWidth
                        required
                        margin="dense"
                        variant="outlined"
                        error={values.errorDireccion}
                        style={{ background: "#FFFFFF" }}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        autoComplete="off"
                        name="referencia"
                        value={values.referencia}
                        onChange={handleChange("referencia")}
                        label="Referencia"
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        style={{ background: "#FFFFFF" }}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LoadSectors
                        value={values.sectorsData}
                        refresh={changeSectors}
                        error={values.errorSectors}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <b>Persona de contacto: </b>
                      <span style={{ fontSize: 12, fontWeight: "400" }}>
                        (Se enviará a este usuario un correo de bienvenida y el
                        acceso al sistema.)
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl style={{ width: "100%" }}>
                        <TextField
                          name="name"
                          value={values.nameContact}
                          error={values.errorNameContact}
                          onChange={handleChange("nameContact")}
                          fullWidth
                          required
                          label="Nombre"
                          margin="dense"
                          autoComplete="off"
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <PersonOutline
                                fontSize="small"
                                className={classes.iconInput}
                                position="end"
                              ></PersonOutline>
                            ),
                          }}
                          style={{ background: "#FFFFFF" }}
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl style={{ width: "100%" }}>
                        <TextField
                          name="lastNameContact"
                          value={values.lastNameContact}
                          error={values.errorLastNameContact}
                          onChange={handleChange("lastNameContact")}
                          fullWidth
                          required
                          label="Apellidos"
                          margin="dense"
                          autoComplete="off"
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <PersonOutline
                                fontSize="small"
                                className={classes.iconInput}
                                position="end"
                              ></PersonOutline>
                            ),
                          }}
                          style={{ background: "#FFFFFF" }}
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl style={{ width: "100%" }}>
                        <TextField
                          name="document_idContact"
                          value={values.document_idContact}
                          error={values.errorDocument_idContact}
                          onChange={handleChange("document_idContact")}
                          fullWidth
                          required
                          label="Documento"
                          margin="dense"
                          variant="outlined"
                          autoComplete="off"
                          inputProps={{
                            style: {
                              textAlign: "center",
                            },
                            maxLength: 13,
                          }}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                          helperText="Cédula, RUC o pasaporte."
                          style={{ background: "#FFFFFF" }}
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        autoComplete="off"
                        name="emailContact"
                        value={values.emailContact}
                        onChange={handleChange("emailContact")}
                        label="Correo electrónico"
                        error={values.errorEmailContact}
                        InputProps={{
                          endAdornment: (
                            <MailOutline
                              fontSize="small"
                              className={classes.iconInput}
                              position="end"
                            ></MailOutline>
                          ),
                        }}
                        fullWidth
                        margin="dense"
                        size="small"
                        required
                        variant="outlined"
                        style={{ background: "#FFFFFF" }}
                        //helperText="Se enviará un correo de bienvenida y el acceso al sistema."
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LoadGender
                        value={values.genderData}
                        refresh={changeGender}
                        error={values.errorGender}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LoadProfile
                        value={values.profileData}
                        refresh={changeProfile}
                        error={values.errorProfile}
                        hide={false}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={valueTabs} index={1} dir={theme.direction}>
                  <Grid
                    container
                    spacing={1}
                    style={{
                      backgroundColor: "#f8f8f8",
                      borderRadius: 5,
                      padding: 10,
                    }}
                  >
                    <Grid item xs={12} md={12}>
                      <Alert severity="warning">
                        <AlertTitle>IMPORTANTE</AlertTitle>- Todas las columnas
                        es deseable que se registre la información.
                        <br></br>- La columna{" "}
                        <strong>
                          Número de Ruc, Teléfono celular de la Empresa e
                          Identificación de las personas
                        </strong>{" "}
                        es importante mantener este formato ej: en formato de
                        "TEXTO" para agregar al inicio 0 si lo requiere.{" "}
                        <br></br>- Las columnas{" "}
                        <strong>de la persona_1 y persona_2</strong> es
                        importante suministrar la información completa tanto en
                        nombres, correo electrónico y género. <br></br>- La
                        columna <strong>Género</strong> es importante mantener
                        este formato ej: <strong>Mujer o Hombre</strong>{" "}
                        <br></br>- Al finalizar con el archivo excel, deberá ir
                        a <strong>"ARCHIVO y GUARDAR COMO"</strong>, seleccionar
                        el formato del archivo tipo&nbsp;
                        <strong>
                          "(CSV CODIFICADO EN UTF-8 (DELIMITADO POR COMAS))"{" "}
                        </strong>
                        y <strong>"GUARDAR"</strong>.<br></br>
                      </Alert>
                    </Grid>
                    <Grid item xs={12} md={12} style={{ marginTop: "3%" }}>
                      {rowsAlert ? arrayDNY(rowsAlertData) : null}
                    </Grid>
                    <br></br>
                    <br></br>
                    <Grid item xs={12} md={12}>
                      <center>
                        {exitAlert ? (
                          <Alert variant="filled" severity="error">
                            <b>
                              Cierre nuevamente la ventana para evitar errores.
                            </b>
                          </Alert>
                        ) : null}
                      </center>
                    </Grid>
                    <br></br>
                    <Fragment>
                      <Grid item xs={12} md={12}>
                        <center>
                          <input
                            accept=".csv"
                            style={{ display: "none" }}
                            onChange={changeHandler}
                            id="contained-button-file"
                            multiple
                            type="file"
                            name="filecsv"
                          />
                          <a
                            href={"/documents/csv_empresas.csv"}
                            download="csv_empresas.csv"
                          >
                            <ButtonLeftSteps2 startIcon={<SaveAlt />}>
                              Descargar formato
                            </ButtonLeftSteps2>
                          </a>
                          <label htmlFor="contained-button-file">
                            <ButtonContactGreen component="span">
                              Subir documento
                            </ButtonContactGreen>
                          </label>
                          <br></br>
                          <br></br>
                        </center>
                      </Grid>
                    </Fragment>
                  </Grid>
                </TabPanel>
              </Grid>
              <Grid item xs={12} md={12}>
                {loaders ? (
                  <center>
                    <img
                      src={imgLoader}
                      alt="loader"
                      style={{
                        width: 60,
                        objectFit: "contain",
                        marginTop: 25,
                      }}
                    />
                  </center>
                ) : (
                  <Fragment>
                    {loadersSave ? (
                      <center>
                        <img
                          src={imgLoader}
                          alt="loader"
                          style={{
                            width: 60,
                            objectFit: "contain",
                            marginTop: 5,
                          }}
                        />
                        <br></br>
                        <b style={{ fontSize: 10 }}>Espere un momento...</b>
                      </center>
                    ) : (
                      <>
                        {errorListCompanies.length > 0 && (
                          <>
                            <Alert severity="error">
                              Estas <b>EMPRESAS</b> no se registraron, revisar y
                              subirlas a parte.
                            </Alert>
                            <TableContainer component={Paper}>
                              <Table aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>
                                      Nombre comercial empresa{" "}
                                    </TableCell>
                                    <TableCell>RUC</TableCell>
                                    <TableCell>Email empresa</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {errorListCompanies.map((row) => (
                                    <TableRow
                                      key={row.nombreComercial}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        style={{ fontSize: 12 }}
                                      >
                                        {row.nombreComercial}
                                      </TableCell>
                                      <TableCell style={{ fontSize: 12 }}>
                                        {row.nRuc}
                                      </TableCell>
                                      <TableCell style={{ fontSize: 12 }}>
                                        {row.email}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            <br></br>
                          </>
                        )}
                        {errorListUsers.length > 0 && (
                          <>
                            <Alert severity="error">
                              Estos <b>USUARIOS</b> no se registraron, revisar y
                              subirlos a parte.
                            </Alert>
                            <TableContainer component={Paper}>
                              <Table aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Nombre y apellidos</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Error</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {errorListUsers.map((row) => (
                                    <TableRow
                                      key={row.nombreComercial}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        style={{ fontSize: 12 }}
                                      >
                                        {row.value.response.data.user.allNames}
                                      </TableCell>
                                      <TableCell style={{ fontSize: 12 }}>
                                        {row.value.response.data.user.email}
                                      </TableCell>
                                      <TableCell style={{ fontSize: 12 }}>
                                        <b style={{ color: "red" }}>
                                          {row.value.response.data.message}
                                        </b>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            <br></br>
                          </>
                        )}

                        <div style={{ float: "right" }}>
                          <ButtonExit
                            className={classes.buttonLeft}
                            onClick={handleCloseDialog(true)}
                          >
                            Cerrar
                          </ButtonExit>
                          <ButtonStyle0
                            onClick={submitForm}
                            disabled={disabledMasive}
                            endIcon={<Save />}
                          >
                            Guardar
                          </ButtonStyle0>
                        </div>
                      </>
                    )}
                  </Fragment>
                )}
              </Grid>
            </Grid>
            <Snackbar
              open={openAlert}
              autoHideDuration={20000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={errorAlert === "error" ? "error" : "success"}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}
