import React, { Fragment, useState, useContext } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";
import { Redirect } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import axios from "axios";
import {
  Slide,
  Snackbar,
  Grid,
  DialogActions,
  DialogContent,
  Alert,
  Dialog,
} from "@mui/material";

import LoadCompanies from "components/services/LoadCompanies";

import {
  cardBodyStyle,
  ButtonExit,
  ButtonStyle0,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function NewCompanyDocumentAdmin(props) {
  const classes = useStyles();

  const [openAlert, setOpenAlert] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  const [openAlertSuccess, setopenAlertSuccess] = useState(false);
  const [mensaje_success, setmensaje_success] = useState("");
  const [returnLogin, setReturnLogin] = React.useState(false);
  const [error, setError] = useState(false);
  const [mensageSystem, setMensageSystem] = useState("");

  const { urlServices, UserAuth } = useContext(UrlServicesContext);

  let keyAuthorization = UserAuth.Session;

  const [values, setValues] = useState({
    company: "",
    companyData: "",
    errorCompany: false,
  });

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  const submitForm = (e) => {
    e.preventDefault();
    setErrorAlert("");
    setError(false);
    setopenAlertSuccess(false);

    if (values.company === "") {
      setValues({ ...values, errorCompany: true });
      setMensaje("Seleccione una Empresa.");
      setOpenAlert(true);
      setErrorAlert("error");
    } else if (values.statusTracking === "") {
      setValues({ ...values, errorStatusTracking: true });
      setMensaje("Seleccione un estatus.");
      setOpenAlert(true);
      setErrorAlert("error");
    } else {
      const dataValue = {
        companyId: values.company,
        createdUserId: UserAuth.id,
      };

      axios
        .post(`${urlServices}documents-companies`, dataValue, {
          headers: {
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then((response) => {
          setopenAlertSuccess(true);
          setmensaje_success(response.data.message);
          props.callBackRefresh();

          setValues({
            ...values,
            company: "",
            companyData: "",
          });
        })
        .catch((e) => {
          if (e.response.status === 401) {
            setMensageSystem(
              "La sesión ha expirado, vuelva a iniciar sesión ..."
            );
            setError(true);

            setTimeout(() => {
              localStorage.clear();
              setReturnLogin(true);
            }, 4000);
          } else if (e.response.status === 500) {
            setMensageSystem("Error en la consulta con sel servidor.");
            setError(true);
          }
        });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const changeCompany = (data) => {
    setValues({
      ...values,
      company: data !== null ? data.id : "",
      companyData: data !== null ? data : "",
      errorCompany: false,
    });

    setOpenAlert(false);
  };

  if (returnLogin) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        maxWidth={"xs"}
        scroll="body"
        PaperProps={{
          sx: {
            borderRadius: 6,
          },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                {error ? <Alert severity="error">{mensageSystem}</Alert> : ""}
                <div className={classes.titleCardFilter}>Agregar</div>
                <div className={classes.lineBottom}></div>
                <br></br>
              </Grid>
              <Grid item xs={12} md={12}>
                <Alert severity="info" sx={{ fontSize: 12 }}>
                  Agregar empresa para administrar sus documentos.
                </Alert>
              </Grid>
              <Grid item xs={12} md={12}>
                <b>Empresa</b>
              </Grid>
              <Grid item xs={12} md={12}>
                <LoadCompanies
                  value={values.companyData}
                  refresh={changeCompany}
                  error={values.errorCompany}
                  rows={props.rows}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <br></br>
                <br></br>
                <center>
                  <ButtonStyle0 type="submit" onClick={submitForm}>
                    Agregar
                  </ButtonStyle0>
                </center>
              </Grid>
              <Grid item xs={12} sm={12}>
                <center>
                  {openAlertSuccess ? (
                    <Alert severity="success">{mensaje_success}</Alert>
                  ) : (
                    ""
                  )}
                </center>
              </Grid>
            </Grid>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={errorAlert === "error" ? "error" : "success"}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <ButtonExit onClick={handleCloseDialog(true)}>Cerrar</ButtonExit>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
