import React, { Fragment, useState, useContext, useEffect } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";
import { Redirect } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import axios from "axios";
import { Save, MailOutline, WhatsApp, Store } from "@mui/icons-material";
import FileBase64 from "react-file-base64";
import Resizer from "react-image-file-resizer";
import {
  Grid,
  TextField,
  Box,
  Tab,
  FormControl,
  Snackbar,
  Typography,
  Slide,
  Alert,
} from "@mui/material";
import PropTypes from "prop-types";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "dayjs/locale/es";

import LoadStates from "components/services/LoadStates";
import LoadCities from "components/services/LoadCities";
import imgLoader from "assets/img/loading.gif";
import LoadSectors from "components/services/LoadSectors";
import Notification from "views/dialog/Notification";

import img from "assets/img/iconLogo.jpeg";

import {
  cardBodyStyle,
  ButtonStyle0,
  TabsTheme,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      512,
      512,
      "JPEG",
      15,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

dayjs.locale("es"); // use Spanish locale globally

export default function EditCompanyAdmin(props) {
  const classes = useStyles();

  const [openAlert, setOpenAlert] = useState(false);
  const [loader, setLoader] = useState(true);
  const [mensaje, setMensaje] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  const [loaders, setLoaders] = useState(false);
  const [disabledMasive, setDisabledMasive] = useState(true);
  const [disabledCities, setDisabledCities] = useState(false);
  const [error, setError] = useState("");
  const [mensageSystem, setMensageSystem] = useState("");
  const [returnLogin, setReturnLogin] = useState(false);
  const [valueTabs, setValueTabs] = useState(0);
  const [openNotification, setOpenNotification] = useState(false);
  const [dateConstitucion, setDateConstitucion] = useState();

  const { urlServices, UserAuth } = useContext(UrlServicesContext);

  let keyAuthorization = UserAuth.Session;

  const [values, setValues] = useState({
    code: "",
    fecha_constitucion: "",
    document_id: "",
    razon_social: "",
    nombre_comercial: "",
    email: "",
    phone: "",
    states: "",
    statesData: "",
    cities: "",
    citiesData: "",
    parroquia: "",
    direccion: "",
    referencia: "",
    logo: "",
    logoBase64: "",
    sectors: "",
    sectorsData: "",
    errorFecha_constitucion: false,
    errorNombre_comercial: false,
    errorDocument: false,
    errorRazon_social: false,
    errorEmail: false,
    errorPhone: false,
    errorStates: false,
    errorCities: false,
    errorDireccion: false,
    errorParroquia: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Scroll to top smoothly
        window.scrollTo({ top: 0, behavior: "smooth" });

        const response = await axios.get(
          `${urlServices}company/${UserAuth.companyId}`,
          {
            headers: {
              Authorization: "Bearer " + keyAuthorization,
            },
          }
        );

        setValues({
          ...values,
          code: response.data.company.code,
          fecha_constitucion: response.data.company.fechaConstitucion,
          document_id: response.data.company.nRuc,
          razon_social: response.data.company.razonSocial,
          nombre_comercial: response.data.company.nombreComercial,
          email: response.data.company.email,
          phone: response.data.company.phone,
          states: response.data.company.provinceId,
          statesData:
            response.data.company.provinceId !== null
              ? {
                  id: response.data.company.provinceId,
                  name: response.data.company.province.name,
                }
              : null,
          cities: response.data.company.cityId,
          citiesData:
            response.data.company.cityId !== null
              ? {
                  id: response.data.company.cityId,
                  name: response.data.company.cities.name,
                }
              : null,
          parroquia: response.data.company.parroquia,
          direccion: response.data.company.direccion,
          referencia: response.data.company.referencia,
          logo: response.data.company.logo,
          sectors: response.data.company.sectorId,
          sectorsData:
            response.data.company.sectorId !== null
              ? {
                  id: response.data.company.sectorId,
                  name: response.data.company.sector.name,
                }
              : null,
        });

        setDateConstitucion(dayjs(response.data.company.fechaConstitucion));

        setLoader(false);
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            setMensageSystem(
              "La sesión ha expirado, vuelva a iniciar sesión ..."
            );
            setError(true);

            setTimeout(() => {
              localStorage.clear();
              setReturnLogin(true);
            }, 4000);
          } else if (error.response.status === 500) {
            setMensageSystem("Error en la consulta con el servidor.");
            setError(true);
          }
        } else {
          console.error("Error fetching company data:", error);
        }
      }
    };

    fetchData();
  }, []);

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      errorFecha_constitucion: false,
      errorNombre_comercial: false,
      errorRazon_social: false,
      errorDocument: false,
      errorEmail: false,
      errorPhone: false,
      errorDireccion: false,
      errorParroquia: false,
    });

    setDisabledMasive(false);
    setOpenAlert(false);
  };

  const submitForm = () => {
    setOpenAlert(false);
    setError(false);

    if (values.fecha_constitucion === "") {
      setValues({ ...values, errorFecha_constitucion: true });
      setMensaje('El campo "Fecha de Constitución" es obligatorio.');
      setOpenAlert(true);
      setErrorAlert("error");
    } /*if (values.document_id.length === 0) {
      setValues({ ...values, errorDocument: true });
      setMensaje('El campo "Nro. Ruc" es obligatorio.');
      setOpenAlert(true);
      setErrorAlert("error");
    } else*/ else if (values.razon_social.length === 0) {
      setValues({ ...values, errorRazon_social: true });
      setMensaje('El campo "Razón social" es obligatorio.');
      setOpenAlert(true);
      setErrorAlert("error");
    } else if (values.nombre_comercial.length === 0) {
      setValues({ ...values, errorNombre_comercial: true });
      setMensaje('El campo "Nombre Comercial" es obligatorio.');
      setOpenAlert(true);
      setErrorAlert("error");
    } else if (values.email.length === 0) {
      setValues({ ...values, errorEmail: true });
      setMensaje('El campo "Correo Electrónico" es obligatorio.');
      setOpenAlert(true);
      setErrorAlert("error");
    } else if (values.states === "") {
      setValues({ ...values, errorStates: true });
      setMensaje('El campo "Provincias" es obligatorio.');
      setOpenAlert(true);
      setErrorAlert("error");
    } else if (values.cities === "") {
      setValues({ ...values, errorCities: true });
      setMensaje('El campo "Ciudad" es obligatorio.');
      setOpenAlert(true);
      setErrorAlert("error");
    } else if (values.direccion.length === 0) {
      setValues({ ...values, errorDireccion: true });
      setMensaje('El campo "Dirección" es obligatorio.');
      setOpenAlert(true);
      setErrorAlert("error");
    } else if (values.phone.length === 0) {
      setValues({ ...values, errorPhone: true });
      setMensaje('El campo "Número de whatsapp" es obligatorio.');
      setOpenAlert(true);
      setErrorAlert("error");
    } else if (values.parroquia.length === 0) {
      setValues({ ...values, errorParroquia: true });
      setMensaje('El campo "Parroquia" es obligatorio.');
      setOpenAlert(true);
      setErrorAlert("error");
    } else if (values.sectors === "") {
      setValues({ ...values, errorSectors: true });
      setMensaje('El campo "Sector" es obligatorio.');
      setOpenAlert(true);
      setErrorAlert("error");
    } else {
      setDisabledMasive(false);
      setOpenAlert(false);
      setLoaders(true);

      const dataValue = {
        razonSocial: values.razon_social,
        nombreComercial: values.nombre_comercial,
        nRuc: values.document_id,
        fechaConstitucion: values.fecha_constitucion,
        provinceId: values.states,
        cityId: values.cities,
        parroquia: values.parroquia,
        direccion: values.direccion,
        referencia: values.referencia,
        email: values.email,
        phone: values.phone,
        code: values.code,
        sectorId: values.sectors,
      };

      if (values.logoBase64.length > 0) {
        dataValue.logo = values.logoBase64;
      }

      axios
        .put(`${urlServices}company/${UserAuth.companyId}`, dataValue, {
          headers: {
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then((response) => {
          setDisabledMasive(true);
          setOpenAlert(true);
          setMensaje(response.data.message);
          setErrorAlert("success");
          setLoaders(false);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            setMensageSystem(
              "La sesión ha expirado, vuelva a iniciar sesión ..."
            );
            setError(true);

            setTimeout(() => {
              localStorage.clear();
              setReturnLogin(true);
            }, 4000);
          } else if (e.response.status === 500) {
            setMensageSystem("Error en la consulta con sel servidor.");
            setError(true);
          }
        });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleDateConstitucionChange = (data) => {
    const formattedDate = dayjs(data).format("YYYY-MM-DD");

    setValues({
      ...values,
      fecha_constitucion: formattedDate,
      errorFecha_constitucion: false,
    });
    setOpenAlert(false);
    setDateConstitucion(data);
    setDisabledMasive(false);
  };

  const changeStates = (data) => {
    setValues({
      ...values,
      states: data !== null ? data.id : "",
      statesData: data !== null ? data : "",
      errorStates: false,
      cities: "",
      citiesData: "",
    });

    setDisabledCities(data !== null ? false : true);
    setOpenAlert(false);
    setDisabledMasive(false);
  };

  const changeCities = (data) => {
    setValues({
      ...values,
      cities: data !== null ? data.id : "",
      citiesData: data !== null ? data : "",
      errorCities: false,
    });

    setOpenAlert(false);
    setDisabledMasive(false);
  };

  const changeSectors = (data) => {
    setValues({
      ...values,
      sectors: data !== null ? data.id : "",
      sectorsData: data !== null ? data : "",
      errorSectors: false,
    });

    setOpenAlert(false);
    setDisabledMasive(false);
  };

  const uploadSingleFile = async (e) => {
    let image = "",
      outfile = "";

    // Single file
    if (Number(e.file.size) > 30000) {
      // SI ES MAYOR A 500KB LE BAJAMOS EL PESO
      image = await resizeFile(e.file);
      // const newFile = dataURIToBlob(image);
      outfile = image;
    } else {
      outfile = e.base64;
    }
    setValues({ ...values, logoBase64: outfile });
    setDisabledMasive(false);
    // Multiples files
    // if (e.length > 0 && e.length <= 1) {
    //   if (Number(e[0].file.size) > 500000) {
    //     // SI ES MAYOR A 500KB LE BAJAMOS EL PESO
    //     image = await resizeFile(e[0].file)
    //     // const newFile = dataURIToBlob(image);
    //     outfile = image
    //   } else {
    //     outfile = e[0].base64
    //   }
    //   setValues({ ...values, pictureBase64: outfile })
    // }
  };

  const handleChangeTabs = (event, newValue) => {
    setValueTabs(newValue);
  };

  const handleCloseNotification = () => {
    setOpenNotification(false);
  };

  if (returnLogin) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <Grid container spacing={1} style={{ marginBottom: 25 }}>
        <Grid item xs={12} sm={12}>
          {error ? <Alert severity="error">{mensageSystem}</Alert> : ""}
          <div className={classes.cardTitleBlack}>
            <Store className={classes.iconFilter} /> Actualización de datos
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={1} className={classes.containerProfile}>
        {loader ? (
          <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
            <img
              src={imgLoader}
              alt="loader"
              style={{
                width: 60,
                objectFit: "contain",
                marginTop: 25,
              }}
            />
          </Grid>
        ) : (
          <Fragment>
            <Grid item xs={12} sm={12}>
              <TabsTheme
                value={valueTabs}
                onChange={handleChangeTabs}
                aria-label="basic tabs example"
              >
                <Tab label="Datos Empresa" {...a11yProps(0)} />
                <Tab label="Logo" {...a11yProps(1)} />
              </TabsTheme>

              <CustomTabPanel value={valueTabs} index={0}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <b>Datos de la Empresa</b>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="off"
                      name="code"
                      value={values.code}
                      onChange={handleChange("code")}
                      label="Código"
                      fullWidth
                      required
                      margin="dense"
                      variant="outlined"
                      disabled
                      style={{ background: "#FFFFFF" }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          name="fecha_constitucion"
                          label="Fecha de constitución"
                          value={dateConstitucion}
                          onChange={(newValue) =>
                            handleDateConstitucionChange(newValue)
                          }
                          format="DD/MM/YYYY"
                          sx={{
                            "& .MuiButtonBase-root": {
                              color: "#333",
                            },
                            width: "100%",
                          }}
                          slotProps={{
                            textField: {
                              // helperText: "DD/MM/YYYY",
                              error: values.errorFecha_constitucion,
                              required: true,
                              size: "small",
                            },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        name="document_id"
                        value={values.document_id}
                        error={values.errorDocument}
                        onChange={handleChange("document_id")}
                        fullWidth
                        required
                        label="Nro. Ruc"
                        margin="dense"
                        variant="outlined"
                        autoComplete="off"
                        inputProps={{
                          style: {
                            textAlign: "left",
                          },
                          maxLength: 13,
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                        style={{ background: "#FFFFFF" }}
                        size="small"
                        //disabled
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      autoComplete="off"
                      name="razon_social"
                      value={values.razon_social}
                      onChange={handleChange("razon_social")}
                      label="Razón social"
                      error={values.errorRazon_social}
                      fullWidth
                      required
                      margin="dense"
                      variant="outlined"
                      style={{ background: "#FFFFFF" }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      autoComplete="off"
                      name="nombre_comercial"
                      value={values.nombre_comercial}
                      onChange={handleChange("nombre_comercial")}
                      label="Nombre Comercial - Marca"
                      error={values.errorNombre_comercial}
                      fullWidth
                      required
                      margin="dense"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <Store
                            fontSize="small"
                            className={classes.iconInput}
                            position="end"
                          ></Store>
                        ),
                      }}
                      style={{ background: "#FFFFFF" }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="off"
                      name="email"
                      value={values.email}
                      onChange={handleChange("email")}
                      label="Correo electrónico de la empresa"
                      error={values.errorEmail}
                      fullWidth
                      required
                      margin="dense"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <MailOutline
                            fontSize="small"
                            className={classes.iconInput}
                            position="end"
                          ></MailOutline>
                        ),
                      }}
                      style={{ background: "#FFFFFF" }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LoadStates
                      value={values.statesData}
                      refresh={changeStates}
                      idCountries={45}
                      error={values.errorStates}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="off"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange("phone")}
                      label="Número de Whatsapp"
                      fullWidth
                      required
                      InputProps={{
                        endAdornment: (
                          <WhatsApp
                            fontSize="small"
                            className={classes.iconInput}
                            position="end"
                          ></WhatsApp>
                        ),
                      }}
                      inputProps={{
                        maxLength: 12,
                      }}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                      margin="dense"
                      variant="outlined"
                      error={values.errorPhone}
                      style={{ background: "#FFFFFF" }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LoadCities
                      value={values.citiesData}
                      refresh={changeCities}
                      disabled={disabledCities}
                      idStates={values.states}
                      error={values.errorCities}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="off"
                      name="parroquia"
                      value={values.parroquia}
                      onChange={handleChange("parroquia")}
                      label="Parroquia"
                      fullWidth
                      required
                      margin="dense"
                      variant="outlined"
                      error={values.errorParroquia}
                      style={{ background: "#FFFFFF" }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="off"
                      name="direccion"
                      value={values.direccion}
                      onChange={handleChange("direccion")}
                      label="Dirección de funcionamiento"
                      fullWidth
                      required
                      margin="dense"
                      variant="outlined"
                      error={values.errorDireccion}
                      style={{ background: "#FFFFFF" }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="off"
                      name="referencia"
                      value={values.referencia}
                      onChange={handleChange("referencia")}
                      label="Referencia"
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      style={{ background: "#FFFFFF" }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LoadSectors
                      value={values.sectorsData}
                      refresh={changeSectors}
                      error={values.errorSectors}
                    />
                  </Grid>
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={valueTabs} index={1}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <b>Logo de la Empresa</b>
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                    <div
                      style={{
                        padding: "30px 30px 20px 30px",
                        border: "1px dashed rgba(0, 0, 0, 0.38)",
                        borderRadius: 10,
                        color: "rgba(0, 0, 0, 0.38)",
                        fontSize: 12,
                        background: "rgb(248, 248, 248, 0.71)",
                      }}
                    >
                      <img
                        alt="avatar"
                        src={values.logoBase64 || values.logo || img}
                        className={classes.imgLogoCompany}
                      />
                      <Box sx={{ margin: "15px 0" }}>
                        <label className={classes.buttonSubmitUpload}>
                          <FileBase64
                            type="file"
                            multiple={false}
                            onDone={uploadSingleFile}
                          />
                          <i className="fa fa-cloud-upload"></i>&nbsp;&nbsp;
                          {values.logo || values.logoBase64
                            ? "Cambiar logo"
                            : "Seleccionar logo"}
                        </label>
                      </Box>
                      Tamaño límite 512px x 512px
                    </div>
                  </Grid>
                </Grid>
              </CustomTabPanel>
            </Grid>
            <Grid item xs={12} md={12}>
              {loaders ? (
                <center>
                  <img
                    src={imgLoader}
                    alt="loader"
                    style={{
                      width: 60,
                      objectFit: "contain",
                      marginTop: 25,
                    }}
                  />
                </center>
              ) : (
                <div style={{ float: "right" }}>
                  <ButtonStyle0
                    onClick={() => setOpenNotification(true)}
                    disabled={disabledMasive}
                    endIcon={<Save />}
                  >
                    Actualizar datos
                  </ButtonStyle0>
                </div>
              )}
            </Grid>
          </Fragment>
        )}
      </Grid>
      <Snackbar
        open={openAlert}
        autoHideDuration={20000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
      >
        <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
          <Alert
            onClose={handleClose}
            severity={errorAlert === "error" ? "error" : "success"}
            elevation={6}
            variant="filled"
          >
            {mensaje}
          </Alert>
        </Slide>
      </Snackbar>
      {openNotification ? (
        <Notification
          open={openNotification}
          exit={handleCloseNotification}
          success={submitForm}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
}
