import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import FormatListBulleted from "@mui/icons-material/FormatListBulleted";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js";
import { Hidden, IconButton, Toolbar, Grid } from "@mui/material";

import logoMobil from "assets/img/logoPalanka.png";

import {
  headerStyle,
  AppBarStyle,
} from "assets/jss/material-dashboard-react/components/headerStyle.js";

const useStyles = makeStyles(headerStyle);

export default function Header(props) {
  const classes = useStyles();
  function makeBrand() {
    var name;
    props.routes.map((prop) => {
      if (prop.submenu.length === 0) {
        if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
          name = props.rtlActive ? prop.rtlName : prop.name;
        }
      } else {
        prop.submenu.map((prop2) => {
          if (window.location.href.indexOf(prop.layout + prop2.path) !== -1) {
            name = prop2.name;
          }
        });
      }
      return null;
    });
    return name;
  }
  //const { color } = props;

  const [sentHome, setsubmitHome] = useState(false);
  const submitHome = () => {
    setsubmitHome(true);
  };

  if (sentHome) {
    return <Redirect from="/" to="/" />;
  }

  return (
    <Toolbar className={classes.toolbar}>
      <div className={classes.flex}>
        {/* Here we create navbar brand, based on route name */}
        <div className={classes.title}>{makeBrand()}</div>
      </div>
      <Hidden smDown implementation="css">
        <AdminNavbarLinks />
      </Hidden>
      <Hidden mdUp implementation="css">
        <AppBarStyle>
          <Toolbar>
            <Grid container>
              <Grid item xs={12}>
                {/*<span className={classes.nameLogoMobil}>AUTOCLICKEAA</span>*/}
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={submitHome}
                >
                  <img
                    src={logoMobil}
                    alt="validate"
                    style={{
                      width: 90,
                      objectFit: "contain",
                    }}
                  />
                </IconButton>
                <span style={{ float: "right" }}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={props.handleDrawerToggle}
                  >
                    <FormatListBulleted className={classes.iconMovilRight} />
                  </IconButton>
                </span>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBarStyle>
      </Hidden>
    </Toolbar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};
