import React, { Fragment, useState, useContext, useEffect } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";
import { Redirect } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import axios from "axios";
import {
  // TextField,
  Slide,
  Snackbar,
  Grid,
  DialogActions,
  DialogContent,
  Alert,
  Dialog,
  Avatar,
  Box,
} from "@mui/material";
import { CheckCircle, DriveFolderUpload } from "@mui/icons-material";

import loaderGif from "assets/img/loading.gif";

import LoadTypeDocuments from "components/services/LoadTypeDocuments";

import {
  cardBodyStyle,
  ButtonExit,
  ButtonStyle0,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function UploadDocumentCompany(props) {
  const classes = useStyles();

  const [openAlert, setOpenAlert] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  const [openAlertSuccess, setopenAlertSuccess] = useState(false);
  const [mensaje_success, setmensaje_success] = useState("");
  const [returnLogin, setReturnLogin] = React.useState(false);
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [mensageSystem, setMensageSystem] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [nameDocument, setNameDocument] = useState("");
  const [loader, setLoader] = useState(false);

  const { urlServices, UserAuth } = useContext(UrlServicesContext);

  let keyAuthorization = UserAuth.Session;

  const [values, setValues] = useState({
    typeDocumentsData: "",
    typeDocuments: "",
    errorTypeDocuments: false,
  });

  useEffect(() => {
    let nameDocumentGenerate =
      "cp" +
      props.id.companyId +
      "_dc" +
      props.id.id +
      "_" +
      (Math.floor(Math.random() * 900000) + 100000).toString();
    setNameDocument(nameDocumentGenerate);
  }, []);

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  const submitForm = (e) => {
    e.preventDefault();
    setErrorAlert("");
    setError(false);
    setopenAlertSuccess(false);

    if (values.typeDocuments === "") {
      setValues({ ...values, errorTypeDocuments: true });
      setMensaje("Seleccione un tipo de documento.");
      setOpenAlert(true);
      setErrorAlert("error");
    } else {
      setLoader(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("documentsCompaniesId", props.id.id);
      formData.append("documentsTypeId", values.typeDocuments);
      formData.append("document", nameDocument);
      formData.append("createdUserId", UserAuth.id);

      axios
        .post(`${urlServices}upload-documents-companies`, formData, {
          headers: {
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then((response) => {
          setDisabled(true);
          setopenAlertSuccess(true);
          setmensaje_success(response.data.message);
          props.callBackRefresh();
          setLoader(false);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            setMensageSystem(
              "La sesión ha expirado, vuelva a iniciar sesión ..."
            );
            setError(true);

            setTimeout(() => {
              localStorage.clear();
              setReturnLogin(true);
            }, 4000);
          } else if (e.response.status === 400) {
            setMensageSystem("Error al cargar el archivo.");
            setError(true);
          } else if (e.response.status === 500) {
            setMensageSystem("Error en la consulta con sel servidor.");
            setError(true);
          }
        });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const changetypeDocuments = (data) => {
    setValues({
      ...values,
      typeDocuments: data !== null ? data.id : "",
      typeDocumentsData: data !== null ? data : "",
      errorTypeDocuments: false,
    });

    setOpenAlert(false);
  };

  const handleFileUpload = (event) => {
    setOpenAlert(false);
    const file = event.target.files[0];

    if (
      file.type === "application/pdf" ||
      file.type === "image/png" ||
      file.type === "image/jpeg"
    ) {
      const newFileName = `${nameDocument}.${file.type.split("/")[1]}`;

      const modifiedFile = new File([file], newFileName, { type: file.type });
      setNameDocument(newFileName);
      setSelectedFile(modifiedFile);
      setDisabled(false);
    } else {
      setDisabled(true);
      setSelectedFile(null);
      setMensaje("Seleccione un documento en PDF, PNG, JPEG o JPG.");
      setOpenAlert(true);
      setErrorAlert("error");
    }
  };

  if (returnLogin) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        maxWidth={"xs"}
        PaperProps={{
          sx: {
            borderRadius: 6,
          },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                {error ? <Alert severity="error">{mensageSystem}</Alert> : ""}
                <div className={classes.titleCardFilter}>Subir documento</div>
                <div className={classes.lineBottom}></div>
                <br></br>
              </Grid>
              <Grid item xs={12} md={12}>
                <b>Tipo de documento:</b>
              </Grid>
              <Grid item xs={12} md={12}>
                <LoadTypeDocuments
                  value={values.typeDocumentsData}
                  refresh={changetypeDocuments}
                  error={values.errorTypeDocuments}
                  rows={props.id.upload_documents_companies}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <b>Seleccionar documento</b>
              </Grid>
              <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                <div
                  style={{
                    padding: "15px 20px 5px 20px",
                    border: "1px dashed rgba(0, 0, 0, 0.38)",
                    borderRadius: 10,
                    color: "rgba(0, 0, 0, 0.38)",
                    fontSize: 12,
                    background: "rgb(248, 248, 248, 0.71)",
                    fontWeight: "bold",
                  }}
                >
                  <center>
                    <Avatar
                      sx={selectedFile ? { bgcolor: "green" } : { bgcolor: "" }}
                    >
                      {selectedFile ? (
                        <CheckCircle
                          sx={{
                            color: "white",
                          }}
                        />
                      ) : (
                        <DriveFolderUpload />
                      )}
                    </Avatar>
                  </center>
                  <Box sx={{ margin: "10px 0" }}>
                    <label className={classes.buttonSubmitUpload}>
                      <input type="file" onChange={handleFileUpload} />
                      <i className="fa fa-cloud-upload"></i>&nbsp;&nbsp;
                      {selectedFile
                        ? "Cambiar documento"
                        : "Seleccionar documento"}
                    </label>
                  </Box>
                  {selectedFile ? (
                    <span style={{ color: "green" }}>Documento cargado</span>
                  ) : (
                    "Solo documentos PDF, PNG, JPEG y JPG "
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <br></br>
                <br></br>
                <center>
                  <ButtonStyle0
                    type="submit"
                    onClick={submitForm}
                    disabled={disabled}
                  >
                    Guardar
                  </ButtonStyle0>
                </center>
              </Grid>
              {loader ? (
                <Grid item xs={12} sm={12}>
                  <center>
                    <img
                      alt="loaderGif"
                      src={loaderGif}
                      style={{ width: 50, margin: 2 }}
                    />
                  </center>
                </Grid>
              ) : (
                <Grid item xs={12} sm={12}>
                  <center>
                    {openAlertSuccess ? (
                      <Alert severity="success">{mensaje_success}</Alert>
                    ) : (
                      ""
                    )}
                  </center>
                </Grid>
              )}
            </Grid>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={errorAlert === "error" ? "error" : "success"}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <ButtonExit onClick={handleCloseDialog(true)}>Cerrar</ButtonExit>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
