import React, { Fragment } from "react";

import { makeStyles } from "@mui/styles";
import { Avatar, Grid } from "@mui/material";

import Header from "../components/Navbars/HeaderHome";

import img from "assets/img/undraw_towing_6yy4.png";

import {
  cardBodyStyle,
  ButtonStyle0,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function Nofoundpage(props) {
  const classes = useStyles();

  const [returnLogin, setReturnLogin] = React.useState(false);

  const handleButton = () => {
    localStorage.clear();
    setReturnLogin(true);
  };

  if (returnLogin) {
    window.location.href = "/login";
  }

  return (
    <Fragment>
      <Header />
      <center>
        <br></br>
        <br></br>
        <Grid
          container
          sx={{
            padding: "30px 20px 20px 20px",
            border: "1px solid rgb(241, 241, 241)",
            borderRadius: 6,
            background: "#fff",
            width: "75%",
            margin: "30px 0 20px 0",
          }}
        >
          <Grid item xs={12} sm={6}>
            <Avatar
              alt="404"
              src={img}
              style={{
                width: "60%",
                height: "auto",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <br></br>
            <br></br>
            <div className={classes.titleCard404}>
              Página no encontrada, esta acción está prohibida, vuelva a iniciar
              sesión.{" "}
            </div>
            <div className={classes.lineBottom}></div>
            <br></br>
            <ButtonStyle0
              onClick={handleButton}
              className={classes.buttonSubmit}
            >
              Ir al inicio
            </ButtonStyle0>
          </Grid>
        </Grid>
      </center>
    </Fragment>
  );
}
