import React, { useState, useContext, useEffect, Fragment } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";
import { Redirect } from "react-router-dom";

import { makeStyles } from "@mui/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { PersonOutline, Search, Cancel } from "@mui/icons-material";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import clsx from "clsx";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Moment from "moment";
import {
  Avatar,
  Grid,
  TextField,
  Box,
  DialogContent,
  DialogActions,
  Dialog,
  Alert,
  Tooltip,
} from "@mui/material";
import axios from "axios";

import DeleteUserAdmin from "views/dialog/DeleteUserAdmin";
import StatusAdmin from "views/dialog/StatusAdmin";

import mujerAvatar from "assets/img/undraw_mujer.png";
import hombreAvatar from "assets/img/undraw_hombre.png";
import loaderGif from "assets/img/loading.gif";

import {
  cardBodyStyle,
  ButtonExit,
  ButtonTableGrid1,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function ListShareHoldersApprove(props) {
  const classes = useStyles();

  const [idRow, setIdRow] = useState("");
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [rowsResponse, setRowsResponse] = useState([]);
  const [openStatus, setOpenStatus] = useState(false);
  const [openApproveValue, setOpenApproveValue] = useState(false);
  const [error, setError] = useState(false);
  const [mensageSystem, setMensageSystem] = useState("");
  const [returnLogin, setReturnLogin] = React.useState(false);

  let resultSearch = "";

  const { urlServices, UserAuth } = useContext(UrlServicesContext);

  let keyAuthorization = UserAuth.Session;

  const [state] = useState({
    columns: [
      {
        headerName: "Accionista",
        field: "allNames",
        flex: 1,
        // headerAlign: "center",
        headerClassName: "super-app-theme--header",
        cellClassName: (params) => {
          return clsx("super-app", {
            celAll: params.value,
          });
        },
        renderCell: (params) => {
          return (
            <>
              <Avatar
                src={params.row.genderId === 1 ? hombreAvatar : mujerAvatar}
                style={{ marginRight: 3, width: 40, height: 40 }}
              />
              {params.value}
            </>
          );
        },
        sortable: false,
        hideable: false,
      },
      {
        headerName: "Nombre Empresa",
        field: "companyRazonSocial",
        flex: 1,
        // headerAlign: "center",
        headerClassName: "super-app-theme--header",
        cellClassName: (params) => {
          return clsx("super-app", {
            celAll: params.value,
          });
        },
        sortable: false,
        hideable: false,
      },
      {
        headerName: "Email",
        field: "email",
        flex: 1,
        // headerAlign: "center",
        headerClassName: "super-app-theme--header",
        cellClassName: (params) => {
          return clsx("super-app", {
            celAll: params.value,
          });
        },
        sortable: false,
        hideable: false,
      },
      {
        headerName: "Solicitud",
        field: "createdAt",
        headerClassName: "super-app-theme--header",
        headerAlign: "center",
        renderCell: (params) => {
          return <span>{Moment(params.value).format("DD-MM-YYYY")} </span>;
        },
        cellClassName: (params) => {
          return clsx("super-app", {
            celAll: params.value,
          });
        },
        align: "center",
        width: 90,
        sortable: false,
        filtering: false,
        hideable: false,
      },
      {
        field: "action1",
        headerName: "Aprobar",
        headerAlign: "center",
        headerClassName: "super-app-theme--header",
        width: 80,
        renderCell: (params) => (
          <ButtonTableGrid1 onClick={OpenApproveValue(params)}>
            Aprobar
          </ButtonTableGrid1>
        ),
      },
      {
        headerName: "-----",
        field: "actions",
        type: "actions",
        width: 40,
        headerClassName: "super-app-theme--header",
        cellClassName: (params) => {
          return clsx("super-app", {
            celActions: params.value,
          });
        },
        getActions: (params) => {
          const actions = [];

          actions.push(
            <GridActionsCellItem
              key="disable"
              icon={
                <Tooltip title="Rechazar solicitud" arrow>
                  <Cancel style={{ fontSize: 24, color: "#000" }} />
                </Tooltip>
              }
              label="Rechazar solicitud"
              onClick={openStatusValue(params)}
            />
          );

          return actions;
        },
      },
    ],
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    RefreshTable();
  }, []);

  // Función para eliminar acentos de una cadena de texto
  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const handleSearchChange = (event) => {
    // Filtrar los datos según el valor de búsqueda
    if (event.target.value.trim() !== "") {
      resultSearch = rows.filter(
        (item) =>
          removeAccents(item.allNames.toLowerCase()).includes(
            removeAccents(searchText.toLowerCase())
          ) ||
          removeAccents(item.email.toLowerCase()).includes(
            removeAccents(searchText.toLowerCase())
          ) ||
          removeAccents(item.companyRazonSocial.toLowerCase()).includes(
            removeAccents(searchText.toLowerCase())
          )
      );
      setRows(resultSearch);
    } else {
      setRows(rowsResponse);
    }

    setSearchText(event.target.value);
  };

  const RefreshTable = () => {
    setSearchText("");

    axios
      .get(`${urlServices}users/companies/list`, {
        headers: {
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let resultss = response.data.filter(
            (filtered) => filtered.status === 2
          );

          setRows(resultss);
          setRowsResponse(resultss);
          setLoader(false);
          props.callBackRefresh();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setMensageSystem(
            "La sesión ha expirado, vuelva a iniciar sesión ..."
          );
          setError(true);

          setTimeout(() => {
            localStorage.clear();
            setReturnLogin(true);
          }, 4000);
        } else if (e.response.status === 500) {
          setRows([]);
          setMensageSystem("Error en la consulta con sel servidor.");
          setError(true);
        }
      });
  };

  const OpenApproveValue = (data) => () => {
    setIdRow(data);
    setOpenApproveValue(true);
  };

  const openStatusValue = (data) => () => {
    setIdRow(data);
    setOpenStatus(true);
  };

  const handleCloseStatus = () => {
    setOpenStatus(false);
  };

  const handleCloseApprove = () => {
    setOpenApproveValue(false);
  };

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  if (returnLogin) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        // style={{ zIndex: 100 }}
        PaperProps={{
          sx: {
            borderRadius: 6,
          },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className={classes.cardTitleBlack}>
                <PersonOutline className={classes.iconFilter} /> Listado de
                solicitudes de aprobación de accionistas
                <br></br>
                <b style={{ fontSize: 12 }}>
                  (Son las solicituedes realizadas por las empresas para agregar
                  más accionistas)
                </b>
              </div>
              <Card>
                <CardBody>
                  {error ? <Alert severity="error">{mensageSystem}</Alert> : ""}
                  {loader ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <center>
                          <img
                            alt="loaderGif"
                            src={loaderGif}
                            style={{ width: 60, margin: "10% 0" }}
                          />
                        </center>
                      </Grid>
                    </Grid>
                  ) : (
                    <Fragment>
                      <Grid container>
                        <Grid item xs={6} sm={6}>
                          {/* Campo de búsqueda */}
                          <TextField
                            variant="outlined"
                            value={searchText}
                            onChange={handleSearchChange}
                            style={{ marginBottom: 5 }}
                            size="small"
                            InputProps={{
                              startAdornment: (
                                <Search
                                  position="start"
                                  style={{
                                    color: "gray",
                                    fontSize: 22,
                                    marginRight: 10,
                                  }}
                                />
                              ),
                            }}
                            placeholder="Buscar"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Box
                            sx={{
                              "& .super-app.celAll": {
                                fontSize: 12,
                                //backgroundColor: 'rgba(255, 7, 0, 0.55)',
                              },
                              "& .super-app.celActions": {
                                backgroundColor: "rgb(242, 242, 242)",
                              },
                              "& .super-app-theme--header": {
                                backgroundColor: "rgb(242, 242, 242)",
                              },
                              "& .super-app.wordWrap": {
                                fontSize: 10,
                                textAlign: "center",
                              },
                              marginTop: 2,
                            }}
                          >
                            <DataGrid
                              rows={rows}
                              columns={state.columns}
                              loading={loader}
                              initialState={{
                                pagination: {
                                  paginationModel: { page: 0, pageSize: 10 },
                                },
                              }}
                              pageSizeOptions={[5, 10, 20, 50]}
                              disableRowSelectionOnClick={true}
                              checkboxSelection={false}
                              //rowHeight={40}
                              autoHeights
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Fragment>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          {openStatus ? (
            <DeleteUserAdmin
              open={openStatus}
              exit={handleCloseStatus}
              id={idRow}
              callBackRefresh={RefreshTable}
            />
          ) : (
            ""
          )}
          {openApproveValue ? (
            <StatusAdmin
              open={openApproveValue}
              exit={handleCloseApprove}
              id={idRow}
              callBackRefresh={RefreshTable}
              path="users"
            />
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          <ButtonExit onClick={handleCloseDialog(true)}>Cerrar</ButtonExit>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
