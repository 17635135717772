import React, { useState, useContext, useEffect } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";
import { Redirect } from "react-router-dom";

// @material-ui/core
import { makeStyles } from "@mui/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import Moment from "moment";
import { Animated } from "react-animated-css";
import { Avatar, Stack, Grid, Box, Alert, Button, Hidden } from "@mui/material";
import clsx from "clsx";
import {
  List,
  Store,
  KeyboardArrowUp,
  KeyboardArrowDown,
  Add,
} from "@mui/icons-material";

import avatar from "assets/img/icon.png";
import logoPalankaClaro from "assets/img/logoPalankaClaro.png";
import loaderGif from "assets/img/loading.gif";
import mujerAvatar from "assets/img/undraw_mujer.png";
import hombreAvatar from "assets/img/undraw_hombre.png";

import StepperStatus from "views/company/StepperStatus";
import NewUserOrderApprove from "views/dialog/NewUserOrderApprove";

import {
  dashboardStyle,
  ButtonStyleWhite0,
  ButtonStyle0,
} from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(dashboardStyle);

export default function Dashboard() {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);
  const [mensageSystem, setMensageSystem] = useState("");
  const [returnLogin, setReturnLogin] = React.useState(false);
  const [buttonMyAccount, setbuttonMyAccount] = useState(false);
  const [stepperVisible, setStepperVisible] = useState(true);
  const [openNew, setOpenNew] = useState(false);

  const { urlServices, UserAuth } = useContext(UrlServicesContext);

  let keyAuthorization = UserAuth.Session;

  const [state] = useState({
    columns: [
      {
        headerName: "Accionista",
        field: "allNames",
        flex: 1,
        // headerAlign: "center",
        headerClassName: "super-app-theme--header",
        cellClassName: (params) => {
          return clsx("super-app", {
            celAll: params.value,
          });
        },
        renderCell: (params) => {
          return (
            <>
              <Avatar
                src={params.row.genderId === 1 ? hombreAvatar : mujerAvatar}
                style={{ marginRight: 3, width: 40, height: 40 }}
              />
              {params.value}
            </>
          );
        },
        sortable: false,
        hideable: false,
      },
      {
        headerName: "Email",
        field: "email",
        flex: 1,
        // headerAlign: "center",
        headerClassName: "super-app-theme--header",
        cellClassName: (params) => {
          return clsx("super-app", {
            celAll: params.value,
          });
        },
        sortable: false,
        hideable: false,
      },
      {
        headerName: "Perfil",
        field: "profileName",
        headerClassName: "super-app-theme--header",
        headerAlign: "center",
        renderCell: (params) => {
          return (
            <b
              style={{
                padding: 5,
                borderRadius: 10,
                border: "1px solid " + params.row.profile.color,
                whiteSpace: "break-spaces",
                background: params.row.profile.color,
                color: "#000",
              }}
            >
              {params.value}
            </b>
          );
        },
        cellClassName: (params) => {
          return clsx("super-app", {
            wordWrap: params.value,
          });
        },
        align: "center",
        width: 110,
        sortable: false,
        filtering: false,
        hideable: false,
      },
      {
        headerName: "Fecha Creación",
        field: "createdAt",
        headerClassName: "super-app-theme--header",
        headerAlign: "center",
        renderCell: (params) => {
          return <span>{Moment(params.value).format("DD-MM-YYYY")} </span>;
        },
        cellClassName: (params) => {
          return clsx("super-app", {
            celAll: params.value,
          });
        },
        align: "center",
        width: 120,
        sortable: false,
        filtering: false,
        hideable: false,
      },
      {
        headerName: "Estado",
        field: "status",
        // headerAlign: "center",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => {
          const text =
            params.value === 1
              ? "ACTIVO"
              : params.value === 0
              ? "INACTIVO"
              : "PENDIENTE";
          const color =
            params.value === 1
              ? "green"
              : params.value === 0
              ? "red"
              : "#000000";
          return (
            <>
              <b style={{ color: color, fontSize: 10 }}>{text}</b>
            </>
          );
        },
      },
    ],
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    RefreshTable();
  }, []);

  const RefreshTable = () => {
    axios
      .get(`${urlServices}users/companies/list`, {
        headers: {
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then(({ status, data }) => {
        if (status === 200) {
          const filteredData = data.filter(
            (filtered) =>
              (filtered.status === 1 || filtered.status === 99) &&
              filtered.companyId === parseInt(UserAuth.companyId)
          );

          setRows(filteredData);
          setLoader(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          const { status } = error.response;
          if (status === 401) {
            setMensageSystem(
              "La sesión ha expirado, vuelva a iniciar sesión ..."
            );
            setError(true);

            setTimeout(() => {
              localStorage.clear();
              setReturnLogin(true);
            }, 4000);
          } else if (status === 500) {
            setRows([]);
            setMensageSystem("Error en la consulta con el servidor.");
            setError(true);
          }
        } else {
          console.error("Error:", error.message);
        }
        setLoader(false);
      });
  };

  const handleButtonMyAccount = () => () => {
    setbuttonMyAccount(true);
  };

  const toggleStepperVisibility = () => {
    setStepperVisible(!stepperVisible);
  };

  const handleCloseNew = () => {
    setOpenNew(false);
  };

  if (buttonMyAccount) {
    localStorage.setItem("controllerRouter", "/customers/myaccount");
    return <Redirect to="/customers/myaccount" />;
  }

  if (returnLogin) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} md={12}>
          {error ? <Alert severity="error">{mensageSystem}</Alert> : ""}
        </GridItem>
      </GridContainer>

      <div className={classes.cardDashboardTop}>
        <GridContainer>
          <GridItem xs={12} md={8}>
            <Stack direction="row" spacing={2}>
              <Avatar
                src={avatar}
                style={{
                  background: "#FFF",
                  objectFit: "contain",
                  padding: "4px 6px",
                  width: 22,
                  height: 30,
                }}
              />
              <div style={{ fontSize: 16, color: "#FFF" }}>
                Bienvenido
                <br></br>
                <b style={{ fontSize: 22 }}>A tu Dashboard</b>
              </div>
            </Stack>
          </GridItem>
          <Hidden smDown>
          <GridItem xs={12} md={4}>
            <Stack direction="row" spacing={1}>
              <img
                src={logoPalankaClaro}
                alt="logoheader"
                style={{ width: 100, objectFit: "contain" }}
              />
              <Animated
                animationIn="pulse"
                animationInDuration={200}
                isVisible={true}
              >
                <div style={{ margin: "8% 0 0 30%" }}>
                  <ButtonStyleWhite0 onClick={handleButtonMyAccount()}>
                    Editar perfil
                  </ButtonStyleWhite0>
                </div>
              </Animated>
            </Stack>
          </GridItem>
          </Hidden>
        </GridContainer>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Grid container spacing={1} sx={{ marginTop: 2 }}>
            <Grid item xs={12} sm={12}>
              <div className={classes.cardTitleBlack}>
                <Store className={classes.iconFilter} /> Estatus de tu Empresa
                &nbsp;&nbsp;&nbsp;
                <Button
                  variant="outlined"
                  color="success"
                  onClick={toggleStepperVisibility}
                  size="small"
                  startIcon={
                    stepperVisible ? <KeyboardArrowUp /> : <KeyboardArrowDown />
                  }
                >
                  {stepperVisible ? "Ocultar procesos" : "Ver procesos"}
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              {stepperVisible ? <StepperStatus /> : ""}
            </Grid>
          </Grid>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={6} sx={{ marginTop: 4 }}>
          <div className={classes.cardTitleBlack}>
            <List className={classes.iconFilter} /> Listado de accionistas
          </div>
        </GridItem>
        <GridItem xs={12} sm={6} sx={{ marginTop: 4 }}>
          <ButtonStyle0
            style={{ float: "right" }}
            endIcon={<Add />}
            onClick={() => setOpenNew(true)}
          >
            Solicitar accionista
          </ButtonStyle0>
        </GridItem>
        <GridItem xs={12} sm={12}>
          <div className={classes.carDashboard}>
            <Grid container spacing={1}>
              {loader ? (
                <Grid item xs={12} sm={12}>
                  <center>
                    <img
                      alt="loaderGif"
                      src={loaderGif}
                      style={{ width: 60, margin: "10% 0" }}
                    />
                  </center>
                </Grid>
              ) : (
                <Grid item xs={12} sm={12}>
                  <Box
                    sx={{
                      "& .super-app.celAll": {
                        fontSize: 12,
                        //backgroundColor: 'rgba(255, 7, 0, 0.55)',
                      },
                      "& .super-app.celActions": {
                        backgroundColor: "rgb(242, 242, 242)",
                      },
                      "& .super-app-theme--header": {
                        backgroundColor: "rgb(242, 242, 242)",
                      },
                      "& .super-app.wordWrap": {
                        fontSize: 10,
                        textAlign: "center",
                      },
                      width: "100%",
                    }}
                  >
                    <DataGrid
                      rows={rows}
                      columns={state.columns}
                      loading={loader}
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 10 },
                        },
                      }}
                      pageSizeOptions={[5, 10, 20, 50]}
                      disableRowSelectionOnClick={true}
                      checkboxSelection={false}
                      //rowHeight={40}
                      autoHeights
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </div>
        </GridItem>
      </GridContainer>
      {openNew ? (
        <NewUserOrderApprove open={openNew} exit={handleCloseNew} />
      ) : (
        ""
      )}
    </div>
  );
}
