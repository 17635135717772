import React, { useState, useContext, useEffect } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";
/*import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { chartsGridClasses } from '@mui/x-charts/ChartsGrid';*/
import { BarChart } from "@mui/x-charts/BarChart";
import { Box, Grid } from "@mui/material";
import axios from "axios";

import loaderGif from "assets/img/loading.gif";

import { purpleColor } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

export default function BarSectorCompanies(props) {
  const [loader, setLoader] = useState(true);
  const [namesArray, setNamesArray] = useState([]);
  const [valuesArray, setValuesArray] = useState([]);
  const [dataValue, setDataValue] = useState(true);
  const [barColors, setBarColors] = useState("");

  const { urlServices, UserAuth } = useContext(UrlServicesContext);

  let keyAuthorization = UserAuth.Session;

  useEffect(() => {
    DataGraphBar();
  }, []);

  const DataGraphBar = () => {
    axios
      .get(`${urlServices}company/list`, {
        headers: {
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            let result = response.data.filter(
              (filtered) =>
                filtered.status !== 0 && filtered.sectorName !== null
            );

            const sectors = result.map((item) => ({
              id: item.sectorId,
              name: item.sectorName,
            }));

            // Objeto para almacenar el conteo de cada nombre
            const countByNameSector = {};

            // Iterar sobre los elementos del array y contarlos
            sectors.forEach((item) => {
              // Si el nombre ya existe en el objeto de conteo, incrementa su contador
              if (countByNameSector[item.name]) {
                countByNameSector[item.name]++;
              } else {
                // Si el nombre no existe, inicializa su contador en 1
                countByNameSector[item.name] = 1;
              }
            });

            // Convertir el objeto countByNameSector en un arreglo de objetos
            const countArray = Object.entries(countByNameSector).map(
              ([name, value]) => ({ name, value })
            );

            const namesArray = Object.values(countArray).map(
              (item) => item.name
            );
            const valuesArray = Object.values(countArray).map(
              (item) => item.value
            );

            setNamesArray(namesArray);
            setValuesArray(valuesArray);

            // make array of bar colors, highlighting max/min
            const minVal = Math.min(...valuesArray);
            const maxVal = Math.max(...valuesArray);
            const barColors = valuesArray.map((val) => {
              if (val === minVal) {
                return "#E6E6FA";
              } else if (val === maxVal) {
                return purpleColor;
              } else {
                return "#E6E6FA";
              }
            });

            setBarColors(barColors);
            setDataValue(false);
            props.countCompaniesAll(response.data.length);
          } else {
            setDataValue(true);
            props.countCompaniesAll(0);
          }

          setLoader(false);
        }
      })
      .catch((e) => {});
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={1}>
        {loader ? (
          <Grid item xs={12} sm={12}>
            <center>
              <img
                alt="loaderGif"
                src={loaderGif}
                style={{ width: 60, margin: "15% 0" }}
              />
            </center>
          </Grid>
        ) : dataValue ? (
          <Grid item xs={12} sm={12}>
            <center>
              <div style={{ margin: "15% 0", color: "gray" }}>
                Sin registros
              </div>
            </center>
          </Grid>
        ) : (
          <Grid item xs={12} sm={12}>
            {valuesArray.length > 0 ? (
              <text
                x="540"
                y="10"
                textAnchor="end"
                fill="black"
                style={{
                  float: "right",
                  fontSize: 12,
                  color: "gray",
                  marginTop: "-35px",
                  fontWeight: "400",
                }}
              >
                {`${(
                  (Math.max(...valuesArray) /
                    valuesArray.reduce((a, b) => a + b, 0)) *
                  100
                ).toFixed(2)}% pertenecen al Sector ${
                  namesArray[valuesArray.indexOf(Math.max(...valuesArray))]
                }`}
              </text>
            ) : (
              ""
            )}
            <center>
              <BarChart
                width={540}
                height={230}
                series={[{ data: valuesArray, id: "cId" }]}
                margin={{
                  left: 0,
                  right: 0,
                  top: 10,
                  // bottom: 30,
                }}
                xAxis={[
                  {
                    data: namesArray,
                    scaleType: "band",
                    colorMap: {
                      type: "ordinal",
                      values: namesArray,
                      colors: barColors,
                    },
                  },
                ]}
                /*slotProps={{
          bar: {
            rx: 10,
            ry: 20,
          },
        }}}*/
                sx={{
                  //change left yAxis label styles
                  "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                    strokeWidth: "0.4",
                    fill: "gray",
                  },
                  // change all labels fontFamily shown on both xAxis and yAxis
                  "& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel": {
                    fontFamily: "Roboto",
                    fontSize: "9px !important",
                    // fontWeight: "bold"
                  },
                  // change bottom label styles
                  "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                    strokeWidth: "0.5",
                    fill: "gray",
                  },
                  // bottomAxis Line Styles
                  "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                    stroke: "gray",
                    strokeWidth: 0.4,
                  },
                  // leftAxis Line Styles
                  "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                    stroke: "gray",
                    strokeWidth: 0.4,
                  },
                  /* [`& .${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'translateX(-10px)',
          },
          [`& .${chartsGridClasses.line}`]: { strokeDasharray: '5 3', strokeWidth: 2 },*/
                }}
                //grid={{ horizontal: true }}
                leftAxis={null}
              />
            </center>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
