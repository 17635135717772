import React, { useState, useContext, useEffect } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { Box, Grid } from "@mui/material";
import axios from "axios";

import loaderGif from "assets/img/loading.gif";

import {
  StyledText,
  purpleColor,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const size = {
  width: 200,
  height: 220,
};

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2.1}>
      {(children + "").split(",").map((word, index) => (
        <tspan key={index} x={left + width / 2} dy={index > 0 ? "1.2em" : 0}>
          {index === 0 ? (
            <tspan>{word}</tspan> // Primer carácter sin negrita
          ) : (
            <tspan style={{ fontWeight: "300", fontSize: 10 }}>{word}</tspan>
          )}
        </tspan>
      ))}
    </StyledText>
  );
}

export default function PieGenderShareHolders(props) {
  const [loader, setLoader] = useState(true);
  const [dataValue, setDataValue] = useState(true);
  const [data, setData] = useState([]);
  const [dataUserCount, setDataUserCount] = useState(0);

  const { urlServices, UserAuth } = useContext(UrlServicesContext);

  let keyAuthorization = UserAuth.Session;

  useEffect(() => {
    DataGraphPie();
  }, []);

  const DataGraphPie = () => {
    axios
      .get(`${urlServices}users/companies/list`, {
        headers: {
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            let result = response.data.filter(
              (filtered) => filtered.status !== 0
            );

            setDataUserCount(result.length);

            const genders = result.map((item) => ({
              id: item.gender.id,
              name: item.gender.name,
            }));

            // Objeto para almacenar el conteo de cada nombre
            const countByNameGender = {};

            // Iterar sobre los elementos del array y contarlos
            genders.forEach((item) => {
              // Si el nombre ya existe en el objeto de conteo, incrementa su contador
              if (countByNameGender[item.name]) {
                countByNameGender[item.name]++;
              } else {
                // Si el nombre no existe, inicializa su contador en 1
                countByNameGender[item.name] = 1;
              }
            });

            // Convertir el objeto countByNameSector en un arreglo de objetos
            const Array = Object.entries(countByNameGender).map(
              ([label, value]) => ({
                label,
                value,
                color:
                  label === "Hombre"
                    ? "#E6E6FA"
                    : label === "Mujer"
                    ? purpleColor
                    : "",
              })
            );

            setData(Array);
            setDataValue(false);
            props.countShareHolders(result.length);
          } else {
            setDataValue(true);
            props.countShareHolders(0);
          }

          setLoader(false);
        }
      })
      .catch((e) => {});
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={1}>
        {loader ? (
          <Grid item xs={12} sm={12}>
            <center>
              <img
                alt="loaderGif"
                src={loaderGif}
                style={{ width: 60, margin: "15% 0" }}
              />
            </center>
          </Grid>
        ) : dataValue ? (
          <Grid item xs={12} sm={12}>
            <center>
              <div style={{ margin: "15% 0", color: "gray" }}>
                Sin registros
              </div>
            </center>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sm={12}>
              <center>
                <PieChart
                  series={[{ data, innerRadius: 45, outerRadius: 70 }]}
                  {...size}
                  sx={{
                    margin: "-170px 0 -110px 0",
                  }}
                  margin={{ right: 0, top: 0, left: 0, bottom: 0 }}
                  slotProps={{
                    /* legend: {
            labelStyle: {
              tableLayout: "fixed",
            },
            direction: "row",
            position: {
              horizontal: "middle",
              vertical: "bottom",
              outside: true,
            },
          },*/
                    legend: { hidden: true },
                  }}
                >
                  <PieCenterLabel>{dataUserCount} Total</PieCenterLabel>
                </PieChart>
              </center>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={1} sx={{ marginTop: "-70px" }}>
                {data.map((item, index) => (
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    key={index}
                    sx={{ textAlign: "center" }}
                  >
                    <StyledText
                      x={100}
                      y={50 + index * 20}
                      sx={{ fontSize: 10, fontWeight: "300" }}
                    >
                      <span
                        style={{
                          background: item.color,
                          padding: "3px 9px",
                          borderRadius: "50%",
                          marginRight: "5px",
                        }}
                      ></span>
                      {item.value}
                      <br />
                      {item.label}
                    </StyledText>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}
