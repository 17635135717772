/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @mui/icons-material
import {
  PersonOutline,
  GridView,
  Settings,
  AddBusiness,
  PeopleOutline,
  AccountCircle,
} from "@mui/icons-material";

// PAGES
import DashboardPage from "views/Dashboard/Dashboard.js";
import UsersListAdmin from "views/Admin/UsersListAdmin.js";
import ShareHoldersListAdmin from "views/Admin/ShareHoldersListAdmin.js";
import CompaniesListAdmin from "views/Admin/CompaniesListAdmin.js";
import ProfilesListAdmin from "views/Admin/ProfilesListAdmin.js";
import ProfilePrivileges from "views/Admin/ProfilePrivilegesListAdmin.js";
import CountriesListAdmin from "views/Admin/CountriesListAdmin.js";
import StatesListAdmin from "views/Admin/StatesListAdmin.js";
import CitiesListAdmin from "views/Admin/CitiesListAdmin.js";
import GenderListAdmin from "views/Admin/GenderListAdmin.js";
import SectorsListAdmin from "views/Admin/SectorsListAdmin.js";
import MyAccount from "views/Admin/MyAccount.js";
import TrackingCompaniesListAdmin from "views/Admin/TrackingCompaniesListAdmin.js";
import DocumentCompaniesListAdmin from "views/Admin/DocumentCompaniesListAdmin.js";
import TypeDocumentsAdmin from "views/Admin/TypeDocumentsAdmin.js";

const dashboardRoutes = [
  {
    id: 0,
    subId: "",
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: GridView,
    component: DashboardPage,
    layout: "/superadmin",
    submenu: [],
    margin: 0,
  },
  // MENU USERS
  {
    id: 0,
    subId: "",
    path: "/users",
    name: "Usuarios",
    rtlName: "لوحة القيادة",
    icon: PersonOutline,
    component: UsersListAdmin,
    layout: "/superadmin",
    submenu: [],
    margin: 0,
  },
  /* START ADMIN COMPANIES */
  {
    id: 2,
    subId: "",
    path: "",
    name: "Empresas",
    rtlName: "",
    icon: AddBusiness,
    component: "",
    layout: "/superadmin",
    submenu: [
      {
        subId: 2,
        name: "Listado",
        path: "/companies",
      },
      {
        subId: 2,
        name: "Seguimiento",
        path: "/trackingcompanies",
      },
      {
        subId: 2,
        name: "Documentos",
        path: "/documentscompanies",
      },
    ],
    margin: 0,
  },
  {
    // SUBMENU COMPANIES list
    id: "",
    subId: 2,
    path: "/companies",
    name: "",
    rtlName: "",
    icon: "",
    component: CompaniesListAdmin,
    layout: "/superadmin",
    submenu: [],
  },
  {
    // SUBMENU TRACKING list
    id: "",
    subId: 2,
    path: "/trackingcompanies",
    name: "",
    rtlName: "",
    icon: "",
    component: TrackingCompaniesListAdmin,
    layout: "/superadmin",
    submenu: [],
  },
  {
    // SUBMENU DOCUMENTS list
    id: "",
    subId: 2,
    path: "/documentscompanies",
    name: "",
    rtlName: "",
    icon: "",
    component: DocumentCompaniesListAdmin,
    layout: "/superadmin",
    submenu: [],
  },
  // MENU ACCIONISTAS
  {
    id: 0,
    subId: "",
    path: "/shareholders",
    name: "Accionistas",
    rtlName: "لوحة القيادة",
    icon: PeopleOutline,
    component: ShareHoldersListAdmin,
    layout: "/superadmin",
    submenu: [],
    margin: 0,
  },
  /* START ADMIN*/
  {
    id: 1,
    subId: "",
    path: "",
    name: "Administración",
    rtlName: "",
    icon: Settings,
    component: "",
    layout: "/superadmin",
    submenu: [
      {
        subId: 1,
        name: "Perfiles",
        path: "/profilelist",
      },
      {
        subId: 1,
        name: "Permisos por perfiles",
        path: "/profileprivileges",
      },
      /* {
        subId: 1,
        name: "Módulos",
        path: "/modules",
      },*/
      {
        subId: 1,
        name: "Paises",
        path: "/countries",
      },
      {
        subId: 1,
        name: "Provincias",
        path: "/states",
      },
      {
        subId: 1,
        name: "Ciudades",
        path: "/cities",
      },
      {
        subId: 1,
        name: "Género",
        path: "/gender",
      },
      {
        subId: 1,
        name: "Sectores",
        path: "/sectors",
      },
      {
        subId: 1,
        name: "Tipos documentos",
        path: "/typedocuments",
      },
    ],
    margin: 0,
  },
  {
    // SUBMENU Profiles list
    id: "",
    subId: 1,
    path: "/profilelist",
    name: "",
    rtlName: "",
    icon: "",
    component: ProfilesListAdmin,
    layout: "/superadmin",
    submenu: [],
  },
  /*{
    // SUBMENU MENU list
    id: "",
    subId: 1,
    path: "/modules",
    name: "",
    rtlName: "",
    icon: "",
    component: MenuListAdmin,
    layout: "/superadmin",
    submenu: [],
  },*/
  {
    // SUBMENU PROFILE/PRIVILEGES list
    id: "",
    subId: 1,
    path: "/profileprivileges",
    name: "",
    rtlName: "",
    icon: "",
    component: ProfilePrivileges,
    layout: "/superadmin",
    submenu: [],
  },
  {
    // SUBMENU COUNTRIES list
    id: "",
    subId: 1,
    path: "/countries",
    name: "",
    rtlName: "",
    icon: "",
    component: CountriesListAdmin,
    layout: "/superadmin",
    submenu: [],
  },
  {
    // SUBMENU STATES list
    id: "",
    subId: 1,
    path: "/states",
    name: "",
    rtlName: "",
    icon: "",
    component: StatesListAdmin,
    layout: "/superadmin",
    submenu: [],
  },
  {
    // SUBMENU CITIES list
    id: "",
    subId: 1,
    path: "/cities",
    name: "",
    rtlName: "",
    icon: "",
    component: CitiesListAdmin,
    layout: "/superadmin",
    submenu: [],
  },
  {
    // SUBMENU GENDER list
    id: "",
    subId: 1,
    path: "/gender",
    name: "",
    rtlName: "",
    icon: "",
    component: GenderListAdmin,
    layout: "/superadmin",
    submenu: [],
  },
  {
    // SUBMENU SECTORS list
    id: "",
    subId: 1,
    path: "/sectors",
    name: "",
    rtlName: "",
    icon: "",
    component: SectorsListAdmin,
    layout: "/superadmin",
    submenu: [],
  },
  {
    // SUBMENU TYPE DOCUMENTS list
    id: "",
    subId: 1,
    path: "/typedocuments",
    name: "",
    rtlName: "",
    icon: "",
    component: TypeDocumentsAdmin,
    layout: "/superadmin",
    submenu: [],
  },
  {
    id: 0,
    subId: "",
    path: "/myaccount",
    name: "Tu perfil",
    rtlName: "لوحة القيادة",
    icon: AccountCircle,
    component: MyAccount,
    layout: "/superadmin",
    submenu: [],
    margin: 1,
  },
];

export default dashboardRoutes;
