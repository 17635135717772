import React, { Fragment, useState, useContext, useEffect } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";
import { Redirect } from "react-router-dom";

import { makeStyles, withStyles } from "@mui/styles";
import axios from "axios";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { CheckBox } from "@mui/icons-material";
import {
  Slide,
  Snackbar,
  Grid,
  Checkbox,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Dialog,
  DialogActions,
  DialogContent,
  Alert,
} from "@mui/material";

import {
  cardBodyStyle,
  ButtonExit,
  ButtonStyle0,
  GreenCheckboxSelected,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

import loaderGif from "assets/img/loading.gif";

const useStyles = makeStyles(cardBodyStyle);

const GreenCheckbox = withStyles(GreenCheckboxSelected)((props) => (
  <Checkbox color="default" {...props} />
));

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Dessert (100g serving)",
  },
];

function EnhancedTableHead(props) {
  const { name } = props;
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={false}
            colSpan={2}
          >
            <b>{name.name}</b>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function ViewProfilePrivileges(props) {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [rowsModules, setRowsModules] = useState([]);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [selectedDifferent, setSelectedDifferent] = useState([]);
  const [selectedIdProfile, setSelectedIdProfile] = useState([]);
  const [idProfileResources, setIdProfileResources] = useState([]);
  const [returnLogin, setReturnLogin] = React.useState(false);
  const [mensageSystem, setMensageSystem] = useState("");

  const { urlServices, UserAuth } = useContext(UrlServicesContext);

  let keyAuthorization = UserAuth.Session;

  useEffect(() => {
    axios
      .get(`${urlServices}modules/list`, {
        headers: {
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then(
        (response) => {
          let resoult = response.data.sort(function (a, b) {
            if (a.nOrder > b.nOrder) {
              return 1;
            }
            if (a.nOrder < b.nOrder) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
          setRowsModules(resoult);

          axios
            .get(`${urlServices}profiles-resources/profile/${props.id.id}`, {
              headers: {
                Authorization: "Bearer " + keyAuthorization,
              },
            })
            .then((response1) => {
              let rsl = [],
                rsl2 = [];

              let result = response1.data.rows.filter(
                (filtered) => filtered.status !== 0
              );

              result.forEach((row) => {
                rsl.push(row.resourceId);
                rsl2.push({ id: row.id, resourceId: row.resourceId });
              });

              setSelected(rsl);
              setSelectedDifferent(rsl);
              setSelectedIdProfile(rsl2);
              setIdProfileResources(response1.data.rows);
              setLoader(false);
            })
            .catch((e) => {
              if (e.response.status === 404) {
                setLoader(false);
              } else if (e.response.status === 401) {
                setMensageSystem(
                  "La sesión ha expirado, vuelva a iniciar sesión ..."
                );
                setError(true);

                setTimeout(() => {
                  localStorage.clear();
                  setReturnLogin(true);
                }, 4000);
              } else if (e.response.status === 500) {
                setMensageSystem("Error en la consulta con sel servidor.");
                setError(true);
              }
            });
        },
        (error) => {
          setError(true);
        }
      )
      .catch((e) => {
        if (e.response.status === 401) {
          setMensageSystem(
            "La sesión ha expirado, vuelva a iniciar sesión ..."
          );
          setError(true);

          setTimeout(() => {
            localStorage.clear();
            setReturnLogin(true);
          }, 4000);
        } else if (e.response.status === 500) {
          setMensageSystem("Error en la consulta con sel servidor.");
          setError(true);
        }
      });
  }, []);

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleSave = (pro) => (event) => {
    var different = [],
      deleteValue = [];

    for (var i0 = 0; i0 < selected.length; i0++) {
      var found0 = false;

      for (var j0 = 0; j0 < selectedDifferent.length; j0++) {
        // j < is missed;
        if (selected[i0] === selectedDifferent[j0]) {
          found0 = true;
          break;
        }
      }
      if (found0 === false) {
        different.push(selected[i0]);
      }
    }

    for (var i = 0; i < selectedDifferent.length; i++) {
      var found = false;

      for (var j = 0; j < selected.length; j++) {
        // j < is missed;
        if (selectedDifferent[i] === selected[j]) {
          found = true;
          break;
        }
      }
      if (found === false) {
        deleteValue.push(selectedDifferent[i]);
      }
    }

    if (different.length > 0) {
      let result = [];

      different.forEach((row) => {
        result = idProfileResources.filter(
          (filtered) => filtered.resourceId === row
        );

        if (result.length === 0) {
          CreateData(row);
        } else {
          for (var i = 0; i < idProfileResources.length; i++) {
            if (idProfileResources[i].resourceId === row) {
              UpdateData(idProfileResources[i].id);
            }
          }
        }
      });
    }

    if (deleteValue.length > 0) {
      let out = [];

      for (var i = 0; i < selectedIdProfile.length; i++) {
        for (var j = 0; j < deleteValue.length; j++) {
          // j < is missed;
          if (selectedIdProfile[i].resourceId === deleteValue[j]) {
            out.push(selectedIdProfile[i]);
          }
        }
      }

      DeleteData(out);
    }
    setMensaje("Registro guardado con éxito.");
    setOpenAlert(true);
    setSuccess("success");
  };

  const DeleteData = (data) => {
    data.forEach((row) => {
      axios
        .delete(`${urlServices}profiles-resources/${row.id}`, {
          headers: {
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then(
          (response) => {},
          (error) => {}
        )
        .catch((e) => {
          if (e.response.status === 401) {
            setMensageSystem(
              "La sesión ha expirado, vuelva a iniciar sesión ..."
            );
            setError(true);

            setTimeout(() => {
              localStorage.clear();
              setReturnLogin(true);
            }, 4000);
          } else if (e.response.status === 500) {
            setMensageSystem("Error en la consulta con sel servidor.");
            setError(true);
          }
        });
    });
  };

  const CreateData = (id) => {
    const dataValue = {
      profileId: props.id.id,
      resourceId: id,
    };

    axios
      .post(`${urlServices}profiles-resources`, dataValue, {
        headers: {
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then(
        (response) => {},
        (error) => {}
      )
      .catch((e) => {
        if (e.response.status === 401) {
          setMensageSystem(
            "La sesión ha expirado, vuelva a iniciar sesión ..."
          );
          setError(true);

          setTimeout(() => {
            localStorage.clear();
            setReturnLogin(true);
          }, 4000);
        } else if (e.response.status === 500) {
          setMensageSystem("Error en la consulta con sel servidor.");
          setError(true);
        }
      });
  };

  const UpdateData = (row) => {
    const dataValue = {
      status: 1,
    };

    axios
      .put(`${urlServices}profiles-resources/${row}`, dataValue, {
        headers: {
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then(
        (response) => {},
        (error) => {}
      )
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear();
            setReturnLogin(true);
          }, 200);
        } else {
          console.log(e);
        }
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  if (returnLogin) {
    return <Redirect to="/" />;
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={handleCloseDialog(true)}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      keepMounted
      scroll="body"
      PaperProps={{
        sx: {
          borderRadius: 6,
        },
      }}
    >
      <DialogContent dividers className={classes.styleDialogContent}>
        <Card>
          <CardHeader color="info">
            <div className={classes.cardTitleWhite}>
              <CheckBox className={classes.iconWhite} /> Permisos
            </div>
          </CardHeader>
          <CardBody>
            <Grid container>
              {error ? <Alert severity="error">{mensageSystem}</Alert> : ""}
              {loader ? (
                <Grid item xs={12} sm={12}>
                  <center>
                    <img
                      alt="loaderGif"
                      src={loaderGif}
                      style={{ width: 60, margin: "8% 0" }}
                    />
                  </center>
                </Grid>
              ) : (
                <Fragment>
                  {rowsModules.map((row1, i1) => {
                    return (
                      <Grid item xs={12} sm={3}>
                        <Paper className={classes.paper}>
                          <TableContainer>
                            <Table
                              aria-labelledby="tableTitle"
                              size="small"
                              aria-label="enhanced table"
                            >
                              <EnhancedTableHead name={row1} />
                              <TableBody>
                                {row1.resources
                                  .sort(function (a, b) {
                                    if (a.nOrder > b.nOrder) {
                                      return 1;
                                    }
                                    if (a.nOrder < b.nOrder) {
                                      return -1;
                                    }
                                    // a must be equal to b
                                    return 0;
                                  })
                                  .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    let isItemSelected = isSelected(row.id);
                                    return (
                                      <Fragment>
                                        <TableRow
                                          hover
                                          onClick={(event) =>
                                            handleClick(event, row.id)
                                          }
                                          role="checkbox"
                                          aria-checked={isItemSelected}
                                          tabIndex={-1}
                                          key={row.id}
                                          selected={isItemSelected}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <TableCell padding="checkbox">
                                            <GreenCheckbox
                                              checked={isItemSelected}
                                              inputProps={{
                                                "aria-labelledby": labelId,
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                          >
                                            {row.name}
                                          </TableCell>
                                        </TableRow>
                                      </Fragment>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      </Grid>
                    );
                  })}
                  <Grid item xs={12} sm={12}>
                    <br></br>
                    <center>
                      <ButtonStyle0 type="submit" onClick={handleSave()}>
                        Aceptar
                      </ButtonStyle0>
                    </center>
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </CardBody>
        </Card>
        <Snackbar
          open={openAlert}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleClose}
        >
          <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
            <Alert
              onClose={handleClose}
              severity={success === "error" ? "error" : "success"}
              elevation={6}
              variant="filled"
            >
              {mensaje}
            </Alert>
          </Slide>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <ButtonExit onClick={handleCloseDialog(true)}>Cerrar</ButtonExit>
      </DialogActions>
    </Dialog>
  );
}
