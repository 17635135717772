import React, { useState, useEffect, Fragment } from "react";

import { makeStyles } from "@mui/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { PersonOutline, Search, Add } from "@mui/icons-material";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import clsx from "clsx";
import { DataGrid } from "@mui/x-data-grid";
import Moment from "moment";
import {
  Avatar,
  Grid,
  TextField,
  Box,
  DialogContent,
  DialogActions,
  Dialog,
} from "@mui/material";

import mujerAvatar from "assets/img/undraw_mujer.png";
import hombreAvatar from "assets/img/undraw_hombre.png";
import loaderGif from "assets/img/loading.gif";

import NewUserMasive from "views/dialog/NewUserMasive";

import {
  cardBodyStyle,
  ButtonExit,
  ButtonStyle0,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function ListShareHoldersCompany(props) {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [rowsResponse, setRowsResponse] = useState([]);
  const [openNewMasive, setopenNewMasive] = useState(false);

  let resultSearch = "";

  const [state] = useState({
    columns: [
      {
        headerName: "Accionista",
        field: "allNames",
        flex: 1,
        // headerAlign: "center",
        headerClassName: "super-app-theme--header",
        cellClassName: (params) => {
          return clsx("super-app", {
            celAll: params.value,
          });
        },
        renderCell: (params) => {
          return (
            <>
              <Avatar
                src={params.row.genderId === 1 ? hombreAvatar : mujerAvatar}
                style={{ marginRight: 3, width: 40, height: 40 }}
              />
              {params.value}
            </>
          );
        },
        sortable: false,
        hideable: false,
      },
      {
        headerName: "Email",
        field: "email",
        flex: 1,
        // headerAlign: "center",
        headerClassName: "super-app-theme--header",
        cellClassName: (params) => {
          return clsx("super-app", {
            celAll: params.value,
          });
        },
        sortable: false,
        hideable: false,
      },
      {
        headerName: "Perfil",
        field: "profile",
        headerClassName: "super-app-theme--header",
        headerAlign: "center",
        renderCell: (params) => {
          return (
            <b
              style={{
                padding: 5,
                borderRadius: 10,
                border: "1px solid " + params.row.profile.color,
                whiteSpace: "break-spaces",
                background: params.row.profile.color,
                color: "#000",
              }}
            >
              {params.value.name}
            </b>
          );
        },
        cellClassName: (params) => {
          return clsx("super-app", {
            wordWrap: params.value,
          });
        },
        align: "center",
        width: 110,
        sortable: false,
        filtering: false,
        hideable: false,
      },
      {
        headerName: "Fecha Creación",
        field: "createdAt",
        headerClassName: "super-app-theme--header",
        headerAlign: "center",
        renderCell: (params) => {
          return <span>{Moment(params.value).format("DD-MM-YYYY")} </span>;
        },
        cellClassName: (params) => {
          return clsx("super-app", {
            celAll: params.value,
          });
        },
        align: "center",
        width: 120,
        sortable: false,
        filtering: false,
        hideable: false,
      },
      {
        headerName: "Estado",
        field: "status",
        // headerAlign: "center",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => {
          const text =
            params.value === 1
              ? "ACTIVO"
              : params.value === 0
              ? "INACTIVO"
              : "PENDIENTE";
          const color =
            params.value === 1
              ? "green"
              : params.value === 0
              ? "red"
              : "#000000";
          return (
            <>
              <b style={{ color: color, fontSize: 10 }}>{text}</b>
            </>
          );
        },
      },
    ],
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    RefreshTable();
  }, []);

  // Función para eliminar acentos de una cadena de texto
  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const handleSearchChange = (event) => {
    // Filtrar los datos según el valor de búsqueda
    if (event.target.value.trim() !== "") {
      resultSearch = rows.filter(
        (item) =>
          removeAccents(item.allNames.toLowerCase()).includes(
            removeAccents(searchText.toLowerCase())
          ) ||
          removeAccents(item.email.toLowerCase()).includes(
            removeAccents(searchText.toLowerCase())
          )
      );
      setRows(resultSearch);
    } else {
      setRows(rowsResponse);
    }

    setSearchText(event.target.value);
  };

  const RefreshTable = (val, rows) => {
    setSearchText("");
    setRows(val !== 1 ? props.id : rows);
    setRowsResponse(val !== 1 ? props.id : rows);
    setLoader(false);

    if (val === 1) {
      props.callBackRefresh();
    }
  };

  const handleCloseOpenNewMasive = () => {
    setopenNewMasive(false);
  };

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        // style={{ zIndex: 100 }}
        PaperProps={{
          sx: {
            borderRadius: 6,
          },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className={classes.cardTitleBlack}>
                <PersonOutline className={classes.iconFilter} /> Listado de
                accionistas
              </div>
              <Card>
                <CardBody>
                  {loader ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <center>
                          <img
                            alt="loaderGif"
                            src={loaderGif}
                            style={{ width: 60, margin: "20% 0" }}
                          />
                        </center>
                      </Grid>
                    </Grid>
                  ) : (
                    <Fragment>
                      <Grid container>
                        <Grid item xs={6} sm={6}>
                          {/* Campo de búsqueda */}
                          <TextField
                            variant="outlined"
                            value={searchText}
                            onChange={handleSearchChange}
                            style={{ marginBottom: 5 }}
                            size="small"
                            InputProps={{
                              startAdornment: (
                                <Search
                                  position="start"
                                  style={{
                                    color: "gray",
                                    fontSize: 22,
                                    marginRight: 10,
                                  }}
                                />
                              ),
                            }}
                            placeholder="Buscar"
                          />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <div style={{ float: "right" }}>
                            <ButtonStyle0
                              onClick={(event) => {
                                setopenNewMasive(true);
                              }}
                              endIcon={<Add />}
                            >
                              Carga masiva
                            </ButtonStyle0>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Box
                            sx={{
                              "& .super-app.celAll": {
                                fontSize: 12,
                                //backgroundColor: 'rgba(255, 7, 0, 0.55)',
                              },
                              "& .super-app.celActions": {
                                backgroundColor: "rgb(242, 242, 242)",
                              },
                              "& .super-app-theme--header": {
                                backgroundColor: "rgb(242, 242, 242)",
                              },
                              "& .super-app.wordWrap": {
                                fontSize: 10,
                                textAlign: "center",
                              },
                              marginTop: 2,
                            }}
                          >
                            <DataGrid
                              rows={rows}
                              columns={state.columns}
                              loading={loader}
                              initialState={{
                                pagination: {
                                  paginationModel: { page: 0, pageSize: 10 },
                                },
                              }}
                              pageSizeOptions={[5, 10, 20, 50]}
                              disableRowSelectionOnClick={true}
                              checkboxSelection={false}
                              //rowHeight={40}
                              autoHeights
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Fragment>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          {openNewMasive ? (
            <NewUserMasive
              open={openNewMasive}
              exit={handleCloseOpenNewMasive}
              callBackRefresh={RefreshTable}
              rows={rowsResponse}
              companyId={props.companyId}
            />
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          <ButtonExit onClick={handleCloseDialog(true)}>Cerrar</ButtonExit>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
