import React, { Fragment, useState, useContext } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";
import { Redirect } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import axios from "axios";
import {
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  Slide,
  Snackbar,
  TextField,
  Grid,
  DialogActions,
  DialogContent,
  Alert,
  Dialog,
} from "@mui/material";

import {
  cardBodyStyle,
  ButtonExit,
  ButtonStyle0,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function NewProfileAdmin(props) {
  const classes = useStyles();

  const [openAlert, setOpenAlert] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  const [openAlertSuccess, setopenAlertSuccess] = useState(false);
  const [mensaje_success, setmensaje_success] = useState("");
  const [returnLogin, setReturnLogin] = React.useState(false);
  const [error, setError] = useState(false);
  const [mensageSystem, setMensageSystem] = useState("");
  const [color, setColor] = React.useState("");

  const { urlServices, UserAuth } = useContext(UrlServicesContext);

  let keyAuthorization = UserAuth.Session;

  const [values, setValues] = useState({
    name: "",
    errorName: false,
  });

  const handleChange = (prop) => (event) => {
    setopenAlertSuccess(false);

    setValues({
      ...values,
      [prop]: event.target.value,
      errorName: false,
    });
    setOpenAlert(false);
  };

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  const submitForm = (e) => {
    e.preventDefault();
    setError("");
    setopenAlertSuccess(false);

    if (values.name.length === 0) {
      setValues({ ...values, errorName: true });
      setMensaje('El campo "Nombre" es obligatorio.');
      setOpenAlert(true);
      setErrorAlert("error");
    } else if (color === "") {
      setMensaje('El campo "Color perfil" es obligatorio.');
      setOpenAlert(true);
      setErrorAlert("error");
    } else {
      const dataValue = {
        name: values.name,
        color: color,
      };

      axios
        .post(`${urlServices}profile`, dataValue, {
          headers: {
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then((response) => {
          setopenAlertSuccess(true);
          setmensaje_success(response.data.message);
          props.callBackRefresh();

          setValues({
            name: "",
          });

          setColor("");
        })
        .catch((e) => {
          if (e.response.status === 401) {
            setMensageSystem(
              "La sesión ha expirado, vuelva a iniciar sesión ..."
            );
            setError(true);

            setTimeout(() => {
              localStorage.clear();
              setReturnLogin(true);
            }, 4000);
          } else if (e.response.status === 500) {
            setMensageSystem("Error en la consulta con sel servidor.");
            setError(true);
          }
        });
    }
  };

  const handleChangeColor = (event) => {
    setColor(event.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  if (returnLogin) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        PaperProps={{
          sx: {
            borderRadius: 6,
          },
        }}
        //style={{ zIndex: 100 }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                {error ? <Alert severity="error">{mensageSystem}</Alert> : ""}
                <div className={classes.titleCardFilter}>Nuevo Perfil</div>
                <div className={classes.lineBottom}></div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <FormControl style={{ width: "100%", marginTop: 15 }}>
                  <TextField
                    name="name"
                    value={values.name}
                    error={values.errorName}
                    onChange={handleChange("name")}
                    fullWidth
                    required
                    inputProps={{
                      style: {
                        textAlign: "center",
                      },
                      maxLength: 25,
                    }}
                    label="Nombre"
                    margin="dense"
                    variant="outlined"
                    autoComplete="off"
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={7}>
                <FormControl sx={{ marginLeft: 5 }}>
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Color perfil
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={color}
                    onChange={handleChangeColor}
                    row
                  >
                    <FormControlLabel
                      value="#f7cac9"
                      control={<Radio size="small" style={{ color: "#000" }} />}
                      style={{ background: "#f7cac9", width: 42, height: 40 }}
                    />
                    <FormControlLabel
                      value="#FFE5B4"
                      control={<Radio size="small" style={{ color: "#000" }} />}
                      style={{ background: "#FFE5B4", width: 42, height: 40 }}
                    />
                    <FormControlLabel
                      value="#cce5ff"
                      control={<Radio size="small" style={{ color: "#000" }} />}
                      style={{ background: "#cce5ff", width: 42, height: 40 }}
                    />
                    <FormControlLabel
                      value="#E6E6FA"
                      control={<Radio size="small" style={{ color: "#000" }} />}
                      style={{ background: "#E6E6FA", width: 42, height: 40 }}
                    />
                    <FormControlLabel
                      value="#c7f6d4"
                      control={<Radio size="small" style={{ color: "#000" }} />}
                      style={{ background: "#c7f6d4", width: 42, height: 40 }}
                    />
                    <FormControlLabel
                      value="#fffec2"
                      control={<Radio size="small" style={{ color: "#000" }} />}
                      style={{ background: "#fffec2", width: 42, height: 40 }}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12}>
                <br></br>
                <center>
                  <ButtonStyle0 type="submit" onClick={submitForm}>
                    Guardar
                  </ButtonStyle0>
                </center>
              </Grid>
              <Grid item xs={12} sm={12}>
                <center>
                  {openAlertSuccess ? (
                    <Alert severity="success">{mensaje_success}</Alert>
                  ) : (
                    ""
                  )}
                </center>
              </Grid>
            </Grid>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={errorAlert === "error" ? "error" : "success"}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <ButtonExit onClick={handleCloseDialog(true)}>Cerrar</ButtonExit>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
